/* eslint-disable */ module.exports = {
  languageData: {
    plurals: function (n, ord) {
      if (ord) return n == 1 ? "one" : "other"
      return n >= 0 && n < 2 ? "one" : "other"
    },
  },
  messages: {
    CONTACT: "CONTACT",
    Hotel: "H\xF4tel",
    Chambres: "Chambres",
    Spa: "Spa",
    Activitées: "Activit\xE9s",
    "La Magie de la Vall\xE9e de la Loire":
      "La Magie de la Vall\xE9e de la Loire",
    "Plus d'infos": "Plus d'infos",
    "Amboise, ville royale aux mille merveilles":
      "Amboise, ville royale aux mille merveilles",
    "AU CHARME RABELAISIEN est situ\xE9 au c\u0153ur de la r\xE9gion des Ch\xE2teaux de la Loire. Au d\xE9part de notre h\xF4tel, offrez-vous une plong\xE9e dans l\u2019histoire de France lors de votre escapade en bord de Loire. Les superbes paysages du bord de Loire et les b\xE2timents historiques":
      "Au Charme Rabelaisien est situ\xE9 au c\u0153ur de la r\xE9gion des Ch\xE2teaux de la Loire. Au d\xE9part de notre h\xF4tel, offrez-vous une plong\xE9e dans l\u2019histoire de France lors de votre escapade en bord de Loire. Les superbes paysages du bord de Loire, les b\xE2timents historiques",
    "et architecturaux charg\xE9s d\u2019histoire font du Val de Loire un territoire riche et incontournable, inscrit \xE0 la liste du Patrimoine Mondial de l\u2019UNESCO. La r\xE9gion s\u2019\xE9tend sur une aire de 800 km2, le long de la Loire sur 4 d\xE9partements.":
      "et architecturaux charg\xE9s d\u2019histoire font du Val de Loire un territoire riche et incontournable, inscrit \xE0 la liste du Patrimoine Mondial de l\u2019UNESCO. La r\xE9gion s\u2019\xE9tend sur une aire de 800 km\xB2 le long de la Loire sur 4 d\xE9partements.",
    "Envoyez-nous un message": "Envoyez-nous un message",
    Envoyer: "Envoyer",
    "Merci ! Nous vous contacterons au plus vite :)":
      "Merci ! Nous vous contacterons au plus vite :)",
    "Contactez-nous et nous nous ferons un plaisir de vous aider.":
      "Contactez-nous et nous nous ferons un plaisir de vous aider.",
    Addresse: "Adresse",
    "Contact info": "Contact",
    "ENTREZ   EN   CONTACT   AVEC       NOUS":
      "ENTREZ   EN   CONTACT   AVEC       NOUS",
    Cookies: "Cookies",
    "Au Charme Rabelaisien uses its own and third-party analytical cookies to analyze your preferences and improve your experience on the website If you want to learn more or opt out of all or some cookies,":
      "Au Charme Rabelaisien utilise ses propres cookies analytiques tiers pour analyser vos pr\xE9f\xE9rences et am\xE9liorer votre exp\xE9rience sur le site Si vous souhaitez en savoir plus ou d\xE9sactiver tout ou partie des cookies,",
    "Learn more about their use.": "En savoir plus sur leur utilisation.",
    Accept: "Accepter",
    "Parking Priv\xE9 S\xE9curis\xE9": "Parking Priv\xE9 S\xE9curis\xE9",
    "L'h\xF4tel b\xE9n\xE9ficie d'un parking payant et s\xE9curis\xE9. Suivant disponibilit\xE9, sa reservation au pr\xE9alable est n\xE9cessaire.":
      "L'h\xF4tel b\xE9n\xE9ficie d'un parking payant et s\xE9curis\xE9. Suivant disponibilit\xE9, sa r\xE9servation au pr\xE9alable est n\xE9cessaire.",
    "Prix: 12 euros par nuit": "Prix: 12\u20AC par nuit",
    "Petit D\xE9jeuner Continental": "Petit D\xE9jeuner Continental",
    "Un moment \xE0 savourer, un moment o\xF9 l'on prend son temps pour d\xE9guster notre buffet, un moment ou l'on s'attarde autour d'un caf\xE9. Il est temps pour vous de d\xE9buter votre journ\xE9e \xE0 Amboise de la plus belle mani\xE8re qu'il soit.":
      "Un moment \xE0 savourer, un moment o\xF9 l'on prend son temps pour d\xE9guster notre buffet, un moment ou l'on s'attarde autour d'un caf\xE9. Il est temps pour vous de d\xE9buter votre journ\xE9e \xE0 Amboise de la plus belle mani\xE8re qu'il soit.",
    Conciergerie: "Conciergerie",
    "Nous nous ferons un plaisir de mettre \xE0 profit nos connaissances pour rendre votre s\xE9jour le meilleur possible avec des services personnalis\xE9s (r\xE9servations de restaurants, taxis, ou visites culturelles et autres lieux embl\xE9matiques \xE0 d\xE9couvrir).":
      "Nous nous ferons un plaisir de mettre \xE0 profit nos connaissances pour rendre votre s\xE9jour le meilleur possible avec des services personnalis\xE9s (r\xE9servations de restaurants, taxis, ou visites culturelles et autres lieux embl\xE9matiques \xE0 d\xE9couvrir).",
    Welcome: "Welcome",
    "Nous aimons nos amis \xE0 quatre pattes, ils sont donc les bienvenus !":
      "Nous aimons nos amis \xE0 quatre pattes, ils sont donc les bienvenus !",
    "Sur demande, nous acceptons les chiens de moins de 10 kilos - 25 euros de suppl\xE9ment par nuit.":
      "Sur demande, nous acceptons les chiens de moins de 8 kilos",
    "Prix: 25 euros de suppl\xE9ment par nuit.":
      "Prix: 25\u20AC de suppl\xE9ment par nuit.",
    Ascenceur: "Ascenceur",
    "Un ascenceur vitr\xE9 est \xE0 votre disposition pour desservir le 1er et 2\xE8me \xE9tage dans la maison de ma\xEEtre.":
      "Un ascenceur vitr\xE9 est \xE0 votre disposition pour desservir le 1er et 2\xE8me \xE9tage dans la maison de ma\xEEtre.",
    "NOS ACTIVIT\xC9ES": "Activit\xE9s",
    GALLERIE: "Galerie",
    "Un avant-go\xFBt de notre caract\xEAre":
      "Un avant-go\xFBt de notre caract\xE8re",
    "Suivez-Nous": "Suivez-nous",
    "Join the adventure!": "Rejoindre l'aventure!",
    "SUIVEZ-NOUS": "Suivez-nous",
    "THE EXPERIENCE": "L'exp\xE9rience",
    Purely: "Purement",
    Original: "Original",
    "In Amboise historical town center, the hotel is an expression of the cultural generosity of the Loire Valley and its modern spirit.":
      "Dans le centre-ville historique d'Amboise, l'h\xF4tel est l'expression de la g\xE9n\xE9rosit\xE9 culturelle du Val de Loire et de son esprit moderne.",
    "Inscrivez-vous \xE0": "Inscrivez-vous \xE0",
    "notre newsletter": "notre newsletter",
    "Votre addresse email": "Votre addresse email",
    OK: "OK",
    "Congratulation! You are now registered !":
      "F\xE9licitation! Vous \xEAtes bien enregistr\xE9.",
    "Salles de bains": "Salles de bains",
    Modernes: "Modernes",
    "Exclusives, spacieuses et incroyablement bien \xE9quip\xE9es (baignoires et douches \xE0 jets hydromassants s\xE9par\xE9es), elles sont aussi accueillantes et  agr\xE9ables que les grandes serviettes moelleuses que vous y trouverez.":
      "Exclusives, spacieuses et incroyablement bien \xE9quip\xE9es (baignoires et douches \xE0 jets hydromassants s\xE9par\xE9es), elles sont aussi accueillantes et confortables que les grandes serviettes moelleuses que vous y trouverez",
    "VOIR LES CHAMBRES": "VOIR LES CHAMBRES",
    Terrasses: "Terrasses",
    Privées: "Priv\xE9es",
    "D\u2019un petit d\xE9jeuner sur votre terrasse \xE0 un dernier verre au bar priv\xE9 qu\u2019offre votre chambre, tout est fait pour que vous vous sentiez comme chez vous.":
      "En rez de chauss\xE9e sur le jardin, vous pourrez profiter d\u2019un petit d\xE9jeuner sur votre terrasse ou un dernier verre du bar priv\xE9, tout est fait pour que vous vous sentiez comme chez vous",
    Vues: "Vues",
    Immersives: "Immersives",
    "Offrez-vous des vues pittoresques sur le Ch\xE2teau, notre jardin paysager avec sa piscine et ses toits Amboisiens":
      "Offrez-vous des vues pittoresques sur le Ch\xE2teau, notre jardin paysager avec sa piscine et ses toits amboisiens",
    DÉCROCHER: "S'\xE9vader",
    "avec Vue": "avec Vue",
    "C\xE9l\xE9brant l'esprit d'Amboise et de sa Loire sauvage, les 10 chambres de Au Charme Rabelaisien entourent les visiteurs de la beaut\xE9 captivante de la destination.":
      "C\xE9l\xE9brant l\u2019esprit m\xE9di\xE9val d\u2019Amboise et la beaut\xE9 majestueuse de la Loire, les 10 chambres d\u2019Au Charme Rabelaisien entourent les visiteurs d\u2019une ambiance captivante.",
    "Une invitation \xE0 profiter des vues les plus extraordinaires sur le ch\xE2teau, le jardin paysag\xE9 ou les superbes toits de cette ville historique.":
      "Impr\xE9gn\xE9 par la r\xEAverie et l\u2019histoire, \xE0 c\xF4t\xE9 de la derni\xE8re demeure de L\xE9onard de Vinci, l'h\xF4tel Au Charme Rabelaisien est la fen\xEAtre sur la nature et l'\xE9ternit\xE9 du temps",
    DÉTENTE: "D\xC9TENTE",
    "L'ACR": "L'ACR",
    "Pour votre d\xE9tente et votre bien-\xEAtre, l\u2019h\xF4tel ACR s\u2019est dot\xE9 d\u2019un espace bien-\xEAtre avec jaccuzzi et sauna \xE0 acc\xE8s privatif.":
      "Pour votre d\xE9tente et votre bien-\xEAtre, l\u2019h\xF4tel ACR s\u2019est dot\xE9 d\u2019un espace bien-\xEAtre avec jacuzzi et sauna \xE0 acc\xE8s privatif",
    Massages: "Massages",
    Jaccuzi: "Jaccuzzi",
    Sauna: "Sauna",
    "Rayonnez et laissez vos tensions dispara\xEEtre entre les mains expertes de nos sp\xE9cialistes, au gr\xE9 de soins exceptionnels.":
      "Rayonnez et laissez vos tensions dispara\xEEtre entre les mains expertes de nos sp\xE9cialistes, au gr\xE9 de soins exceptionnels",
    "L\u2019immersion dans cette eau chaude \xE0 remous procure une sensation imm\xE9diate de d\xE9tente.":
      "L\u2019immersion dans cette eau chaude \xE0 remous procure une sensation imm\xE9diate de d\xE9tente",
    "Le sauna offre une sensation de purification. Il stimule la circulation sanguine, offre une d\xE9tente physique et mentale compl\xE8te.":
      "Le sauna offre une sensation de purification. Il stimule la circulation sanguine, offre une d\xE9tente physique et mentale compl\xE8te",
    "EN SAVOIR PLUS": "En savoir plus",
    "FERMER VIDEO": "FERMER VIDEO",
    "TARIFS / R\xC9SERVER": "TARIFS / R\xC9SERVER",
    "INSERT A DATE FOR PRICE": "S\xC9LECTIONNER UNE DATE",
    "PRIX:": "PRIX",
    RÉSERVER: "R\xC9SERVER",
    Imprenables: "Royale",
    Luxueux: "Luxueux",
    Privé: "Priv\xE9",
    Confort: "Confort",
    "Vues Imprenables": "Royale",
    "Douche \xE0 Jets et": "Douche \xE0 Jets et",
    "Baignoires S\xE9par\xE9es": "Baignoires S\xE9par\xE9es",
    "Exclusives, spacieuses et incroyablement bien \xE9quip\xE9es (baignoires et douches \xE0 jets hydromassants s\xE9par\xE9es), elles sont aussi accueillantes et agr\xE9ables que les grandes serviettes moelleuses que vous y trouverez.":
      "Exclusives, spacieuses et incroyablement bien \xE9quip\xE9es (baignoires et douches \xE0 jets hydromassants s\xE9par\xE9es), elles sont aussi accueillantes et agr\xE9ables que les grandes serviettes mo\xEBlleuses que vous y trouverez.",
    "Terrasses Privatives": "Terrasses Privatives",
    "Grand Confort": "Confort",
    "Nos chambres disposent d\u2019un confort complet (Nespresso, produits de toilettes Fragonard, peignoirs, chaussons...), pour faire de votre s\xE9jour \xE0 Amboise, un moment r\xE9ussi, plac\xE9 sous le signe de la qui\xE9tude et du charme.":
      "Nos chambres disposent d\u2019un confort complet (Nespresso, produits de toilettes Fragonard, peignoirs, chaussons, s\xE9che cheveux, minibar, coffre...), pour faire de votre s\xE9jour \xE0 Amboise, un moment r\xE9ussi, plac\xE9 sous le signe de la qui\xE9tude et du charme.",
    "NOS DIFF\xC9RENCES": "Nos diff\xE9rences",
    "Nos Chambres": "Nos Chambres",
    "Chambres avec Vue Ch\xE2teau": "Chambres: avec Vue Ch\xE2teau",
    "Chambres avec Terrasse": "Chambres: avec Terrasse",
    "Chambres cot\xE9es Ville": "Chambres: c\xF4t\xE9 Ville",
    Découvrir: "D\xE9couvrir",
    "D\xE9couvrir la chambre": "D\xE9couvrir la chambre",
    Chambre: "Chambre",
    "Vue :": "Vue :",
    "Superficie :": "Superficie :",
    "Sdb :": "Sdb :",
    "Occupation :": "Occupation :",
    "Lits :": "Lit :",
    "Situation dans la :": "Situation :",
    "Climatisation r\xE9versible": "Climatisation r\xE9versible",
    "WC s\xE9par\xE9 et ferm\xE9": "WC ferm\xE9",
    CHAMBRES: "Chambres",
    LUXE: "LUXE",
    et: "et",
    "SOUCIS DU D\xC9TAIL": "SOUCI DU D\xC9TAIL",
    pour: "pour",
    "UN S\xC9JOUR AUTHENTIC": "UN S\xC9JOUR AUTHENTIQUE",
    Contact: "Contact",
    Address: "Adresse",
    hotel: "h\xF4tel",
    chambres: "chambres",
    spa: "spa",
    activitées: "activit\xE9s",
    contact: "contact",
    "Besoin de plus d'informations?": "Besoin de plus d'informations ?",
    "Contactez-nous": "Contactez-nous",
    "Pour ceux qui souhaitent faire une pause relaxante pendant leur s\xE9jour, il y a un spa confortable \xE0 l\u2019int\xE9rieur de la structure. Id\xE9al pour se d\xE9tendre le corps et l\u2019esprit apr\xE8s une journ\xE9e de visites ou pour r\xE9cup\xE9rer de l\u2019\xE9nergie.":
      "Pour ceux qui souhaitent faire une pause relaxante pendant leur s\xE9jour, l'h\xF4tel met \xE0 votre disposition un spa confortable. Id\xE9al pour se d\xE9tendre le corps et l\u2019esprit apr\xE8s une journ\xE9e de visites et pour r\xE9cup\xE9rer de l\u2019\xE9nergie.",
    "Intime et r\xE9serv\xE9": "Intime et PRIV\xC9",
    ", notre spa est con\xE7u pour accueillir un nombre limit\xE9 de deux clients qui pourront profiter pleinement des espaces":
      ", notre spa est con\xE7u pour accueillir un nombre limit\xE9 de deux clients qui pourront en profiter pleinement",
    Prix: "Prix",
    "Privacy Policy": "Politique de confidentialit\xE9",
    "Terms of use": "Conditions d'utilisation",
    "H\xF4tel Au Charme Rabelaisien provides its services under reserve that you to respect the present conditions of use, that can be changed at any time by H\xF4tel Au Charme Rabelaisien.":
      "L'H\xF4tel Au Charme Rabelaisien met \xE0 votre disposition ses services sous r\xE9serve que vous respectiez les pr\xE9sentes conditions d'utilisation, susceptibles d'\xEAtre modifi\xE9es \xE0 tout moment par H\xF4tel Au Charme Rabelaisien.",
    "Intellectual property": "Propri\xE9t\xE9 intellectuelle",
    "According to the laws poverning intellectual property, the reproduction or the use of elements found on this internet site, whether complete or partial, is strictly forbidden. Only are maintained the legal exceptions among which personal non-commercial use only (representation in the family circle, or private copy or the right to a short quotation.)":
      "Selon les lois r\xE9gissant la propri\xE9t\xE9 intellectuelle, la reproduction ou l'utilisation des \xE9l\xE9ments pr\xE9sents sur ce site Internet, qu'elle soit compl\xE8te ou partielle, est strictement interdite. Seules sont maintenues les exceptions l\xE9gales parmi lesquelles un usage personnel non commercial uniquement (repr\xE9sentation dans le cercle familial, ou copie priv\xE9e ou droit \xE0 un court devis).",
    "Thus, all the texts, photos, logos, brands and other elements reproduced on this site are protected by the right of intellectual property (Right author, right(straight) neighbour, right(law) of the marks(brands)).":
      "Ainsi, tous les textes, photos, logos, marques et autres \xE9l\xE9ments reproduits sur ce site sont prot\xE9g\xE9s par le droit de la propri\xE9t\xE9 intellectuelle (Droit d'auteur, droit (droit) voisin, droit (loi) des marques (marques)).",
    "Any total or partial of this site by any means whatsoever, without the express permission of the operator of the website is prohibited and constitutes counterfeit sanctioned by articles L 335-2 and following of the Intellectual Property Code.":
      "Tout ou partie de ce site par quelque proc\xE9d\xE9 que ce soit, sans l'autorisation expresse de l'exploitant du site est interdite et constituerait une contrefa\xE7on sanctionn\xE9e par les articles L 335-2 et suivants du Code de la Propri\xE9t\xE9 Intellectuelle.",
    "Same is true of any databases contained on the website which protected by the provisions of the Act of 11 July 1998 transposing into the Intellectual Property Code (IPC) of the European directive of 11 March 1996 on the Legal Protection of Databases":
      "Il en est de m\xEAme de toutes les bases de donn\xE9es contenues sur le site Internet qui sont prot\xE9g\xE9es par les dispositions de la loi du 11 juillet 1998 transposant dans le code de la propri\xE9t\xE9 intellectuelle (IPC) de la directive europ\xE9enne du 11 mars 1996 relative \xE0 la protection juridique des bases de donn\xE9es",
    "Personal datas": "Donn\xE9es personnelles",
    "H\xF4tel Au Charme Rabelaisien complies fully with all applicable data protection and consumer legislation, and will treat all your personal information as confidential.We will not pass on your personal details to third parties. H\xF4tel Au Charme Rabelaisien makes a commitment to assure everyone who will make the demand a right of access, rectification and deletion of the datas concerning him/her.":
      "L'H\xF4tel Au Charme Rabelaisien se conforme pleinement \xE0 toutes les lois applicables en mati\xE8re de protection des donn\xE9es et de la consommation, et traitera toutes vos informations personnelles de mani\xE8re confidentielle, nous ne transmettrons pas vos donn\xE9es personnelles \xE0 des tiers. H\xF4tel Au Charme Rabelaisien s'engage \xE0 assurer \xE0 toute personne qui en fera la demande un droit d'acc\xE8s, de rectification et de suppression des donn\xE9es la concernant.",
    Responsabilities: "Responsabilities",
    Links: "Liens",
    "You are free to establish links towards this site, as long as they don\u2019t affect the interests and the image of its editor. You are allowed to establish links towards the pages of this site , only if they are in the followinf formats : html, php, xml.":
      "Vous \xEAtes libre d\u2019\xE9tablir des liens vers ce site, tant qu\u2019ils n\u2019affectent pas les int\xE9r\xEAts et l\u2019image de son \xE9diteur. Vous \xEAtes autoris\xE9 \xE0 \xE9tablir des liens vers les pages de ce site, uniquement si elles sont dans les formats suivants: html, php, xml.",
    "The practice of framing is strictly forbidden, as well as any other practice or technique which could confuse people.":
      "La pratique du cadrage est strictement interdite, ainsi que toute autre pratique ou technique qui pourrait d\xE9router les gens.",
    "Deep links towards documents in the pdf, doc, or rtf formats are prohibited. In fact, those types of documents don\u2019t allow people to read the conditions of use.":
      "Les liens profonds vers des documents aux formats pdf, doc ou rtf sont interdits. En fait, ces types de documents ne permettent pas aux gens de lire les conditions d\u2019utilisation.",
    "H\xF4tel Au Charme Rabelaisien is absolutely not responsible for the contents of Internet sites that can be reached through its website.":
      "Ces cookies peuvent \xEAtre des cookies de session ou des cookies persistants et leur utilisation est limit\xE9e aux performances et \xE0 l'am\xE9lioration du site. Ces cookies permettent de reconna\xEEtre, mesurer et suivre les visiteurs du Site, permettant ainsi l'am\xE9lioration du Site, par exemple en \xE9tablissant si l'Utilisateur est en mesure de trouver facilement les informations souhait\xE9es, ou en identifiant quels aspects du Site sont les plus importants int\xE9r\xEAt. Ces cookies sont utilis\xE9s par Au Charme Rabelaisien pour traiter des analyses statistiques et anonymes sur la fa\xE7on dont les Utilisateurs naviguent sur le Site, le nombre total de pages visit\xE9es ou le nombre de clics effectu\xE9s sur une page lors de la navigation. Ces cookies de premi\xE8re partie sont consid\xE9r\xE9s par la l\xE9gislation sur la confidentialit\xE9 comme \xE9gaux aux cookies techniques, ils ne n\xE9cessitent donc pas le consentement de l'utilisateur.",
    "Enjoy your visit.": "Bonne visite.",
    "A cookie is a little file which a website sends to the browser and saves on the User\u2019s computer when visiting a website. Cookies are used in order to allow or enhance the running of the website, but also to gain information on the Site or on the User\u2019s web-browsing activities.":
      "Un cookie est un petit fichier qu'un site Web envoie au navigateur et enregistre sur l'ordinateur de l'utilisateur lors de la visite d'un site Web. Les cookies sont utilis\xE9s pour permettre ou am\xE9liorer le fonctionnement du site Web, mais \xE9galement pour obtenir des informations sur le site ou sur les activit\xE9s de navigation Web de l'utilisateur.",
    "Technical cookies": "Cookies techniques",
    "These cookies are essential to allow the User to browse on the Site and use its functionalities, such as the access to reserved areas of the Site (cookie of authentication). The strictly necessary cookies are used to record a univocal identifier in order to manage and identify the User with respect to other Users visiting the Site in the same moment, providing the User with a consistent and precise service (e.g. the language selection). These cookies are necessary to the correct working of the Site and to record the choices expressed by the User, to distribute requests on different servers, to record when the User gives his/her authorization or expresses the consent to specific options (e.g. newsletters), to allow the User to visualize contents and video through Adobe Flash Player.":
      "Ces cookies sont indispensables pour permettre \xE0 l'Utilisateur de naviguer sur le Site et d'utiliser ses fonctionnalit\xE9s, telles que l'acc\xE8s aux zones r\xE9serv\xE9es du Site (cookie d'authentification). Les cookies strictement n\xE9cessaires sont utilis\xE9s pour enregistrer un identifiant univoque afin de g\xE9rer et d'identifier l'Utilisateur par rapport aux autres Utilisateurs visitant le Site au m\xEAme moment, fournissant \xE0 l'Utilisateur un service coh\xE9rent et pr\xE9cis (par exemple la s\xE9lection de la langue). Ces cookies sont n\xE9cessaires au bon fonctionnement du Site et pour enregistrer les choix exprim\xE9s par l'Utilisateur, pour diffuser des demandes sur diff\xE9rents serveurs, pour enregistrer lorsque l'Utilisateur donne son autorisation ou exprime son consentement \xE0 des options sp\xE9cifiques (ex: newsletters), pour permettre \xE0 l'utilisateur de visualiser le contenu et la vid\xE9o via Adobe Flash Player.",
    "First Party Performance and Analytics Cookies":
      "Cookies de performance et d'analyse de premi\xE8re partie",
    "These cookies can be session cookies or persistent cookies and their use is limited to the performance and improvement of the Site. These cookies allow to recognize, measure and keep track of visitors to the Site, thus allowing the improvement of the Site, for example establishing whether the User is able to easily find the desired information, or by identifying which aspects of the Site are of major interest. These cookies are used by Au Charme Rabelaisien to process statistical and anonymous analysis on how Users navigate on the Site, the overall number of pages visited or the number of clicks made on a page while browsing. These first party cookies are considered by the privacy legislation as equal to technical cookies, therefore they do not require the consent of the User.":
      "Ces cookies peuvent \xEAtre des cookies de session ou des cookies persistants et leur utilisation est limit\xE9e aux performances et \xE0 l'am\xE9lioration du site. Ces cookies permettent de reconna\xEEtre, mesurer et suivre les visiteurs du Site, permettant ainsi l'am\xE9lioration du Site, par exemple en \xE9tablissant si l'Utilisateur est en mesure de trouver facilement les informations souhait\xE9es, ou en identifiant quels aspects du Site sont les plus importants int\xE9r\xEAt. Ces cookies sont utilis\xE9s par Au Charme Rabelaisien pour traiter des analyses statistiques et anonymes sur la fa\xE7on dont les Utilisateurs naviguent sur le Site, le nombre total de pages visit\xE9es ou le nombre de clics effectu\xE9s sur une page lors de la navigation. Ces cookies de premi\xE8re partie sont consid\xE9r\xE9s par la l\xE9gislation sur la confidentialit\xE9 comme \xE9gaux aux cookies techniques, ils ne n\xE9cessitent donc pas le consentement de l'utilisateur.",
    "Third Party Analytics Cookies": "Cookies d'analyse tiers",
    "Au Charme Rabelaisien may also use analytics and performance cookies from third parties on the Site, including those of Google Analytics, as indicated below. These cookies collect information on the ways in which the User uses the Site, including, for example, the pages visited by the User. Furthermore, third-party analytics cookies can track the use that Users make of the Site and allow Au Charme Rabelaisien to make improvements to the Site by verifying, for example, whether the User is able to easily find the information sought or, again, identify the aspects of the Site that are most interesting. These cookies, with appropriate mechanisms to reduce the personal data collected (such as deletion of part of the User\u2019s IP address) are used by The St. Regis Venice to process analysis and statistics, exclusively in anonymous mode, on how Users browse the Site, the number of pages visited and clicks made on a page during navigation. Au Charme Rabelaisien treats the results of these analysis only if the third party uses cookies in connection with the browser installed on the computer or on other devices used by the User to browse the Site.":
      "Au Charme Rabelaisien peut \xE9galement utiliser des cookies d'analyse et de performance de tiers sur le Site, y compris ceux de Google Analytics, comme indiqu\xE9 ci-dessous. Ces cookies collectent des informations sur la mani\xE8re dont l'Utilisateur utilise le Site, y compris, par exemple, les pages visit\xE9es par l'Utilisateur. En outre, les cookies d'analyse tiers peuvent suivre l'utilisation que les Utilisateurs font du Site et permettre \xE0 Au Charme Rabelaisien d'apporter des am\xE9liorations au Site en v\xE9rifiant, par exemple, si l'Utilisateur est en mesure de trouver facilement les informations recherch\xE9es ou, encore une fois, de s'identifier les aspects du site les plus int\xE9ressants. Ces cookies, avec des m\xE9canismes appropri\xE9s pour r\xE9duire les donn\xE9es personnelles collect\xE9es (comme la suppression d'une partie de l'adresse IP de l'Utilisateur) sont utilis\xE9s par The St.Regis Venice pour traiter des analyses et des statistiques, exclusivement en mode anonyme, sur la fa\xE7on dont les Utilisateurs naviguent sur le Site, le nombre de pages visit\xE9es et de clics effectu\xE9s sur une page lors de la navigation. Au Charme Rabelaisien ne traite les r\xE9sultats de ces analyses que si le tiers utilise des cookies en relation avec le navigateur install\xE9 sur l'ordinateur ou sur d'autres appareils utilis\xE9s par l'Utilisateur pour naviguer sur le Site.",
    "How to disable cookies on the browser":
      "How to disable cookies on the browser",
    "Apart from the choice to accept or refuse the cookies used by the Site for additional purposes by means of the mechanism illustrated in this Privacy Policy, in the future the User may disable from the browser the cookies installed by the Site also by means of the setting of the same browser. The majority of web browsers are set to accept cookies. However, if so wished, the User may set his/her browser to limit the number of cookies accepted or to block all cookies, by changing the browser\u2019s settings with the following procedures:":
      "Au Charme Rabelaisien peut \xE9galement utiliser des cookies d'analyse et de performance de tiers sur le Site, y compris ceux de Google Analytics, comme indiqu\xE9 ci-dessous. Ces cookies collectent des informations sur la mani\xE8re dont l'Utilisateur utilise le Site, y compris, par exemple, les pages visit\xE9es par l'Utilisateur. En outre, les cookies d'analyse tiers peuvent suivre l'utilisation que les Utilisateurs font du Site et permettre \xE0 Au Charme Rabelaisien d'apporter des am\xE9liorations au Site en v\xE9rifiant, par exemple, si l'Utilisateur est en mesure de trouver facilement les informations recherch\xE9es ou, encore une fois, de s'identifier les aspects du site les plus int\xE9ressants. Ces cookies, avec des m\xE9canismes appropri\xE9s pour r\xE9duire les donn\xE9es personnelles collect\xE9es (comme la suppression d'une partie de l'adresse IP de l'Utilisateur) sont utilis\xE9s par The St.Regis Venice pour traiter des analyses et des statistiques, exclusivement en mode anonyme, sur la fa\xE7on dont les Utilisateurs naviguent sur le Site, le nombre de pages visit\xE9es et de clics effectu\xE9s sur une page lors de la navigation. Au Charme Rabelaisien ne traite les r\xE9sultats de ces analyses que si le tiers utilise des cookies en relation avec le navigateur install\xE9 sur l'ordinateur ou sur d'autres appareils utilis\xE9s par l'Utilisateur pour naviguer sur le Site.",
    ": Click \u201CTools\u201D in the top right corner and select \u201CInternet settings\u201D. In the pop-up select \u201CPrivacy\u201D: here the User may change the cookies settings.":
      ": Cliquez sur \xABOutils\xBB dans le coin sup\xE9rieur droit et s\xE9lectionnez \xABParam\xE8tres Internet\xBB. Dans la fen\xEAtre contextuelle, s\xE9lectionnez \xABConfidentialit\xE9\xBB: ici, l'utilisateur peut modifier les param\xE8tres des cookies.",
    ": Click the \u201Cwrench\u201D in the top right corner and select \u201CSettings\u201D. Then select \u201CShow advanced settings\u201D and change the \u201CPrivacy\u201D settings.":
      ": Cliquez sur la \xABcl\xE9\xBB dans le coin sup\xE9rieur droit et s\xE9lectionnez \xABParam\xE8tres\xBB. S\xE9lectionnez ensuite \xABAfficher les param\xE8tres avanc\xE9s\xBB et modifiez les param\xE8tres \xABConfidentialit\xE9\xBB.",
    ": In the top left menu select \u201CSettings\u201D. In the pop-up window select \u201CPrivacy\u201D: here the User may change the cookies settings.":
      ": Dans le menu en haut \xE0 gauche, s\xE9lectionnez \xABParam\xE8tres\xBB. Dans la fen\xEAtre pop-up, s\xE9lectionnez \xABConfidentialit\xE9\xBB: ici, l'utilisateur peut modifier les param\xE8tres des cookies.",
    ": In the top right menu select \u201CPreferences\u201D. Then select \u201CSecurity\u201D and change the cookies settings.":
      ": Dans le menu en haut \xE0 droite, s\xE9lectionnez \xABPr\xE9f\xE9rences\xBB. S\xE9lectionnez ensuite \xABS\xE9curit\xE9\xBB et modifiez les param\xE8tres des cookies.",
    "In order to carry out such operation in the mobile telephone, please consult the handbook of the device. Please be aware that by disabling all the cookies  on the Site, certain functionalities of the Site may not be available.":
      "Pour effectuer une telle op\xE9ration sur le t\xE9l\xE9phone mobile, veuillez consulter le manuel de l'appareil. Veuillez noter qu'en d\xE9sactivant tous les cookies sur le Site, certaines fonctionnalit\xE9s du Site peuvent ne pas \xEAtre disponibles.",
    "Further information on cookies": "Plus d'informations sur les cookies",
    "The website": "Le site Web",
    "contains the instructions for the management of cookies of the most used browsers; alternatively, it is possible to consult the documentation related to the software used on the device.":
      "contient les instructions pour la gestion des cookies des navigateurs les plus utilis\xE9s; alternativement, il est possible de consulter la documentation relative au logiciel utilis\xE9 sur l'appareil.",
    "Amendments to the Privacy Policy":
      "Modifications de la politique de confidentialit\xE9",
    "Au Charme Rabelaisien may amend, from time to time, this Privacy Policy, by publishing the amendments on the Site. We invite Users to check periodically such updates.":
      "Au Charme Rabelaisien peut modifier, de temps \xE0 autre, cette politique de confidentialit\xE9, en publiant les modifications sur le site. Nous invitons les utilisateurs \xE0 v\xE9rifier p\xE9riodiquement ces mises \xE0 jour.",
    "Chambre title room": "Chambre",
    Précédente: "Pr\xE9c\xE9dente",
    "Chambre changer": "Chambre",
    Suivante: "Suivante",
    Indisponible: "Chambre Occup\xE9",
    "Il semblerait que nous soyons complet pour vos dates. Nous vous invitons \xE0 v\xE9rifier si tel est le cas en nous contactant.":
      "Il semblerait que nous soyons complet pour vos dates. Nous vous invitons \xE0 v\xE9rifier si tel est le cas en nous contactant.",
    "Les chambres avec vue Ch\xE2teau :":
      "Les chambres avec une vue Ch\xE2teau :",
  },
}
