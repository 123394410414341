// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-activities-jsx": () => import("./../../../src/pages/activities.jsx" /* webpackChunkName: "component---src-pages-activities-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-jsx": () => import("./../../../src/pages/legal.jsx" /* webpackChunkName: "component---src-pages-legal-jsx" */),
  "component---src-pages-rooms-jsx": () => import("./../../../src/pages/rooms.jsx" /* webpackChunkName: "component---src-pages-rooms-jsx" */),
  "component---src-pages-spa-jsx": () => import("./../../../src/pages/spa.jsx" /* webpackChunkName: "component---src-pages-spa-jsx" */),
  "component---src-templates-room-js": () => import("./../../../src/templates/room.js" /* webpackChunkName: "component---src-templates-room-js" */)
}

