import React, { useState, useRef, useEffect } from "react"
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import fr from "date-fns/locale/fr"
import en from "date-fns/locale/fr"
import ru from "date-fns/locale/ru"
import { connect } from "react-redux"

const langObj = { en: en, fr: fr, ru: ru }

let calendarLangVoc = {
  fr: "ARRIVÉE",
  en: "CHECK-IN",
  ru: "приезд",
}

const StartCalendarMobile = ({
  language,
  startingDate,
  endingDate,
  updateStartingDate,
  showCalendar,
}) => {
  let container = useRef(null)
  registerLocale(language, langObj[language])
  let calendar = useRef(null)

  const updateStartDate = date => {
    updateStartingDate(date)
  }

  useEffect(() => {
    let input = container.children[0].children[0].children[0]
    input.readOnly = true
  }, [])

  useEffect(() => {
    if (showCalendar) {
      calendar.setOpen(true)
    }
  }, [showCalendar])

  return (
    <div className="calendar-mobile" ref={el => (container = el)}>
      <DatePicker
        selected={startingDate}
        // locale={language}
        dateFormat={language === "en" ? "MM/dd/yyyy" : "dd/MM/yyyy"}
        locale={language != "ru" ? language : ru}
        onSelect={date => updateStartDate(date)}
        withPortal
        placeholderText={calendarLangVoc[language]}
        minDate={new Date()}
        endDate={endingDate}
        ref={el => (calendar = el)}
      />
    </div>
  )
}

const mapStateToProps = ({ language, startingDate, endingDate }) => {
  return { language, startingDate, endingDate }
}

const mapDispatchToProps = dispatch => {
  return {
    updateStartingDate: date => {
      dispatch({
        type: `UPDATE_STARTING_DATE`,
        payload: date,
      })
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StartCalendarMobile)
