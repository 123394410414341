import React from "react"

const DownArrow = ({ color, isReservationCenter }) => {
  return (
    <div
      className="navigation-arrow-down"
      style={
        !isReservationCenter
          ? { zIndex: 100 }
          : { position: "sticky", zIndex: 100, left: "calc(50% - 50px)" }
      }
    >
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 490 490"
        style={{ enableBackground: "new 0 0 490 490" }}
        xmlSpace="preserve"
      >
        <g>
          <g>
            <g>
              <polygon
                style={{ fill: color }}
                points="366.291,109.411 244.998,233.196 123.709,109.411 161.578,72.429 244.998,157.564 328.422,72.429 			"
              />
            </g>
            <g>
              <polygon
                style={{ fill: color }}
                points="490,167.534 244.996,417.571 0,167.534 76.493,92.832 244.996,264.8 413.507,92.832 			"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}

export default DownArrow
