import { combineReducers } from "redux"

function language(state = "fr", action) {
  switch (action.type) {
    // omit other reducer cases
    case "UPDATE_LANGUAGE": {
      // Replace the existing state entirely by returning the new value
      return (state = action.payload.language)
    }
    case "UPDATE_INITIAL_LANGUAGE": {
      // Replace the existing state entirely by returning the new value
      return (state = action.payload)
    }
    default:
      return state
  }
}

function languageCatalogs(state = {}, action) {
  switch (action.type) {
    // omit other reducer cases
    case "UPDATE_LANGUAGE": {
      // Replace the existing state entirely by returning the new value
      return (state = action.payload.langCatalog)
    }
    default:
      return state
  }
}

function instaContainerHeight(state = 0, action) {
  switch (action.type) {
    // omit other reducer cases
    case "UPDATE_CONTAINER_HEIGHT": {
      // Replace the existing state entirely by returning the new value
      return (state = action.payload)
    }
    default:
      return state
  }
}

function activityTitle(state = "", action) {
  switch (action.type) {
    // omit other reducer cases
    case "UPDATE_ACTIVITY_TITLE": {
      // Replace the existing state entirely by returning the new value
      return (state = action.payload)
    }
    default:
      return state
  }
}

function activityBackgroundColor(state = "", action) {
  switch (action.type) {
    // omit other reducer cases
    case "UPDATE_ACTIVITY_COLOR": {
      // Replace the existing state entirely by returning the new value
      return (state = action.payload)
    }
    default:
      return state
  }
}

function showNavbar(state = { show: true, delay: 0 }, action) {
  switch (action.type) {
    // omit other reducer cases
    case "UPDATE_SHOW_NABAR": {
      // Replace the existing state entirely by returning the new value
      return (state = {
        show: action.payload.show,
        delay: action.payload.delay,
      })
    }
    default:
      return state
  }
}

function showMenu(state = false, action) {
  switch (action.type) {
    // omit other reducer cases
    case "UPDATE_SHOW_MENU": {
      // Replace the existing state entirely by returning the new value
      return (state = action.payload)
    }
    default:
      return state
  }
}

function navColor(state = "white", action) {
  switch (action.type) {
    // omit other reducer cases
    case "UPDATE_NAV_COLOR": {
      // Replace the existing state entirely by returning the new value
      return (state = action.payload)
    }
    default:
      return state
  }
}

function showTransition(state = false, action) {
  switch (action.type) {
    // omit other reducer cases
    case `SHOW_TRANSITION`: {
      // Replace the existing state entirely by returning the new value
      return (state = action.payload)
    }
    default:
      return state
  }
}

function isTouchScreen(state = false, action) {
  switch (action.type) {
    // omit other reducer cases
    case `UPDATE_IS_TOUCHSCREEN`: {
      // Replace the existing state entirely by returning the new value
      return (state = action.payload)
    }
    default:
      return state
  }
}

function showGalleryCursors(state = true, action) {
  switch (action.type) {
    // omit other reducer cases
    case `UPDATE_SHOW_GALLERY_CURSORS`: {
      // Replace the existing state entirely by returning the new value
      return (state = action.payload)
    }
    default:
      return state
  }
}
function showServicesCursors(state = true, action) {
  switch (action.type) {
    // omit other reducer cases
    case `UPDATE_SHOW_SERVICES_CURSORS`: {
      // Replace the existing state entirely by returning the new value
      return (state = action.payload)
    }
    default:
      return state
  }
}

function showIntroCursors(state = true, action) {
  switch (action.type) {
    // omit other reducer cases
    case `UPDATE_SHOW_INTRO_CURSORS`: {
      // Replace the existing state entirely by returning the new value
      return (state = action.payload)
    }
    default:
      return state
  }
}

function windowWidth(state = "", action) {
  switch (action.type) {
    // omit other reducer cases
    case `UPDATE_WINDOW_WIDTH`: {
      // Replace the existing state entirely by returning the new value
      return (state = action.payload)
    }
    default:
      return state
  }
}

function showReservationBtn(state = true, action) {
  switch (action.type) {
    // omit other reducer cases
    case `SHOW_RESERVATION_CENTER`: {
      // Replace the existing state entirely by returning the new value
      return (state = false)
    }
    case `HIDE_RESERVATION_CENTER`: {
      // Replace the existing state entirely by returning the new value
      return (state = true)
    }
    case `SHOW_MAIN_RESERVATION_BUTTON`: {
      // Replace the existing state entirely by returning the new value
      return (state = action.payload)
    }
    default:
      return state
  }
}

function showReservationCenter(state = false, action) {
  switch (action.type) {
    // omit other reducer cases
    case `SHOW_RESERVATION_CENTER`: {
      // Replace the existing state entirely by returning the new value
      return (state = true)
    }
    case `HIDE_RESERVATION_CENTER`: {
      // Replace the existing state entirely by returning the new value
      return (state = false)
    }
    default:
      return state
  }
}

function startingDate(state = "", action) {
  switch (action.type) {
    // omit other reducer cases
    case `UPDATE_STARTING_DATE`: {
      // Replace the existing state entirely by returning the new value
      return (state = action.payload)
    }
    case "UPDATE_LANGUAGE": {
      state = ""
    }
    default:
      return state
  }
}

function endingDate(state = "", action) {
  switch (action.type) {
    // omit other reducer cases
    case `UPDATE_STARTING_DATE`: {
      // Replace the existing state entirely by returning the new value
      if (state === "" || state.getTime() <= action.payload.getTime()) {
        const newDate = new Date(action.payload)
        state = new Date()
        state = new Date(
          newDate.setDate(new Date(action.payload.getDate() + 1))
        )
      }
      return state
    }
    case `UPDATE_ENDING_DATE`: {
      // Replace the existing state entirely by returning the new value
      return (state = action.payload)
    }
    case "UPDATE_LANGUAGE": {
      state = ""
    }
    default:
      return state
  }
}

function stayPrice(state = 200, action) {
  switch (action.type) {
    // omit other reducer cases
    case `UPDATE_STAY_PRICE`: {
      // Replace the existing state entirely by returning the new value
      return (state = action.payload)
    }
    default:
      return state
  }
}

function showRoomBarReservation(state = false, action) {
  switch (action.type) {
    // omit other reducer cases
    case `SHOW_ROOM_RESERVATION`: {
      // Replace the existing state entirely by returning the new value
      return (state = action.payload)
    }
    default:
      return state
  }
}

function roomReservationInfos(state = { price: "", availability: "" }, action) {
  switch (action.type) {
    // omit other reducer cases
    case `UPDATE_ROOM_RESERVATION_INFO`: {
      // Replace the existing state entirely by returning the new value
      return (state = {
        price: action.payload.price,
        availability: action.payload.availability,
      })
    }
    default:
      return state
  }
}

function mainImageLoaded(state = true, action) {
  switch (action.type) {
    case `IMG_LOADED`: {
      return (state = true)
    }
    case `IMG_NOT_LOADED`: {
      return (state = false)
    }
    default:
      return state
  }
}

function firstTimeLayoutRendered(state = true, action) {
  switch (action.type) {
    case `UPDATE_LAYOUT_RENDERED`: {
      return (state = false)
    }
    default:
      return state
  }
}

function clickableLinksMenu(state = true, action) {
  switch (action.type) {
    case `NOT_CLICKABLE`: {
      return (state = false)
    }
    case `CLICKABLE`: {
      return (state = true)
    }
    default:
      return state
  }
}

export function scrolltriggerReady(state = false, action) {
  switch (action.type) {
    case "SCROLLTRIGGER_READY":
      return (state = action.payload)
    default:
      return state
  }
}

export function scrollOffset(state = 0, action) {
  switch (action.type) {
    case "UPDATE_OFFSET":
      return (state = action.payload)
    case "RESET_OFFSET":
      return (state = 0)
    default:
      return state
  }
}

export function resetOffset(state = false, action) {
  switch (action.type) {
    case "RESET_OFFSET":
      return (state = action.payload)
    default:
      return state
  }
}

export function roomListCurrentTitle(state = "comfort-room", action) {
  switch (action.type) {
    case "UPDATE_ROOM_LIST_TITLE":
      return (state = action.payload)
    default:
      return state
  }
}

export function roomListColor(state = "white", action) {
  switch (action.type) {
    case "UPDATE_ROOM_LIST_COLOR":
      return (state = action.payload)
    default:
      return state
  }
}

export function isRoomsPage(state = false, action) {
  switch (action.type) {
    case "IS_ROOMS_PAGE":
      return (state = action.payload)
    default:
      return state
  }
}

export function isFixboxVisible(state = "hidden", action) {
  switch (action.type) {
    case "UPDATE_FIXBOX_VISIBILITY":
      return (state = action.payload)
    default:
      return state
  }
}

export function isVideoPlaying(state = false, action) {
  switch (action.type) {
    case "UPDATE_IS_PLAYING":
      return (state = action.payload)
    case "UPDATE_BANNER_SIGHT":
      if (state) {
        return (state = false)
      }
    default:
      return state
  }
}

export function isBannerInSight(state = false, action) {
  switch (action.type) {
    case "UPDATE_BANNER_SIGHT":
      return (state = action.payload)
    default:
      return state
  }
}

export function isCookiesVisible(state = true, action) {
  switch (action.type) {
    case "UPDATE_COOKIES_BANNER":
      return (state = action.payload)
    default:
      return state
  }
}

export function fetchingAvailabilities(state = false, action) {
  switch (action.type) {
    case "UPDATE_FETCHING_AVAILABILITIES":
      return (state = action.payload)
    default:
      return state
  }
}

export function roomsAvailabilities(state = [], action) {
  switch (action.type) {
    case "UPDATE_ROOMS_AVAILABILITIES":
      return (state = action.payload)
    default:
      return state
  }
}

export function roomUrl(state = "", action) {
  switch (action.type) {
    case "UPDATE_ROOM_URL":
      return (state = action.payload)
    default:
      return state
  }
}
export function loadingBannerFromRoom(state = false, action) {
  switch (action.type) {
    case "IS_FROM_ROOM":
      return (state = action.payload)
    default:
      return state
  }
}

export function roomPageAnimation3sDelay(state = true, action) {
  switch (action.type) {
    case "FIRED_FROM_NAVBAR":
      return (state = action.payload)
    default:
      return state
  }
}

export function showArrowDown(state = true, action) {
  switch (action.type) {
    case "UPDATE_SHOW_ARROW_DOWN":
      return (state = action.payload)
    default:
      return state
  }
}

export function isModalOpen(state = false, action) {
  switch (action.type) {
    case "UPDATE_OPEN_MODAL":
      return (state = action.payload)
    default:
      return state
  }
}

export function modalPhotos(state = [], action) {
  switch (action.type) {
    case "SET_PHOTOS_MODAL":
      return (state = action.payload)
    default:
      return state
  }
}

export function sessionId(state = "", action) {
  switch (action.type) {
    case "SET_SESSION_ID":
      return (state = action.payload)
    default:
      return state
  }
}

export default combineReducers({
  language,
  languageCatalogs,
  instaContainerHeight,
  activityBackgroundColor,
  showMenu,
  showNavbar,
  navColor,
  showTransition,
  activityTitle,
  isTouchScreen,
  showGalleryCursors,
  showIntroCursors,
  windowWidth,
  showReservationBtn,
  showReservationCenter,
  startingDate,
  endingDate,
  stayPrice,
  showRoomBarReservation,
  roomReservationInfos,
  mainImageLoaded,
  firstTimeLayoutRendered,
  clickableLinksMenu,
  scrolltriggerReady,
  scrollOffset,
  resetOffset,
  roomListCurrentTitle,
  roomListColor,
  isRoomsPage,
  isFixboxVisible,
  isVideoPlaying,
  isBannerInSight,
  isCookiesVisible,
  showServicesCursors,
  roomsAvailabilities,
  fetchingAvailabilities,
  roomUrl,
  loadingBannerFromRoom,
  roomPageAnimation3sDelay,
  showArrowDown,
  isModalOpen,
  modalPhotos,
  sessionId,
})
