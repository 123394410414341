import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { connect } from "react-redux"
import { console } from "globalthis/implementation"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */
//test new format
export const Image = ({ src, loaded }) => {
  const data = useStaticQuery(graphql`
    query {
      hotel: file(relativePath: { eq: "face-acr.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      rooms: file(relativePath: { eq: "privilege.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      spa: file(relativePath: { eq: "sauna-2.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      activities: file(relativePath: { eq: "drone-castle.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      contact: file(relativePath: { eq: "massage.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      privilege: file(relativePath: { eq: "privilege.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      classic: file(relativePath: { eq: "classic.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      gardenComfort: file(relativePath: { eq: "garden-confort.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      comfortSup: file(relativePath: { eq: "comfort-sup.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      classic: file(relativePath: { eq: "classic.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      prestigeBlue: file(relativePath: { eq: "prestige-5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      executive: file(relativePath: { eq: "executive.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      chateauSunrise: file(relativePath: { eq: "chateau-sunrise.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      chateauSunsetPortrait: file(
        relativePath: { eq: "chateau-sunset-portrait.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      lobbyPortrait: file(relativePath: { eq: "lobby-portrait.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      chateauMontgolfiereSunset: file(
        relativePath: { eq: "chateau-montgolfiere-sunset.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      roomViewChateau: file(relativePath: { eq: "room-view.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      poolFacadeDrone: file(relativePath: { eq: "pool-facade-drone.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      spaFace: file(relativePath: { eq: "spa-face.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      glassWine: file(relativePath: { eq: "glass-wine.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      laptopSalon: file(relativePath: { eq: "laptop-salon-1.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      prestigeRykiel: file(relativePath: { eq: "prestige-rykiel.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fleursParasol: file(relativePath: { eq: "fleurs-parasols.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mirrorStairs: file(relativePath: { eq: "mirror-stairs.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      privilegeRoom: file(relativePath: { eq: "chambre-4.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      castleView: file(relativePath: { eq: "castle-view.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sdbPrestige: file(relativePath: { eq: "sdb-prestige.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      gardenRose: file(relativePath: { eq: "garden-rose.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      saunaSpa: file(relativePath: { eq: "sauna-spa.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      jacuzziSpa: file(relativePath: { eq: "jacuzzi-spa.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      castleNight: file(relativePath: { eq: "banner-loader.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      hotelThumbnail: file(relativePath: { eq: "rose-garden-facade.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      roseGardenFacade: file(relativePath: { eq: "rose-garden-facade.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      chambreMaquilleuseDetails: file(
        relativePath: { eq: "chambre-5-maquilleuse.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fragonardDetails: file(relativePath: { eq: "fragonard-portrait.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      perfumesExecutiveDetails: file(
        relativePath: { eq: "parfums-executive.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      viewChateauRooms: file(relativePath: { eq: "piscine-castle.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bathroomRooms: file(relativePath: { eq: "sdb-prestige-5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      nespressoRooms: file(relativePath: { eq: "collage-confort.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      peintureRoomsDetails: file(
        relativePath: { eq: "peinture-chambre-3.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fragonardPortraitRoomsDetails: file(
        relativePath: { eq: "fragonard-portrait.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      chambre6MirrorRoomsDetails: file(
        relativePath: { eq: "mirror-6-portrait.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      chambre3PortraitRoomsDetails: file(
        relativePath: { eq: "mirror-3-portrait.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      prestige5ListRooms: file(relativePath: { eq: "prestige-5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      superiorListRooms: file(relativePath: { eq: "chambre-confort-sup.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      superiorListRoomsThumb: file(
        relativePath: { eq: "chambre-confort-sup.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      privilegeListRooms: file(relativePath: { eq: "chambre-4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      executiveListRooms: file(relativePath: { eq: "executive.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      comfortListRooms: file(relativePath: { eq: "confort-room.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      prestigeTerrasseListRooms: file(
        relativePath: { eq: "classic-exterior.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      gardenComfortListRooms: file(relativePath: { eq: "garden-comfort.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      gardenPrestigeListRooms: file(
        relativePath: { eq: "garden-prestige.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      portraitRoomSuperiorBed: file(
        relativePath: { eq: "portrait-bed-3.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      squareRoom3: file(relativePath: { eq: "square-room-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sliderRoom3: file(relativePath: { eq: "slider-room-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      squareRoom3Mirror: file(
        relativePath: { eq: "square-room-3-mirror.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mirror3Portrait: file(relativePath: { eq: "mirror-3-portrait.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      smallImageRoomPhotos: file(
        relativePath: { eq: "small-image-room-photos.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mainImageRoomPhotos: file(
        relativePath: { eq: "main-image-room-photos.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      portraitRoomPhotos: file(
        relativePath: { eq: "portrait-room-photos.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mainBathroomRoom3: file(
        relativePath: { eq: "main-bathroom-room-3.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      showerRoom3: file(relativePath: { eq: "shower-room-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      nespressoRoom3: file(relativePath: { eq: "nespresso-room-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      portraitBathroomRoom3: file(
        relativePath: { eq: "portrait-bathroom-room-superior.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sofaLampRoom3: file(relativePath: { eq: "sofa-lamp-room-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      lampRoom3: file(relativePath: { eq: "lamp-room-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mirrorBigImageRoom3: file(
        relativePath: { eq: "mirror-big-image-room-3.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mirrorPortraitImageRoom3: file(
        relativePath: { eq: "mirror-portrait-image-room-3.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      spaBanner: file(relativePath: { eq: "spa-banner.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      spaLetterS: file(relativePath: { eq: "spa-letter-s.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      saunaSpaComponent: file(relativePath: { eq: "inside-sauna.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      massageSpaComponent: file(relativePath: { eq: "massage.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      jaccuzziSpaComponent: file(relativePath: { eq: "inside-jaccuzzi.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      aLetterSpaComponent: file(relativePath: { eq: "a-letter-massage.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      pLetterSpaComponent: file(relativePath: { eq: "p-letter-jaccuzzi.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      portraitPrestige5: file(
        relativePath: { eq: "portrait-prestige-five.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sliderRoom5: file(relativePath: { eq: "slider-room-5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      squareRoom5: file(relativePath: { eq: "square-room-5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mirrorBigImageRoom5: file(
        relativePath: { eq: "mirror-big-image-room-5.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mirrorPortraitImageRoom5: file(
        relativePath: { eq: "mirror-portrait-image-room-5.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mainImageRoom5: file(relativePath: { eq: "main-image-room-5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mainImageRoom5Thumb: file(relativePath: { eq: "main-image-room-5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      portraitRoomPrestige5: file(
        relativePath: { eq: "portrait-room-prestige-5.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      smallImagePrestige5: file(
        relativePath: { eq: "small-image-prestige-5.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mainBathroom5: file(relativePath: { eq: "main-bathroom-room-5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      peignoirRoom5: file(relativePath: { eq: "peignoir-room-5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      nespressoRoom5: file(relativePath: { eq: "nespresso-room-5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      portraitBathroomRoom5: file(
        relativePath: { eq: "portrait-bathroom-room-5.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      lastSliderRoom5: file(relativePath: { eq: "slider-room-five-view.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      lampRoomFive: file(relativePath: { eq: "view-castle-square.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      squareRoom5Mirror: file(
        relativePath: { eq: "square-room-5-mirror.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mirror5Portrait: file(relativePath: { eq: "mirror-five-portrait.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      portraitRoomConfort: file(
        relativePath: { eq: "portrait-room-confort.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sliderRoom6: file(relativePath: { eq: "slider-room-6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      squareRoom6: file(relativePath: { eq: "square-room-6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mirrorBigImageRoom6: file(
        relativePath: { eq: "mirror-big-image-room-6.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mirrorPortraitImageRoom6: file(
        relativePath: { eq: "mirror-portrait-image-room-6.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mainImageRoom6: file(relativePath: { eq: "main-image-room-6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mainImageRoom6Thumb: file(relativePath: { eq: "main-image-room-6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      portraitRoomPhoto6: file(
        relativePath: { eq: "portrait-room-photo-6.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      smallImageRoom6: file(relativePath: { eq: "small-image-room-6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      showerRoomSix: file(relativePath: { eq: "shower-room-six.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mainBathroomRoomSix: file(
        relativePath: { eq: "main-bathroom-room-six.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      portraitBathroom6: file(
        relativePath: { eq: "portrait-bathroom-room-6.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      lastSlider6: file(relativePath: { eq: "last-slider-6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      lastSliderBig6: file(relativePath: { eq: "last-slider-big-6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      squareRoom6Mirror: file(
        relativePath: { eq: "square-room-6-mirror.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      lastMirror6Portrait: file(
        relativePath: { eq: "last-mirror-6-portrait.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      portraitPrestigeMauve: file(
        relativePath: { eq: "portrait-prestige-mauve.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sliderRoomMauve: file(relativePath: { eq: "slider-room-mauve.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      squareRoomMauve: file(relativePath: { eq: "square-room-mauve.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mirrorBigImageRoomMauve: file(
        relativePath: { eq: "mirror-big-image-room-mauve.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mirrorPortraitImageRoomMauve: file(
        relativePath: { eq: "mirror-portrait-image-room-mauve.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mainImageRoomMauve: file(
        relativePath: { eq: "main-image-room-mauve.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mainImageRoomMauveThumb: file(
        relativePath: { eq: "main-image-room-mauve.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      portraitRoomPrestigeMauve: file(
        relativePath: { eq: "portrait-room-prestige-mauve.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      smallImagePrestigeMauve: file(
        relativePath: { eq: "small-image-prestige-mauve.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mainBathroomRoomMauve: file(
        relativePath: { eq: "main-bathroom-room-mauve.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      productRoomMauve: file(relativePath: { eq: "prooduct-room-mauve.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      portraitBathroomRoomMauve: file(
        relativePath: { eq: "portrait-bathroom-room-mauve.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      lastSliderRoomMauve: file(
        relativePath: { eq: "last-slider-room-mauve.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      squareRoomMauveMirror: file(
        relativePath: { eq: "square-room-mauve-mirror.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mirrorMauvePortrait: file(
        relativePath: { eq: "mirror-mauve-portrait.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      portraitPrestigeAnis: file(
        relativePath: { eq: "portrait-prestige-anis.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sliderRoomAnis: file(relativePath: { eq: "slider-room-anis.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      squareRoomAnis: file(relativePath: { eq: "square-room-anis.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mirrorBigRoomAnis: file(
        relativePath: { eq: "mirror-big-image-room-anis.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mirrorPortraitRoomAnis: file(
        relativePath: { eq: "mirror-portrait-image-room-anis.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mainImageRoomAnis: file(
        relativePath: { eq: "main-image-room-anis.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mainImageRoomAnisThumb: file(
        relativePath: { eq: "main-image-room-anis.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      smallImageAnis: file(relativePath: { eq: "small-image-anis.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      portraitRoomPrestigeAnis: file(
        relativePath: { eq: "portrait-room-prestige-anis.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      smallImagePrestigeAnis: file(
        relativePath: { eq: "small-image-prestige-anis.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mainBathroomRoomAnis: file(
        relativePath: { eq: "main-bathroom-room-anis.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mainBathroomRoomAnis: file(
        relativePath: { eq: "main-bathroom-room-anis.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      portraitBathroomRoomAnis: file(
        relativePath: { eq: "portrait-bathroom-room-anis.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      lastSliderRoomAnis: file(
        relativePath: { eq: "last-slider-room-anis.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      squareRoomAnisMirror: file(
        relativePath: { eq: "square-room-anis-mirror.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mirrorAnisPortrait: file(
        relativePath: { eq: "mirror-anis-portrait.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      portraitRoomExecutiveBed: file(
        relativePath: { eq: "portrait-room-executive-bed.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sliderRoom8: file(relativePath: { eq: "slider-room-8.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      squareRoom8: file(relativePath: { eq: "square-room-8.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mirrorBigImageRoom8: file(
        relativePath: { eq: "mirror-big-image-room-eight.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mirrorPortraitImageRoom8: file(
        relativePath: { eq: "mirror-portrait-image-room-8.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mainImageRoomPhotos8: file(
        relativePath: { eq: "main-image-room-photos-8.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mainImageRoomPhotos8Thumb: file(
        relativePath: { eq: "main-image-room-photos-8.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      portraitRoomPhotos8: file(
        relativePath: { eq: "portrait-room-photos-8.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      smallImageRoomPhotos8: file(
        relativePath: { eq: "small-image-room-photos-8.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mainBathroomRoom8: file(
        relativePath: { eq: "main-bathroom-room-8.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      portraitBathroomRoom8: file(
        relativePath: { eq: "portrait-bathroom-room-8.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      squareRoom8Mirror: file(
        relativePath: { eq: "square-room-8-mirror.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mirror8Portrait: file(relativePath: { eq: "mirror-8-portrait.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      lastSlider8: file(relativePath: { eq: "last-slider-8.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bicycleForest: file(relativePath: { eq: "bicycle-forest.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      balloonSky: file(relativePath: { eq: "balloon-sky.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      chambordSunrise: file(relativePath: { eq: "chambord-sunrise.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      piscineLandscape: file(relativePath: { eq: "piscine-landscape.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      cocktailAcr: file(relativePath: { eq: "cocktail-acr.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      golfAcr: file(relativePath: { eq: "golf-acr.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      insideSpaActivities: file(relativePath: { eq: "inside-jaccuzzi.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      massageActivity: file(relativePath: { eq: "inside-jaccuzzi.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bicycleForestLandscape: file(
        relativePath: { eq: "bicycle-forest-landscape.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      hotAirBalloonLandscape: file(
        relativePath: { eq: "hot-air-balloon-landscape.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bannerRoom: file(relativePath: { eq: "main-image-room-photos-8.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      curtainsLobby: file(relativePath: { eq: "curtains-lobby.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mirrorLobby: file(relativePath: { eq: "mirror-lobby.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      beauval: file(relativePath: { eq: "photo-beauval-land.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      concierge: file(relativePath: { eq: "concierge.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      gardenPrestigeBedPortrait: file(
        relativePath: { eq: "portrait-garden-prestige-bed.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mainSalonPrestigeJardin: file(
        relativePath: { eq: "main-salon-prestige-jardin.JPG" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      portraitSalonGardenPrestige: file(
        relativePath: { eq: "portrait-salon-garden-prestige.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      salonPrestigeJardin: file(
        relativePath: { eq: "salon-prestige-jardin.JPG" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mainBathroomPrestigeGarden: file(
        relativePath: { eq: "main-bathroom-prestige-garden.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mirrorPortraitPrestigeGarden: file(
        relativePath: { eq: "mirror-portrait-prestige-garden.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mirrorMainPrestigeGarden: file(
        relativePath: { eq: "mirror-main-prestige-garden.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mirrorMainPrestigeGardenThumb: file(
        relativePath: { eq: "mirror-main-prestige-garden.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      zoomRoses: file(relativePath: { eq: "zoom-roses.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      zoomRosesFacade: file(relativePath: { eq: "zoom-roses-facade.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      annexTreeColor: file(relativePath: { eq: "annex-tree-color.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      poolFaceFacade: file(relativePath: { eq: "pool-face-facade.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      portraitRoomGarden: file(
        relativePath: { eq: "portrait-room-garden.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      portraitBathroomGarden: file(
        relativePath: { eq: "portrait-bathroom-jardin.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      firstMirrorMainGardenComfort: file(
        relativePath: { eq: "first-mirror-main-garden-comfort.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      firstMirrorPortraitGardenComfort: file(
        relativePath: { eq: "first-mirror-portrait-garden-comfort.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mainRoomGardenComfort: file(
        relativePath: { eq: "main-room-garden-comfort.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mainRoomGardenComfortThumb: file(
        relativePath: { eq: "main-room-garden-comfort.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      portraitChambreJardin: file(
        relativePath: { eq: "portrait-chambre-jardin.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      smallImageGardenComfort: file(
        relativePath: { eq: "small-image-garden-comfort.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mainBathroomGardenComfort: file(
        relativePath: { eq: "main-bathroom-garden-comfort.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      smallBathroomGardenComfort: file(
        relativePath: { eq: "small-bathroom-garden-comfort.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      smallShowerGardenComfort: file(
        relativePath: { eq: "small-shower-garden-comfort.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      portraitChambrePrestigeTerrasse: file(
        relativePath: { eq: "portrait-chambre-prestige-terrasse.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      firstMirrorMainPrestigeTerrasse: file(
        relativePath: { eq: "first-mirror-main-prestige-terrasse.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      firstMirrorPortraitPrestigeTerrasse: file(
        relativePath: { eq: "first-mirror-portrait-prestige-terrasse.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      firstSquarePrestigeTerrasse: file(
        relativePath: { eq: "first-square-prestige-terrasse.JPG" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mainBathroomPrestigeTerrasse: file(
        relativePath: { eq: "main-bathroom-prestige-terrasse.JPG" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mainImagePrestigeTerrasse: file(
        relativePath: { eq: "main-image-prestige-terrasse.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mainImagePrestigeTerrasseThumb: file(
        relativePath: { eq: "main-image-prestige-terrasse.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      lastMirrorMainPrestigeTerrasse: file(
        relativePath: { eq: "last-mirror-main-prestige-terrasse.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      lastMirrorPortraitPrestigeTerrasse: file(
        relativePath: { eq: "last-mirror-portrait-prestige-terrasse.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      firstSliderPrestigeTerrasse: file(
        relativePath: { eq: "first-slider-prestige-terrasse.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      firstSliderSquarePrivilege: file(
        relativePath: { eq: "first-slider-square-privilege.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mainChambrePrivilege: file(
        relativePath: { eq: "main-chambre-privilege.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      firstSliderPrivilege: file(
        relativePath: { eq: "first-slider-privilege.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mainImagePrivilege: file(
        relativePath: { eq: "main-image-privilege.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mainImagePrivilegeThumb: file(
        relativePath: { eq: "main-image-privilege.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      smallImagePrivilege: file(
        relativePath: { eq: "small-image-privilege.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      smallBathroomPrivilege: file(
        relativePath: { eq: "small-bathroom-privilege.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      portraitRoomPrivilege: file(
        relativePath: { eq: "portrait-room-privilege.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      portraitShowerPrivilege: file(
        relativePath: { eq: "portrait-shower-privilege.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mainBathroomPrivilege: file(
        relativePath: { eq: "main-bathroom-privilege.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      lastMirrorMainPrivilege: file(
        relativePath: { eq: "last-mirror-main-privilege.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      lastMirrorPortraitPrivilege: file(
        relativePath: { eq: "last-mirror-portrait-privilege.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      firstMirrortMainPrivilege: file(
        relativePath: { eq: "first-mirror-main-privilege.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      firstMirrorPortraitPrivilege: file(
        relativePath: { eq: "first-mirror-portrait-privilege.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      lastSliderSquarePrivilege: file(
        relativePath: { eq: "last-slider-square-privilege.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      lastSliderPrivilege: file(
        relativePath: { eq: "last-slider-privilege.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      breakfastContinental: file(
        relativePath: { eq: "breakfast-continental.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      conciergerie: file(relativePath: { eq: "conciergerie.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      facadeMontgolfiere: file(
        relativePath: { eq: "facade-montgolfiere-2.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      melba: file(relativePath: { eq: "melba-crop.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ascenceur: file(relativePath: { eq: "ascenceur.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      parasolFacade: file(relativePath: { eq: "parasol-facade.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      insta1: file(relativePath: { eq: "instagram-0.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      insta2: file(relativePath: { eq: "instagram-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      insta3: file(relativePath: { eq: "instagram-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      insta4: file(relativePath: { eq: "instagram-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      insta5: file(relativePath: { eq: "instagram-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      insta6: file(relativePath: { eq: "bug-instagram-5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      insta7: file(relativePath: { eq: "instagram-6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      insta8: file(relativePath: { eq: "instagram-7.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      insta9: file(relativePath: { eq: "instagram-8.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      {src === "facade-acr" ? (
        <Img
          loading="eager"
          fluid={{ ...data.hotel.childImageSharp.fluid, aspectRatio: 3 / 2 }}
        />
      ) : src === 2 ? (
        <Img loading="eager" fluid={data.rooms.childImageSharp.fluid} />
      ) : src === "spa" ? (
        <Img
          loading="eager"
          fluid={{ ...data.spa.childImageSharp.fluid, aspectRatio: 7 / 8 }}
        />
      ) : src === 4 ? (
        <Img loading="eager" fluid={data.activities.childImageSharp.fluid} />
      ) : src === "homepageImage" ? (
        <Img loading="eager" fluid={data.hotel.childImageSharp.fluid} />
      ) : src === "privilege" ? (
        <Img
          loading="eager"
          fluid={{
            ...data.privilege.childImageSharp.fluid,
            aspectRatio: 7 / 8,
          }}
        />
      ) : src === "classic" ? (
        <Img loading="eager" fluid={data.classic.childImageSharp.fluid} />
      ) : src === "comfortSup" ? (
        <Img loading="eager" fluid={data.comfortSup.childImageSharp.fluid} />
      ) : src === "gardenComfort" ? (
        <Img loading="eager" fluid={data.gardenComfort.childImageSharp.fluid} />
      ) : src === "prestigeBlue" ? (
        <Img
          loading="eager"
          fluid={{
            ...data.prestigeBlue.childImageSharp.fluid,
            aspectRatio: 7 / 8,
          }}
        />
      ) : src === "executive" ? (
        <Img
          loading="eager"
          fluid={{
            ...data.executive.childImageSharp.fluid,
            aspectRatio: 7 / 8,
          }}
        />
      ) : src === "chateau-sunrise" ? (
        <Img
          loading="eager"
          fluid={{ ...data.chateauSunrise.childImageSharp.fluid }}
        />
      ) : src === "chateau-sunset-portrait" ? (
        <Img
          loading="eager"
          fluid={{
            ...data.chateauSunsetPortrait.childImageSharp.fluid,
            aspectRatio: 7 / 8,
          }}
        />
      ) : src === "chateau-montgolfiere-sunset" ? (
        <Img
          loading="eager"
          fluid={{
            ...data.chateauMontgolfiereSunset.childImageSharp.fluid,
            aspectRatio: 7 / 8,
          }}
        />
      ) : src === "lobby-portrait" ? (
        <Img
          loading="eager"
          fluid={{
            ...data.lobbyPortrait.childImageSharp.fluid,
            aspectRatio: 7 / 8,
          }}
        />
      ) : src === "room-view-chateau" ? (
        <Img
          loading="eager"
          fluid={{
            ...data.roomViewChateau.childImageSharp.fluid,
            aspectRatio: 7 / 8,
          }}
        />
      ) : src === "pool-facade-drone" ? (
        <Img
          loading="eager"
          fluid={{
            ...data.poolFacadeDrone.childImageSharp.fluid,
            aspectRatio: 7 / 8,
          }}
        />
      ) : src === "spa-face" ? (
        <Img
          loading="eager"
          fluid={{ ...data.spaFace.childImageSharp.fluid, aspectRatio: 7 / 8 }}
        />
      ) : src === "glass-wine" ? (
        <Img
          loading="eager"
          fluid={{
            ...data.glassWine.childImageSharp.fluid,
            aspectRatio: 7 / 8,
          }}
        />
      ) : src === "laptop-salon" ? (
        <Img
          loading="eager"
          fluid={{
            ...data.laptopSalon.childImageSharp.fluid,
            aspectRatio: 3 / 2,
          }}
        />
      ) : src === "prestige-rykiel" ? (
        <Img
          loading="eager"
          fluid={{
            ...data.prestigeRykiel.childImageSharp.fluid,
            aspectRatio: 3 / 2,
          }}
        />
      ) : src === "fleurs-parasol" ? (
        <Img
          loading="eager"
          fluid={{
            ...data.fleursParasol.childImageSharp.fluid,
            aspectRatio: 3 / 2,
          }}
        />
      ) : src === "mirror-stairs" ? (
        <Img
          loading="eager"
          fluid={{
            ...data.mirrorStairs.childImageSharp.fluid,
            aspectRatio: 3 / 2,
          }}
        />
      ) : src === "privilege-room" ? (
        <Img
          loading="eager"
          fluid={{
            ...data.privilegeRoom.childImageSharp.fluid,
            aspectRatio: 3 / 2,
          }}
        />
      ) : src === "castle-view" ? (
        <Img
          loading="eager"
          fluid={{
            ...data.castleView.childImageSharp.fluid,
            aspectRatio: 3 / 2,
          }}
        />
      ) : src === "sdb-prestige" ? (
        <Img
          loading="eager"
          fluid={{
            ...data.sdbPrestige.childImageSharp.fluid,
            aspectRatio: 3 / 2,
          }}
        />
      ) : src === "garden-rose" ? (
        <Img
          loading="eager"
          fluid={{
            ...data.gardenRose.childImageSharp.fluid,
            aspectRatio: 3 / 2,
          }}
        />
      ) : src === "sauna-spa" ? (
        <Img
          loading="eager"
          fluid={{ ...data.saunaSpa.childImageSharp.fluid, aspectRatio: 3 / 2 }}
        />
      ) : src === "jacuzzi-spa" ? (
        <Img
          loading="eager"
          fluid={{
            ...data.jacuzziSpa.childImageSharp.fluid,
            aspectRatio: 3 / 2,
          }}
        />
      ) : src === "hotel-thumbnail" ? (
        <Img
          loading="eager"
          fluid={{
            ...data.hotelThumbnail.childImageSharp.fluid,
            aspectRatio: 7 / 8,
          }}
        />
      ) : src === "chambre-maquilleuse-details" ? (
        <Img
          loading="eager"
          fluid={{
            ...data.chambreMaquilleuseDetails.childImageSharp.fluid,
            aspectRatio: 5 / 8,
          }}
        />
      ) : src === "fragonard-details" ? (
        <Img
          loading="eager"
          fluid={{
            ...data.fragonardDetails.childImageSharp.fluid,
            aspectRatio: 5 / 8,
          }}
        />
      ) : src === "banner-image" ? (
        <Img
          loading="eager"
          onLoad={loaded}
          fluid={{
            ...data.castleNight.childImageSharp.fluid,
            aspectRatio: 7 / 8,
          }}
        />
      ) : src === "parfums-executive-details" ? (
        <Img
          loading="eager"
          fluid={{
            ...data.perfumesExecutiveDetails.childImageSharp.fluid,
            aspectRatio: 5 / 8,
          }}
        />
      ) : src === "view-chateau-rooms" ? (
        <Img
          loading="eager"
          fluid={{ ...data.viewChateauRooms.childImageSharp.fluid }}
        />
      ) : src === "bathroom-rooms" ? (
        <Img
          loading="eager"
          fluid={{ ...data.bathroomRooms.childImageSharp.fluid }}
        />
      ) : src === "peinture-rooms-details" ? (
        <Img
          loading="eager"
          fluid={{ ...data.peintureRoomsDetails.childImageSharp.fluid }}
        />
      ) : src === "nespresso-rooms" ? (
        <Img
          loading="eager"
          fluid={{ ...data.nespressoRooms.childImageSharp.fluid }}
        />
      ) : src === "fragonard-portrait-rooms-details" ? (
        <Img
          loading="eager"
          fluid={{
            ...data.fragonardPortraitRoomsDetails.childImageSharp.fluid,
          }}
        />
      ) : src === "chambre-3-mirror-rooms-details" ? (
        <Img
          loading="eager"
          fluid={{ ...data.chambre3PortraitRoomsDetails.childImageSharp.fluid }}
        />
      ) : src === "prestige-5-rooms" ? (
        <Img
          loading="eager"
          fluid={{ ...data.prestige5ListRooms.childImageSharp.fluid }}
        />
      ) : src === "chambre-6-mirror-rooms-details" ? (
        <Img
          loading="eager"
          fluid={{ ...data.chambre6MirrorRoomsDetails.childImageSharp.fluid }}
        />
      ) : src === "comfort-superior-list-rooms" ? (
        <Img
          loading="eager"
          fluid={{ ...data.superiorListRooms.childImageSharp.fluid }}
        />
      ) : src === "comfort-superior-list-rooms-thumb" ? (
        //
        <Img
          loading="eager"
          fluid={{ ...data.superiorListRoomsThumb.childImageSharp.fluid }}
        />
      ) : src === "privilege-list-rooms" ? (
        <Img
          loading="eager"
          fluid={{ ...data.privilegeListRooms.childImageSharp.fluid }}
        />
      ) : src === "executive-list-rooms" ? (
        <Img
          loading="eager"
          fluid={{ ...data.executiveListRooms.childImageSharp.fluid }}
        />
      ) : src === "comfort-list-rooms" ? (
        <Img
          loading="eager"
          fluid={{ ...data.comfortListRooms.childImageSharp.fluid }}
        />
      ) : src === "prestige-terrasse-list-rooms" ? (
        <Img
          loading="eager"
          fluid={{ ...data.prestigeTerrasseListRooms.childImageSharp.fluid }}
        />
      ) : src === "garden-comfort-list-rooms" ? (
        <Img
          loading="eager"
          fluid={{ ...data.gardenComfortListRooms.childImageSharp.fluid }}
        />
      ) : src === "garden-prestige-list-rooms" ? (
        <Img
          loading="eager"
          fluid={{ ...data.gardenPrestigeListRooms.childImageSharp.fluid }}
        />
      ) : src === "portrait-room-superior-bed" ? (
        <Img
          loading="eager"
          fluid={{ ...data.portraitRoomSuperiorBed.childImageSharp.fluid }}
        />
      ) : src === "slider-room-3" ? (
        <Img
          loading="eager"
          fluid={{ ...data.sliderRoom3.childImageSharp.fluid }}
        />
      ) : src === "square-room-3" ? (
        <Img
          loading="eager"
          fluid={{ ...data.squareRoom3.childImageSharp.fluid }}
        />
      ) : src === "square-room-3-mirror" ? (
        <Img
          loading="eager"
          fluid={{ ...data.squareRoom3Mirror.childImageSharp.fluid }}
        />
      ) : src === "mirror-3-portrait" ? (
        <Img
          loading="eager"
          fluid={{ ...data.mirror3Portrait.childImageSharp.fluid }}
        />
      ) : src === "main-image-room-photos" ? (
        <Img
          loading="eager"
          fluid={{ ...data.mainImageRoomPhotos.childImageSharp.fluid }}
        />
      ) : src === "small-image-room-photos" ? (
        <Img
          loading="eager"
          fluid={{ ...data.smallImageRoomPhotos.childImageSharp.fluid }}
        />
      ) : src === "portrait-room-photos" ? (
        <Img
          loading="eager"
          fluid={{ ...data.portraitRoomPhotos.childImageSharp.fluid }}
        />
      ) : src === "main-bathroom-room-3" ? (
        <Img
          loading="eager"
          fluid={{ ...data.mainBathroomRoom3.childImageSharp.fluid }}
        />
      ) : src === "shower-room-3" ? (
        <Img
          loading="eager"
          fluid={{ ...data.showerRoom3.childImageSharp.fluid }}
        />
      ) : src === "nespresso-room-3" ? (
        <Img
          loading="eager"
          fluid={{ ...data.nespressoRoom3.childImageSharp.fluid }}
        />
      ) : src === "portrait-bathroom-room-3" ? (
        <Img
          loading="eager"
          fluid={{ ...data.portraitBathroomRoom3.childImageSharp.fluid }}
        />
      ) : src === "sofa-lamp-room-3" ? (
        <Img
          loading="eager"
          fluid={{ ...data.sofaLampRoom3.childImageSharp.fluid }}
        />
      ) : src === "lamp-room-3" ? (
        <Img
          loading="eager"
          fluid={{ ...data.lampRoom3.childImageSharp.fluid }}
        />
      ) : src === "mirror-big-image-room-3" ? (
        <Img
          loading="eager"
          fluid={{ ...data.mirrorBigImageRoom3.childImageSharp.fluid }}
        />
      ) : src === "spa-banner" ? (
        <Img
          loading="eager"
          fluid={{ ...data.spaBanner.childImageSharp.fluid }}
        />
      ) : src === "mirror-portrait-image-room-3" ? (
        <Img
          loading="eager"
          fluid={{ ...data.mirrorPortraitImageRoom3.childImageSharp.fluid }}
        />
      ) : src === "spa-letter-s" ? (
        <Img
          loading="eager"
          fluid={{ ...data.spaLetterS.childImageSharp.fluid }}
        />
      ) : src === "sauna-spa-component" ? (
        <Img
          loading="eager"
          fluid={{ ...data.saunaSpaComponent.childImageSharp.fluid }}
        />
      ) : src === "massage-spa-component" ? (
        <Img
          loading="eager"
          fluid={{ ...data.massageSpaComponent.childImageSharp.fluid }}
        />
      ) : src === "jaccuzzi-spa-component" ? (
        <Img
          loading="eager"
          fluid={{ ...data.jaccuzziSpaComponent.childImageSharp.fluid }}
        />
      ) : src === "a-letter-massage" ? (
        <Img
          loading="eager"
          fluid={{ ...data.aLetterSpaComponent.childImageSharp.fluid }}
        />
      ) : src === "p-letter-jaccuzzi" ? (
        <Img
          loading="eager"
          fluid={{ ...data.pLetterSpaComponent.childImageSharp.fluid }}
        />
      ) : src === "portrait-prestige-5" ? (
        <Img
          loading="eager"
          fluid={{ ...data.portraitPrestige5.childImageSharp.fluid }}
        />
      ) : src === "slider-room-5" ? (
        <Img
          loading="eager"
          fluid={{ ...data.sliderRoom5.childImageSharp.fluid }}
        />
      ) : src === "square-room-5" ? (
        <Img
          loading="eager"
          fluid={{ ...data.squareRoom5.childImageSharp.fluid }}
        />
      ) : src === "mirror-big-image-room-5" ? (
        <Img
          loading="eager"
          fluid={{ ...data.mirrorBigImageRoom5.childImageSharp.fluid }}
        />
      ) : src === "mirror-portrait-image-room-5" ? (
        <Img
          loading="eager"
          fluid={{ ...data.mirrorPortraitImageRoom5.childImageSharp.fluid }}
        />
      ) : src === "main-image-room-5" ? (
        <Img
          loading="eager"
          fluid={{ ...data.mainImageRoom5.childImageSharp.fluid }}
        />
      ) : src === "main-image-room-5-thumb" ? (
        //
        <Img
          loading="eager"
          fluid={{ ...data.mainImageRoom5Thumb.childImageSharp.fluid }}
        />
      ) : src === "portrait-room-prestige-5" ? (
        <Img
          loading="eager"
          fluid={{ ...data.portraitRoomPrestige5.childImageSharp.fluid }}
        />
      ) : src === "small-image-prestige-5" ? (
        <Img
          loading="eager"
          fluid={{ ...data.smallImagePrestige5.childImageSharp.fluid }}
        />
      ) : src === "main-bathroom-room-5" ? (
        <Img
          loading="eager"
          fluid={{ ...data.mainBathroom5.childImageSharp.fluid }}
        />
      ) : src === "peignoir-room-5" ? (
        <Img
          loading="eager"
          fluid={{ ...data.peignoirRoom5.childImageSharp.fluid }}
        />
      ) : src === "nespresso-room-5" ? (
        <Img
          loading="eager"
          fluid={{ ...data.nespressoRoom5.childImageSharp.fluid }}
        />
      ) : src === "portrait-bathroom-room-5" ? (
        <Img
          loading="eager"
          fluid={{ ...data.portraitBathroomRoom5.childImageSharp.fluid }}
        />
      ) : src === "last-slider-room-5" ? (
        <Img
          loading="eager"
          fluid={{ ...data.lastSliderRoom5.childImageSharp.fluid }}
        />
      ) : src === "lamp-room-5" ? (
        <Img
          loading="eager"
          fluid={{ ...data.lampRoomFive.childImageSharp.fluid }}
        />
      ) : src === "square-room-5-mirror" ? (
        <Img
          loading="eager"
          fluid={{ ...data.squareRoom5Mirror.childImageSharp.fluid }}
        />
      ) : src === "mirror-5-portrait" ? (
        <Img
          loading="eager"
          fluid={{ ...data.mirror5Portrait.childImageSharp.fluid }}
        />
      ) : src === "portrait-room-confort" ? (
        <Img
          loading="eager"
          fluid={{ ...data.portraitRoomConfort.childImageSharp.fluid }}
        />
      ) : src === "slider-room-6" ? (
        <Img
          loading="eager"
          fluid={{ ...data.sliderRoom6.childImageSharp.fluid }}
        />
      ) : src === "square-room-6" ? (
        <Img
          loading="eager"
          fluid={{ ...data.squareRoom6.childImageSharp.fluid }}
        />
      ) : src === "mirror-big-image-room-6" ? (
        <Img
          loading="eager"
          fluid={{ ...data.mirrorBigImageRoom6.childImageSharp.fluid }}
        />
      ) : src === "mirror-portrait-image-room-6" ? (
        <Img
          loading="eager"
          fluid={{ ...data.mirrorPortraitImageRoom6.childImageSharp.fluid }}
        />
      ) : src === "main-image-room-6" ? (
        <Img
          loading="eager"
          fluid={{ ...data.mainImageRoom6.childImageSharp.fluid }}
        />
      ) : src === "main-image-room-6-thumb" ? (
        //
        <Img
          loading="eager"
          fluid={{ ...data.mainImageRoom6Thumb.childImageSharp.fluid }}
        />
      ) : src === "portrait-room-photo-6" ? (
        <Img
          loading="eager"
          fluid={{ ...data.portraitRoomPhoto6.childImageSharp.fluid }}
        />
      ) : src === "small-image-room-6" ? (
        <Img
          loading="eager"
          fluid={{ ...data.smallImageRoom6.childImageSharp.fluid }}
        />
      ) : src === "main-bathroom-room-six" ? (
        <Img
          loading="eager"
          fluid={{ ...data.mainBathroomRoomSix.childImageSharp.fluid }}
        />
      ) : src === "shower-room-6" ? (
        <Img
          loading="eager"
          fluid={{ ...data.showerRoomSix.childImageSharp.fluid }}
        />
      ) : src === "portrait-bathroom-room-6" ? (
        <Img
          loading="eager"
          fluid={{ ...data.portraitBathroom6.childImageSharp.fluid }}
        />
      ) : src === "last-slider-6" ? (
        <Img
          loading="eager"
          fluid={{ ...data.lastSlider6.childImageSharp.fluid }}
        />
      ) : src === "last-slider-big-6" ? (
        <Img
          loading="eager"
          fluid={{ ...data.lastSliderBig6.childImageSharp.fluid }}
        />
      ) : src === "square-room-6-mirror" ? (
        <Img
          loading="eager"
          fluid={{ ...data.squareRoom6Mirror.childImageSharp.fluid }}
        />
      ) : src === "last-mirror-6-portrait" ? (
        <Img
          loading="eager"
          fluid={{ ...data.lastMirror6Portrait.childImageSharp.fluid }}
        />
      ) : src === "portrait-prestige-mauve" ? (
        <Img
          loading="eager"
          fluid={{ ...data.portraitPrestigeMauve.childImageSharp.fluid }}
        />
      ) : src === "slider-room-mauve" ? (
        <Img
          loading="eager"
          fluid={{ ...data.sliderRoomMauve.childImageSharp.fluid }}
        />
      ) : src === "square-room-mauve" ? (
        <Img
          loading="eager"
          fluid={{ ...data.squareRoomMauve.childImageSharp.fluid }}
        />
      ) : src === "mirror-big-image-room-mauve" ? (
        <Img
          loading="eager"
          fluid={{ ...data.mirrorBigImageRoomMauve.childImageSharp.fluid }}
        />
      ) : src === "mirror-portrait-image-room-mauve" ? (
        <Img
          loading="eager"
          fluid={{ ...data.mirrorPortraitImageRoomMauve.childImageSharp.fluid }}
        />
      ) : src === "main-image-room-mauve" ? (
        <Img
          loading="eager"
          fluid={{ ...data.mainImageRoomMauve.childImageSharp.fluid }}
        />
      ) : src === "main-image-room-mauve-thumb" ? (
        <Img
          fluid={{ ...data.mainImageRoomMauveThumb.childImageSharp.fluid }}
        />
      ) : src === "portrait-room-prestige-mauve" ? (
        <Img
          loading="eager"
          fluid={{ ...data.portraitRoomPrestigeMauve.childImageSharp.fluid }}
        />
      ) : src === "small-image-prestige-mauve" ? (
        <Img
          loading="eager"
          fluid={{ ...data.smallImagePrestigeMauve.childImageSharp.fluid }}
        />
      ) : src === "main-bathroom-room-mauve" ? (
        <Img
          loading="eager"
          fluid={{ ...data.mainBathroomRoomMauve.childImageSharp.fluid }}
        />
      ) : src === "product-room-mauve" ? (
        <Img
          loading="eager"
          fluid={{ ...data.productRoomMauve.childImageSharp.fluid }}
        />
      ) : src === "portrait-bathroom-room-mauve" ? (
        <Img
          loading="eager"
          fluid={{ ...data.portraitBathroomRoomMauve.childImageSharp.fluid }}
        />
      ) : src === "last-slider-room-mauve" ? (
        <Img
          loading="eager"
          fluid={{ ...data.lastSliderRoomMauve.childImageSharp.fluid }}
        />
      ) : src === "square-room-mauve-mirror" ? (
        <Img
          loading="eager"
          fluid={{ ...data.squareRoomMauveMirror.childImageSharp.fluid }}
        />
      ) : src === "mirror-mauve-portrait" ? (
        <Img
          loading="eager"
          fluid={{ ...data.mirrorMauvePortrait.childImageSharp.fluid }}
        />
      ) : src === "mirror-mauve-portrait" ? (
        <Img
          loading="eager"
          fluid={{ ...data.mirrorMauvePortrait.childImageSharp.fluid }}
        />
      ) : src === "portrait-prestige-anis" ? (
        <Img
          loading="eager"
          fluid={{ ...data.portraitPrestigeAnis.childImageSharp.fluid }}
        />
      ) : src === "slider-room-anis" ? (
        <Img
          loading="eager"
          fluid={{ ...data.sliderRoomAnis.childImageSharp.fluid }}
        />
      ) : src === "square-room-anis" ? (
        <Img
          loading="eager"
          fluid={{ ...data.squareRoomAnis.childImageSharp.fluid }}
        />
      ) : src === "mirror-big-image-room-anis" ? (
        <Img
          loading="eager"
          fluid={{ ...data.mirrorBigRoomAnis.childImageSharp.fluid }}
        />
      ) : src === "mirror-portrait-image-room-anis" ? (
        <Img
          loading="eager"
          fluid={{ ...data.mirrorPortraitRoomAnis.childImageSharp.fluid }}
        />
      ) : src === "main-image-room-anis" ? (
        <Img
          loading="eager"
          fluid={{ ...data.mainImageRoomAnis.childImageSharp.fluid }}
        />
      ) : src === "main-image-room-anis-thumb" ? (
        //
        <Img
          loading="eager"
          fluid={{ ...data.mainImageRoomAnisThumb.childImageSharp.fluid }}
        />
      ) : src === "small-image-anis" ? (
        <Img
          loading="eager"
          fluid={{ ...data.smallImageAnis.childImageSharp.fluid }}
        />
      ) : src === "portrait-room-prestige-anis" ? (
        <Img
          loading="eager"
          fluid={{ ...data.portraitRoomPrestigeAnis.childImageSharp.fluid }}
        />
      ) : src === "small-image-prestige-anis" ? (
        <Img
          loading="eager"
          fluid={{ ...data.smallImagePrestigeAnis.childImageSharp.fluid }}
        />
      ) : src === "main-bathroom-room-anis" ? (
        <Img
          loading="eager"
          fluid={{ ...data.mainBathroomRoomAnis.childImageSharp.fluid }}
        />
      ) : src === "portrait-bathroom-room-anis" ? (
        <Img
          loading="eager"
          fluid={{ ...data.portraitBathroomRoomAnis.childImageSharp.fluid }}
        />
      ) : src === "last-slider-room-anis" ? (
        <Img
          loading="eager"
          fluid={{ ...data.lastSliderRoomAnis.childImageSharp.fluid }}
        />
      ) : src === "square-room-anis-mirror" ? (
        <Img
          loading="eager"
          fluid={{ ...data.squareRoomAnisMirror.childImageSharp.fluid }}
        />
      ) : src === "mirror-anis-portrait" ? (
        <Img
          loading="eager"
          fluid={{ ...data.mirrorAnisPortrait.childImageSharp.fluid }}
        />
      ) : src === "portrait-room-executive-bed" ? (
        <Img
          loading="eager"
          fluid={{ ...data.portraitRoomExecutiveBed.childImageSharp.fluid }}
        />
      ) : src === "slider-room-8" ? (
        <Img
          loading="eager"
          fluid={{ ...data.sliderRoom8.childImageSharp.fluid }}
        />
      ) : src === "square-room-8" ? (
        <Img
          loading="eager"
          fluid={{ ...data.squareRoom8.childImageSharp.fluid }}
        />
      ) : src === "mirror-big-image-room-8" ? (
        <Img
          loading="eager"
          fluid={{ ...data.mirrorBigImageRoom8.childImageSharp.fluid }}
        />
      ) : src === "mirror-big-image-room-8" ? (
        <Img
          loading="eager"
          fluid={{ ...data.mirrorBigImageRoom8.childImageSharp.fluid }}
        />
      ) : src === "mirror-portrait-image-room-8" ? (
        <Img
          loading="eager"
          fluid={{ ...data.mirrorBigImageRoom8.childImageSharp.fluid }}
        />
      ) : src === "main-image-room-photos-8" ? (
        <Img
          loading="eager"
          fluid={{ ...data.mainImageRoomPhotos8.childImageSharp.fluid }}
        />
      ) : src === "main-image-room-photos-8-thumb" ? (
        //
        <Img
          loading="eager"
          fluid={{ ...data.mainImageRoomPhotos8Thumb.childImageSharp.fluid }}
        />
      ) : src === "portrait-room-photos-8" ? (
        <Img
          loading="eager"
          fluid={{ ...data.portraitRoomPhotos8.childImageSharp.fluid }}
        />
      ) : src === "small-image-room-photos-8" ? (
        <Img
          loading="eager"
          fluid={{ ...data.smallImageRoomPhotos8.childImageSharp.fluid }}
        />
      ) : src === "main-bathroom-room-8" ? (
        <Img
          loading="eager"
          fluid={{ ...data.mainBathroomRoom8.childImageSharp.fluid }}
        />
      ) : src === "portrait-bathroom-room-8" ? (
        <Img
          loading="eager"
          fluid={{ ...data.portraitBathroomRoom8.childImageSharp.fluid }}
        />
      ) : src === "square-room-8-mirror" ? (
        <Img
          loading="eager"
          fluid={{ ...data.squareRoom8Mirror.childImageSharp.fluid }}
        />
      ) : src === "mirror-8-portrait" ? (
        <Img
          loading="eager"
          fluid={{ ...data.mirror8Portrait.childImageSharp.fluid }}
        />
      ) : src === "last-slider-8" ? (
        <Img
          loading="eager"
          fluid={{ ...data.lastSlider8.childImageSharp.fluid }}
        />
      ) : src === "bicycle-forest" ? (
        <Img
          loading="eager"
          fluid={{ ...data.bicycleForest.childImageSharp.fluid }}
        />
      ) : src === "balloon-sky" ? (
        <Img
          loading="eager"
          fluid={{ ...data.balloonSky.childImageSharp.fluid }}
        />
      ) : src === "chambord-sunrise" ? (
        <Img
          loading="eager"
          fluid={{ ...data.chambordSunrise.childImageSharp.fluid }}
        />
      ) : src === "piscine-landscape" ? (
        <Img
          loading="eager"
          fluid={{ ...data.piscineLandscape.childImageSharp.fluid }}
        />
      ) : src === "cocktail-acr" ? (
        <Img
          loading="eager"
          fluid={{ ...data.cocktailAcr.childImageSharp.fluid }}
        />
      ) : src === "golf-acr" ? (
        <Img
          loading="eager"
          fluid={{ ...data.golfAcr.childImageSharp.fluid }}
        />
      ) : src === "inside-spa-activities" ? (
        <Img
          loading="eager"
          fluid={{ ...data.insideSpaActivities.childImageSharp.fluid }}
        />
      ) : src === "massage-activity" ? (
        <Img
          loading="eager"
          fluid={{ ...data.massageActivity.childImageSharp.fluid }}
        />
      ) : src === "bicycle-forest-landscape" ? (
        <Img
          loading="eager"
          fluid={{ ...data.bicycleForestLandscape.childImageSharp.fluid }}
        />
      ) : src === "hot-air-balloon-landscape" ? (
        <Img
          loading="eager"
          fluid={{ ...data.hotAirBalloonLandscape.childImageSharp.fluid }}
        />
      ) : src === "banner-room" ? (
        <Img
          loading="eager"
          fluid={{ ...data.bannerRoom.childImageSharp.fluid }}
        />
      ) : src === "rose-garden-facade" ? (
        <Img
          loading="eager"
          fluid={{ ...data.roseGardenFacade.childImageSharp.fluid }}
        />
      ) : src === "curtains-lobby" ? (
        <Img
          loading="eager"
          fluid={{ ...data.curtainsLobby.childImageSharp.fluid }}
        />
      ) : src === "mirror-lobby" ? (
        <Img
          loading="eager"
          fluid={{ ...data.mirrorLobby.childImageSharp.fluid }}
        />
      ) : src === "beauval" ? (
        <Img
          loading="eager"
          fluid={{ ...data.beauval.childImageSharp.fluid }}
        />
      ) : src === "concierge" ? (
        <Img
          loading="eager"
          fluid={{ ...data.concierge.childImageSharp.fluid }}
        />
      ) : src === "garden-prestige-bed-portrait" ? (
        <Img
          loading="eager"
          fluid={{ ...data.gardenPrestigeBedPortrait.childImageSharp.fluid }}
        />
      ) : src === "main-salon-prestige-jardin" ? (
        <Img
          loading="eager"
          fluid={{ ...data.mainSalonPrestigeJardin.childImageSharp.fluid }}
        />
      ) : src === "portrait-salon-garden-prestige" ? (
        <Img
          loading="eager"
          fluid={{ ...data.portraitSalonGardenPrestige.childImageSharp.fluid }}
        />
      ) : src === "salon-prestige-jardin" ? (
        <Img
          loading="eager"
          fluid={{ ...data.salonPrestigeJardin.childImageSharp.fluid }}
        />
      ) : src === "main-bathroom-prestige-garden" ? (
        <Img
          loading="eager"
          fluid={{ ...data.mainBathroomPrestigeGarden.childImageSharp.fluid }}
        />
      ) : src === "mirror-portrait-prestige-garden" ? (
        <Img
          loading="eager"
          fluid={{ ...data.mirrorPortraitPrestigeGarden.childImageSharp.fluid }}
        />
      ) : src === "mirror-main-prestige-garden" ? (
        <Img
          loading="eager"
          fluid={{ ...data.mirrorMainPrestigeGarden.childImageSharp.fluid }}
        />
      ) : src === "mirror-main-prestige-garden-thumb" ? (
        //
        <Img
          loading="eager"
          fluid={{
            ...data.mirrorMainPrestigeGardenThumb.childImageSharp.fluid,
          }}
        />
      ) : src === "zoom-roses" ? (
        <Img
          loading="eager"
          fluid={{ ...data.zoomRoses.childImageSharp.fluid }}
        />
      ) : src === "zoom-roses-facade" ? (
        <Img
          loading="eager"
          fluid={{ ...data.zoomRosesFacade.childImageSharp.fluid }}
        />
      ) : src === "annex-tree-color" ? (
        <Img
          loading="eager"
          fluid={{ ...data.annexTreeColor.childImageSharp.fluid }}
        />
      ) : src === "pool-face-facade" ? (
        <Img
          loading="eager"
          fluid={{ ...data.poolFaceFacade.childImageSharp.fluid }}
        />
      ) : src === "portrait-room-garden" ? (
        <Img
          loading="eager"
          fluid={{ ...data.portraitRoomGarden.childImageSharp.fluid }}
        />
      ) : src === "portrait-bathroom-garden" ? (
        <Img
          loading="eager"
          fluid={{ ...data.portraitBathroomGarden.childImageSharp.fluid }}
        />
      ) : src === "first-mirror-main-garden-comfort" ? (
        <Img
          loading="eager"
          fluid={{ ...data.firstMirrorMainGardenComfort.childImageSharp.fluid }}
        />
      ) : src === "first-mirror-portrait-garden-comfort" ? (
        <Img
          loading="eager"
          fluid={{
            ...data.firstMirrorPortraitGardenComfort.childImageSharp.fluid,
          }}
        />
      ) : src === "main-room-garden-comfort" ? (
        <Img
          loading="eager"
          fluid={{ ...data.mainRoomGardenComfort.childImageSharp.fluid }}
        />
      ) : src === "main-room-garden-comfort-thumb" ? (
        //
        <Img
          loading="eager"
          fluid={{ ...data.mainRoomGardenComfortThumb.childImageSharp.fluid }}
        />
      ) : src === "portrait-chambre-jardin" ? (
        <Img
          loading="eager"
          fluid={{ ...data.portraitChambreJardin.childImageSharp.fluid }}
        />
      ) : src === "small-image-garden-comfort" ? (
        <Img
          loading="eager"
          fluid={{ ...data.smallImageGardenComfort.childImageSharp.fluid }}
        />
      ) : src === "main-bathroom-garden-comfort" ? (
        <Img
          loading="eager"
          fluid={{ ...data.mainBathroomGardenComfort.childImageSharp.fluid }}
        />
      ) : src === "small-bathroom-garden-comfort" ? (
        <Img
          loading="eager"
          fluid={{ ...data.smallBathroomGardenComfort.childImageSharp.fluid }}
        />
      ) : src === "small-shower-garden-comfort" ? (
        <Img
          loading="eager"
          fluid={{ ...data.smallShowerGardenComfort.childImageSharp.fluid }}
        />
      ) : src === "portrait-chambre-prestige-terrasse" ? (
        <Img
          loading="eager"
          fluid={{
            ...data.portraitChambrePrestigeTerrasse.childImageSharp.fluid,
          }}
        />
      ) : src === "first-mirror-main-prestige-terrasse" ? (
        <Img
          loading="eager"
          fluid={{
            ...data.firstMirrorMainPrestigeTerrasse.childImageSharp.fluid,
          }}
        />
      ) : src === "first-mirror-portrait-prestige-terrasse" ? (
        <Img
          loading="eager"
          fluid={{
            ...data.firstMirrorPortraitPrestigeTerrasse.childImageSharp.fluid,
          }}
        />
      ) : src === "first-square-prestige-terrasse" ? (
        <Img
          loading="eager"
          fluid={{ ...data.firstSquarePrestigeTerrasse.childImageSharp.fluid }}
        />
      ) : src === "main-bathroom-prestige-terrasse" ? (
        <Img
          loading="eager"
          fluid={{ ...data.mainBathroomPrestigeTerrasse.childImageSharp.fluid }}
        />
      ) : src === "main-image-prestige-terrasse" ? (
        <Img
          loading="eager"
          fluid={{ ...data.mainImagePrestigeTerrasse.childImageSharp.fluid }}
        />
      ) : src === "main-image-prestige-terrasse-thumb" ? (
        //
        <Img
          loading="eager"
          fluid={{
            ...data.mainImagePrestigeTerrasseThumb.childImageSharp.fluid,
          }}
        />
      ) : src === "last-mirror-main-prestige-terrasse" ? (
        <Img
          loading="eager"
          fluid={{
            ...data.lastMirrorMainPrestigeTerrasse.childImageSharp.fluid,
          }}
        />
      ) : src === "last-mirror-portrait-prestige-terrasse" ? (
        <Img
          loading="eager"
          fluid={{
            ...data.lastMirrorPortraitPrestigeTerrasse.childImageSharp.fluid,
          }}
        />
      ) : src === "first-slider-prestige-terrasse" ? (
        <Img
          loading="eager"
          fluid={{ ...data.firstSliderPrestigeTerrasse.childImageSharp.fluid }}
        />
      ) : src === "first-slider-square-privilege" ? (
        <Img
          loading="eager"
          fluid={{ ...data.firstSliderSquarePrivilege.childImageSharp.fluid }}
        />
      ) : src === "main-chambre-privilege" ? (
        <Img
          loading="eager"
          fluid={{ ...data.mainChambrePrivilege.childImageSharp.fluid }}
        />
      ) : src === "first-slider-privilege" ? (
        <Img
          loading="eager"
          fluid={{ ...data.firstSliderPrivilege.childImageSharp.fluid }}
        />
      ) : src === "main-image-privilege" ? (
        <Img
          loading="eager"
          fluid={{ ...data.mainImagePrivilege.childImageSharp.fluid }}
        />
      ) : src === "main-image-privilege-thumb" ? (
        //
        <Img
          loading="eager"
          fluid={{ ...data.mainImagePrivilegeThumb.childImageSharp.fluid }}
        />
      ) : src === "small-image-privilege" ? (
        <Img
          loading="eager"
          fluid={{ ...data.smallImagePrivilege.childImageSharp.fluid }}
        />
      ) : src === "small-bathroom-privilege" ? (
        <Img
          loading="eager"
          fluid={{ ...data.smallBathroomPrivilege.childImageSharp.fluid }}
        />
      ) : src === "portrait-room-privilege" ? (
        <Img
          loading="eager"
          fluid={{ ...data.portraitRoomPrivilege.childImageSharp.fluid }}
        />
      ) : src === "portrait-room-privilege" ? (
        <Img
          loading="eager"
          fluid={{ ...data.portraitRoomPrivilege.childImageSharp.fluid }}
        />
      ) : src === "main-bathroom-privilege" ? (
        <Img
          loading="eager"
          fluid={{ ...data.mainBathroomPrivilege.childImageSharp.fluid }}
        />
      ) : src === "portrait-shower-privilege" ? (
        <Img
          loading="eager"
          fluid={{ ...data.portraitShowerPrivilege.childImageSharp.fluid }}
        />
      ) : src === "last-mirror-main-privilege" ? (
        <Img
          loading="eager"
          fluid={{ ...data.lastMirrorMainPrivilege.childImageSharp.fluid }}
        />
      ) : src === "last-mirror-portrait-privilege" ? (
        <Img
          loading="eager"
          fluid={{ ...data.lastMirrorPortraitPrivilege.childImageSharp.fluid }}
        />
      ) : src === "first-mirror-main-privilege" ? (
        <Img
          loading="eager"
          fluid={{ ...data.firstMirrortMainPrivilege.childImageSharp.fluid }}
        />
      ) : src === "first-mirror-portrait-privilege" ? (
        <Img
          loading="eager"
          fluid={{ ...data.firstMirrorPortraitPrivilege.childImageSharp.fluid }}
        />
      ) : src === "last-slider-square-privilege" ? (
        <Img
          loading="eager"
          fluid={{ ...data.lastSliderSquarePrivilege.childImageSharp.fluid }}
        />
      ) : src === "last-slider-privilege" ? (
        <Img
          loading="eager"
          fluid={{ ...data.lastSliderPrivilege.childImageSharp.fluid }}
        />
      ) : src === "breakfast-continental" ? (
        <Img
          loading="eager"
          fluid={{ ...data.breakfastContinental.childImageSharp.fluid }}
        />
      ) : src === "conciergerie" ? (
        <Img
          loading="eager"
          fluid={{ ...data.conciergerie.childImageSharp.fluid }}
        />
      ) : src === "facade-montgolfiere" ? (
        <Img
          loading="eager"
          fluid={{ ...data.facadeMontgolfiere.childImageSharp.fluid }}
        />
      ) : src === "melba" ? (
        <Img loading="eager" fluid={{ ...data.melba.childImageSharp.fluid }} />
      ) : src === "ascenceur" ? (
        <Img
          loading="eager"
          fluid={{ ...data.ascenceur.childImageSharp.fluid }}
        />
      ) : src === "parasol-facade" ? (
        <Img
          loading="eager"
          fluid={{ ...data.parasolFacade.childImageSharp.fluid }}
        />
      ) : src === "insta-1" ? (
        <Img loading="eager" fluid={{ ...data.insta1.childImageSharp.fluid }} />
      ) : src === "insta-2" ? (
        <Img loading="eager" fluid={{ ...data.insta2.childImageSharp.fluid }} />
      ) : src === "insta-3" ? (
        <Img fluid={{ ...data.insta3.childImageSharp.fluid }} />
      ) : src === "insta-4" ? (
        <Img loading="eager" fluid={{ ...data.insta4.childImageSharp.fluid }} />
      ) : src === "insta-5" ? (
        //
        <Img loading="eager" fluid={{ ...data.insta5.childImageSharp.fluid }} />
      ) : src === "insta-6" ? (
        //
        <Img loading="eager" fluid={{ ...data.insta6.childImageSharp.fluid }} />
      ) : src === "insta-7" ? (
        //
        <Img loading="eager" fluid={{ ...data.insta7.childImageSharp.fluid }} />
      ) : src === "insta-8" ? (
        //
        <Img loading="eager" fluid={{ ...data.insta8.childImageSharp.fluid }} />
      ) : src === "insta-9" ? (
        //
        <Img loading="eager" fluid={{ ...data.insta9.childImageSharp.fluid }} />
      ) : (
        //
        <Img loading="eager" fluid={data.contact.childImageSharp.fluid} />
      )}
    </>
  )
}
