import React, { useState, useRef, useEffect } from "react"
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import fr from "date-fns/locale/fr"
import en from "date-fns/locale/fr"
import ru from "date-fns/locale/ru"
import { connect } from "react-redux"
import { console } from "globalthis/implementation"

const langObj = { en: en, fr: fr, ru: ru }

let calendarLangVoc = {
  fr: "ARRIVÉE",
  en: "CHECK-IN",
  ru: "приезд",
}

const StartCalendar = ({
  language,
  startingDate,
  updateStartingDate,
  endingDate,
  showCalendar,
}) => {
  const [startDate, setStartDate] = useState(null)
  let container = useRef(null)
  let calendar = useRef(null)
  registerLocale(language, langObj[language])

  const updateStartDate = date => {
    updateStartingDate(date)
  }

  useEffect(() => {
    let input = container.children[0].children[0].children[0]
    input.readOnly = true
  }, [])

  useEffect(() => {
    if (showCalendar) {
      console.log("click on elenent")
      calendar.setOpen(true)
    }
  }, [showCalendar])

  return (
    <div className="calendar first" ref={el => (container = el)}>
      <DatePicker
        // dateFormat="yyyy/MM/dd"
        selectsRange
        endDate={endingDate}
        locale={language != "ru" ? language : ru}
        placeholderText={calendarLangVoc[language]}
        dateFormat={language === "en" ? "MM/dd/yyyy" : "dd/MM/yyyy"}
        selected={startingDate}
        onSelect={date => updateStartDate(date)}
        minDate={new Date()}
        ref={el => (calendar = el)}
      />
    </div>
  )
}

const mapStateToProps = ({ language, startingDate, endingDate }) => {
  return { language, startingDate, endingDate }
}

const mapDispatchToProps = dispatch => {
  return {
    updateStartingDate: date => {
      dispatch({
        type: `UPDATE_STARTING_DATE`,
        payload: date,
      })
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StartCalendar)
