import React, { useState, useEffect, useRef } from "react"
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import fr from "date-fns/locale/fr"
import en from "date-fns/locale/fr"
import ru from "date-fns/locale/ru"
import { connect } from "react-redux"

const langObj = { en: en, fr: fr, ru: ru }

let calendarLangVoc = {
  fr: "DÉPART",
  en: "CHECK-OUT",
  ru: "отъезд",
}

const EndCalendar = ({
  language,
  endingDate,
  updateEndingDate,
  startingDate,
}) => {
  const [startDate, setStartDate] = useState(null)
  let container = useRef(null)
  registerLocale(language, langObj[language])
  const handleUpdateDate = date => {
    updateEndingDate(date)
    setStartDate(date)
  }
  useEffect(() => {
    setStartDate(endingDate)
  }, [endingDate])

  useEffect(() => {
    let input = container.children[0].children[0].children[0]
    input.readOnly = true
  }, [])
  const startingDatePlusOne = date => {
    var day = 60 * 60 * 24 * 1000
    var endDate = new Date(date.getTime() + day)
    return endDate
  }
  const tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
  return (
    <div className="calendar" ref={el => (container = el)}>
      <DatePicker
        // dateFormat="yyyy/MM/dd"
        dateFormat={language === "en" ? "MM/dd/yyyy" : "dd/MM/yyyy"}
        selectsRange
        startDate={startingDate}
        locale={language}
        locale={language != "ru" ? language : ru}
        selected={endingDate}
        placeholderText={calendarLangVoc[language]}
        onSelect={date => handleUpdateDate(date)}
        minDate={startingDate ? startingDatePlusOne(startingDate) : tomorrow}
      />
    </div>
  )
}

const mapStateToProps = ({ language, endingDate, startingDate }) => {
  return { language, endingDate, startingDate }
}

const mapDispatchToProps = dispatch => {
  return {
    updateEndingDate: date =>
      dispatch({
        type: `UPDATE_ENDING_DATE`,
        payload: date,
      }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EndCalendar)
