import { Trans } from "@lingui/macro"
import TransitionLink from "gatsby-plugin-transition-link"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Image } from "../gatsby-images/image"
import RoomsListData from "./roomslist.json"
import axios from "axios"

const RoomsList = ({
  hideShowReservationCenter,
  language,
  startingDate,
  endingDate,
  showTransitionBanner,
  hideTransitionBanner,
  resetOffset,
  fetchingAvailabilities,
  roomsAvailabilities,
  showReservationCenter,
  isFromRoom,
  sessionId,
}) => {
  const langObj = RoomsListData.filter(obj => obj[language])[0][language]
  const [roomsListLanguage, setRoomsListLanguage] = useState(langObj)
  const [filteredList, setFilteredList] = useState([])

  useEffect(() => {
    setFilteredList(roomsListLanguage)
  }, [])

  useEffect(() => {
    const roomsList = RoomsListData.filter(obj => obj[language])[0][language]
    let arr = []
    if (roomsAvailabilities) {
      roomsAvailabilities.map(roomAvailObj => {
        const key = Object.keys(roomAvailObj)[0]
        const value = Object.values(roomAvailObj)[0]
        roomsList.map(roomObj => {
          if (roomObj.url == key) {
            roomObj["price"] = value
            arr.push(roomObj)
          }
        })
      })
      let availableRooms = []
      arr.map(el => el.price && availableRooms.push(el))
      setFilteredList(availableRooms)
      // console.log(availableRooms, 'availableRooms')
    }
  }, [roomsAvailabilities])

  useEffect(() => {
    const newLanguage = RoomsListData.filter(obj => obj[language])[0][language]
    setFilteredList(newLanguage)
  }, [language])

  const checkIfValidClick = () => {
    if (typeof window !== `undefined`) {
      if (!window.location.pathname.includes("contact")) {
        return true
      } else {
        return false
      }
    }
  }
  return (
    <>
      {fetchingAvailabilities ? (
        <p style={{ textAlign: "center", color: "#f2f2f2", marginTop: 50 }}>
          <Trans>Nous recherchons les meilleurs tarifs guarantis</Trans>
        </p>
      ) : filteredList.length ? (
        filteredList
          // filter rooms for which the price is -1
          .filter(room => room["price"] !== -1)
          .sort((a, b) => (a["price"] > b["price"] ? 1 : -1))
          .map(room => {
            return (
              <RoomComponent
                hideShowReservationCenter={hideShowReservationCenter}
                title={room["title"]}
                subtitle={room["subtitle"]}
                description={room["description"]}
                btn={room["discover-btn"]}
                src={room["src"]}
                url={room["url"]}
                startingDate={startingDate}
                endingDate={endingDate}
                key={room["url"]}
                hideTransitionBanner={hideTransitionBanner}
                showTransitionBanner={showTransitionBanner}
                resetOffset={resetOffset}
                price={room["price"]}
                language={language}
                view={room["view"]}
                sdb={room["sdb"]}
                terrasse={room["terrasse"] === "yes" ? true : false}
                lit={room["lit"]}
                occupation={room["occupation"]}
                superficie={room["superficie"]}
                showReservationCenter={showReservationCenter}
                isFromRoom={isFromRoom}
                sessionId={sessionId}
              />
            )
          })
      ) : (
        <div
          style={{
            color: "#f2f2f2",
            margin: "auto",
            width: "80%",
            marginTop: 50,
          }}
        >
          <p>
            <Trans>
              Il semblerait que nous soyons complet pour vos dates. Nous vous
              invitons à vérifier si tel est le cas en nous contactant.
            </Trans>
          </p>
          <div
            className="contact-us"
            onClick={() => checkIfValidClick() && showTransitionBanner()}
          >
            <TransitionLink
              to={`/${language}/contact`}
              exit={{
                delay: 2,
              }}
              entry={{
                trigger: ({ node, e, exit, entry }) => {
                  hideTransitionBanner()
                  resetOffset(true)
                },
              }}
            >
              <div
                className="button"
                onClick={() => hideShowReservationCenter()}
              >
                <Trans>Contactez-nous</Trans>
              </div>
            </TransitionLink>
          </div>
        </div>
      )}
    </>
  )
}

const checkIfValidClick = name => {
  if (typeof window !== `undefined`) {
    if (!window.location.pathname.includes(name)) {
      return true
    } else {
      return false
    }
  }
}

const RoomComponent = ({
  hideShowReservationCenter,
  title,
  subtitle,
  url,
  description,
  btn,
  src,
  endingDate,
  startingDate,
  hideTransitionBanner,
  showTransitionBanner,
  resetOffset,
  price,
  language,
  view,
  sdb,
  terrasse,
  lit,
  occupation,
  superficie,
  showReservationCenter,
  isFromRoom,
  sessionId,
}) => {
  const [nDays, setNDays] = useState("")
  const [roomCodes, setRoomCodes] = useState()
  const [dateObj, setDateObj] = useState({})

  useEffect(() => {
    if (startingDate && endingDate) {
      const newDate = {}
      newDate["day"] = startingDate.getDate()
      newDate["month"] = startingDate.getMonth() + 1
      newDate["year"] = startingDate.getFullYear()
      const diff = (e, t) => Math.floor((e.getTime() - t.getTime()) / 864e5)
      newDate["nDays"] = diff(endingDate, startingDate)

      setDateObj(newDate)
    }
  }, [startingDate, endingDate])

  useEffect(() => {
    const roomsCodes = {
      "executive-room": {
        roomCat: "438969",
        roomType: "1023434",
        roomPrice: "220",
      },
      "privilege-room": {
        roomCat: "438970",
        roomType: "1023436",
        roomPrice: "185",
      },
      "garden-prestige-room": {
        roomCat: "438971",
        roomType: "1023437",
        roomPrice: "185",
      },
      "confort-superior-room": {
        roomCat: "438972",
        roomType: "1023438",
        roomPrice: "160",
      },
      "garden-comfort-room": {
        roomCat: "438973",
        roomType: "1023439",
        roomPrice: "150",
      },
      "confort-room": {
        roomCat: "438974",
        roomType: "1023440",
        roomPrice: "130",
      },
      "prestige-terrasse-room": {
        roomCat: "438975",
        roomType: "1023441",
        roomPrice: "185",
      },
      "prestige-anis-room": {
        roomCat: "438976",
        roomType: "1023442",
        roomPrice: "185",
      },
      "prestige-canard-room": {
        roomCat: "438977",
        roomType: "1023443",
        roomPrice: "185",
      },
      "prestige-mauve-room": {
        roomCat: "438978",
        roomType: "1023435",
        roomPrice: "185",
      },
    }

    setRoomCodes(roomsCodes[url])
  }, [url])

  useEffect(() => {
    if (startingDate && endingDate) {
      const diff = (e, t) => Math.floor((e.getTime() - t.getTime()) / 864e5)
      const days = diff(endingDate, startingDate)
      setNDays(days)
    }
  }, [startingDate, endingDate])

  const pluralNight = () => {
    if (language == "fr" && nDays > 1) {
      return "nuits"
    } else if (language == "fr") {
      return "nuit"
    } else if (language == "en" && nDays > 1) {
      return "nights"
    } else if (language == "en") {
      return "night"
    } else if (language == "ru") {
      return "ночи"
    }
  }

  const checkOutRoomPage = () => {
    // isFromRoom(true);
    hideShowReservationCenter()
    checkIfValidClick(url) && showTransitionBanner()
  }

  return (
    <div
    // style={{direction:'ltr'}}
    // onClick={() => { isFromRoom(true);checkIfValidClick(url) && checkOutRoomPage()}}
    >
      <div className="row">
        <div className="col-10 offset-1 room-container">
          <div className="texts-container">
            <div className="title">{title}</div>
            <div className="subtitle">{subtitle}</div>
            <div className="description">{description}</div>
            <div className="">
              <div
                className="room-infos-separation"
                style={{ marginTop: 30 }}
              ></div>
              <div style={{ display: "flex" }}>
                <ul
                  className="rooms-infos-reservation"
                  style={{ width: "100%" }}
                >
                  <li className="li">
                    <span>
                      <Trans>Vue</Trans>
                    </span>{" "}
                    : {view}
                  </li>
                  <li className="div">
                    <span>
                      <Trans>Superficie</Trans>
                    </span>{" "}
                    : {superficie}
                  </li>
                  <li className="div">
                    <span>
                      <Trans>Occupation</Trans>
                    </span>{" "}
                    : {occupation}
                  </li>
                  <li className="div">
                    <span>
                      <Trans>Climatisation rėversible</Trans>
                    </span>
                  </li>
                  {terrasse && (
                    <li className="div" style={{ textDecoration: "underline" }}>
                      <Trans>Terrasse privée</Trans>
                    </li>
                  )}
                  <li className="li">
                    <span>
                      <Trans>Sdb</Trans>
                    </span>{" "}
                    : {sdb}
                  </li>
                  <li className="div">
                    <span>
                      <Trans>Lit </Trans>
                    </span>{" "}
                    : {lit}
                  </li>
                </ul>
                {/* <ul className="rooms-infos-reservation" style={{width: '50%'}}>

                                </ul> */}
              </div>
              <div className="room-infos-separation"></div>
            </div>
          </div>
          <div className="image">
            {showReservationCenter && <Image src={`${src}-thumb`} />}
          </div>
        </div>
      </div>
      {/* <div className="row" style={{marginTop: 30}}>
                <div className="col-10 offset-1">
                    <div className="room-infos-separation"></div>
                    <div style={{display: 'flex', marginLeft: '5%'}}>
                        <ul className="rooms-infos-reservation" style={{width: '50%'}}>
                            <li className="li"><span><Trans>Vue</Trans></span> : {view}</li>
                            <li className="div"><span><Trans>Superficie</Trans></span> : {superficie}</li>
                            <li className="div"><span><Trans>Lit </Trans></span> : {lit}</li>

                            {terrasse &&<li className="div"><Trans>Terrasse privée</Trans></li>}
                        </ul>
                        <ul className="rooms-infos-reservation" style={{width: '50%'}}>
                            <li className="li"><span><Trans>Sdb</Trans></span> : {sdb}</li>
                            <li className="div"><span><Trans>Occupation</Trans></span> : {occupation}</li>
                            <li className="div"><Trans>Climatisation rėversible</Trans></li>

                        </ul>
                    </div>
                    <div className="room-infos-separation"></div>
                </div>
            </div> */}
      <div className="row" style={{ marginTop: 10 }}>
        <div
          className="col-10 offset-1"
          onClick={() => hideShowReservationCenter()}
        >
          {price && (
            <div className="price">
              {price}€{" "}
              <span>
                <Trans>pour</Trans> {nDays} {pluralNight()}
              </span>
            </div>
          )}
          {price ? (
            <div
              style={{ display: "flex" }}
              onClick={() => {
                axios.post(
                  "https://reservations-requests.herokuapp.com/complete_request",
                  {
                    sessionId: sessionId,
                    roomCat: url,
                  }
                )
              }}
            >
              <a
                style={{ height: "100%" }}
                href={
                  roomCodes &&
                  `https://secure.reservit.com/reservit/reserhotel.php?lang=${language.toUpperCase()}&id=2&hotelid=429967&roomtcode=${
                    roomCodes["roomCat"]
                  }&catcode=${roomCodes["roomType"]}&fday=${
                    dateObj["day"]
                  }&fmonth=${dateObj["month"]}&fyear=${
                    dateObj["year"]
                  }&nbnights=${dateObj["nDays"]}`
                }
              >
                <div className="book-button">
                  {" "}
                  <Trans>RÉSERVER</Trans>
                </div>
              </a>
              <TransitionLink
                to={`/${language}/rooms/${url}`}
                exit={{
                  delay: checkIfValidClick(url) ? 2 : 0,
                }}
                entry={{
                  trigger: ({ node, e, exit, entry }) => {
                    hideTransitionBanner()
                    resetOffset(true)
                  },
                }}
              >
                <div
                  onClick={() => hideShowReservationCenter()}
                  className="discover-btn"
                >
                  | {btn}
                </div>
              </TransitionLink>
            </div>
          ) : (
            <div style={{ display: "flex", alignItems: "baseline" }}>
              <div>
                <TransitionLink
                  to={`/${language}/rooms/${url}`}
                  exit={{
                    delay: checkIfValidClick(url) ? 2 : 0,
                  }}
                  entry={{
                    trigger: ({ node, e, exit, entry }) => {
                      hideTransitionBanner()
                      resetOffset(true)
                    },
                  }}
                >
                  <div
                    onClick={() => checkOutRoomPage()}
                    className="discover-btn with-underline"
                  >
                    {btn}
                  </div>
                </TransitionLink>
              </div>
              {roomCodes && (
                <div style={{ color: "white", marginLeft: 7 }}>
                  | <Trans>À partir de</Trans> {roomCodes["roomPrice"]}Є
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({
  language,
  startingDate,
  endingDate,
  fetchingAvailabilities,
  roomsAvailabilities,
  showReservationCenter,
  sessionId,
}) => {
  return {
    language,
    startingDate,
    endingDate,
    fetchingAvailabilities,
    roomsAvailabilities,
    showReservationCenter,
    sessionId,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    hideShowReservationCenter: () =>
      dispatch({
        type: `HIDE_RESERVATION_CENTER`,
      }),
    showTransitionBanner: () => dispatch({ type: `IMG_NOT_LOADED` }),
    hideTransitionBanner: () => dispatch({ type: `IMG_LOADED` }),
    resetOffset: bool => dispatch({ type: "RESET_OFFSET", payload: bool }),
    isFromRoom: bool => dispatch({ type: "IS_FROM_ROOM", payload: bool }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomsList)
