/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// My fonts
import "typeface-ibm-plex-sans"
import { detect } from "@lingui/detect-locale"

import wrapWithProvider from "./wrap-with-provider"
export const wrapRootElement = wrapWithProvider

// export const onClientEntry = () => {
//     const result = detect(
//         window.navigator.language
//       )

//       if(result.includes('fr')  && window.location.pathname === '/'){
//         window.location.pathname = `/fr`
//       }  else if (result.includes('ru') && window.location === '/' ) {
//         window.location.pathname = `/ru`
//       } else if (window.location.pathname === '/'){
//         window.location.pathname = `/en`
//       }
//   }
