import React from "react"
import CookiesBanner from "./CookiesBanner"

const Cookies = () => {
  return (
    <div>
      <CookiesBanner />
    </div>
  )
}

export default Cookies
