import React, { useEffect, useRef } from "react"
import { connect } from "react-redux"
import gsap from "gsap"
import StartCalendarMobile from "./StartCalendarMobile"
import EndCalendarMobile from "./EndCalendarMobile"
import RoomsListMobile from "./RoomsListMobile"

const ReservationCenterMobile = ({
  showReservationCenter,
  hideShowReservationCenter,
}) => {
  let container = useRef()

  useEffect(() => {
    if (showReservationCenter) {
      gsap.to(container, { yPercent: -100, delay: 0.3 })
      const el = container

      ;["touchmove", "mousewheel", "wheel"].forEach(eventType => {
        el.addEventListener(eventType, e => e.stopPropagation())
      })
    } else {
      gsap.to(container, { yPercent: 0, duration: 1 })
    }
  }, [showReservationCenter])
  return (
    <div ref={el => (container = el)} className="reservation-center-mobile">
      <div className="row">
        <div className="close">
          <i
            className="fas fa-times"
            onClick={() => hideShowReservationCenter()}
          ></i>
        </div>
      </div>
      <div className="row">
        <div className="col-6 offset-1">
          <div className="guarantee">Meilleur prix guaranti </div>
        </div>
      </div>
      <div className="row">
        <div className="col-5 offset-1">
          <StartCalendarMobile />
        </div>
        <div className="col-5">
          <EndCalendarMobile />
        </div>
      </div>
      <RoomsListMobile />
    </div>
  )
}

const mapStateToProps = ({ showReservationBtn, showReservationCenter }) => {
  return { showReservationBtn, showReservationCenter }
}

const mapDispatchToProps = dispatch => {
  return {
    hideShowReservationCenter: () =>
      dispatch({
        type: `HIDE_RESERVATION_CENTER`,
      }),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReservationCenterMobile)
