import React, { useState, useRef, useEffect, useCallback } from "react"
import { Link } from "gatsby"
// Framer motion
import { motion, AnimatePresence } from "framer-motion"
//Gatby Image
import { Image } from "./gatsby-images/image"
//Data
import menuList from "../data/menu-elements.json"

import useMousePosition from "../hooks/useMousePosition"

import { connect } from "react-redux"
import Logo from "./share/Logo"
import { Trans } from "@lingui/macro"

// Transition Animation
const transition = { duration: 0.8, ease: [0.6, -0.05, 0.01, 0.9] }
// Stagger on menu link
const parent = {
  animate: { transition: { staggerChildren: 0.1, delayChildren: 1 } },
}
// Menu Title Slide Up
const titleSlideUp = {
  initial: { y: 200 },
  animate: { y: 0 },
}
// Menu lines grow
const lineGrow = {
  initial: { width: "100%" },
  animate: { width: 0 },
}

const Menu = ({
  setMenuState,
  updateLanguage,
  menuState,
  setCursorHovered,
  x,
  y,
  updateShowNavbar,
  updateShowMenu,
  hideTransitionBanner,
  language,
  resetOffsetFunct,
  roomPageAnimationDelayFromNav,
}) => {
  const closeMenu = () => {
    // menuState && updateShowNavbar({show: true, delay: 2000})
    setMenuState(!menuState)
  }

  const [hidePanels, setHidePanels] = useState(false)

  const [menuLang, setMenuLang] = useState(menuList[1]["fr"])

  let resetOffset = false

  const resetOffsetY = e => {
    resetOffsetFunct(true)
    //had issue while transition from link element moving to top and pannels not being at the right place
    setHidePanels(true)
    setTimeout(() => {
      closeMenu()
    }, 0)
    setTimeout(() => {
      setHidePanels(false)
    }, 2000)
    hideTransitionBanner()
  }

  const languageChange = lang => {
    updateLanguage({
      language: lang,
      langCatalog: {},
    })
  }

  useEffect(() => {
    if (menuState) {
      //forbid scrolling if the menu is showed
      var x = window.scrollX
      var y = window.scrollY
      window.onscroll = function () {
        window.scrollTo(x, y)
      }
      updateShowMenu(true)
    } else {
      //unforbid scrolling when menu is hidden
      window.onscroll = function () {}
      setTimeout(() => updateShowMenu(true), 2000)
    }
  }, [menuState])

  let menuListLang

  useEffect(() => {
    menuListLang = menuList.filter(list => list[language])[0][language]
    setMenuLang(menuListLang)
  }, [language])

  return (
    <>
      <AnimatePresence>
        {menuState && menuLang && (
          <>
            <motion.div
              initial={{ visibility: "hidden" }}
              exit={{
                visibility: "hidden",
                transition: { delay: 1.2 },
              }}
              animate={{
                visibility: "visible",
                transition: { delay: 1 },
              }}
              className="projects"
            >
              {/* <div
              onClick={() => closeMenu()}
              onMouseEnter={() => setCursorHovered(true)}
              onMouseLeave={() => setCursorHovered(false)}
              className="close"
            >
              <Close />
            </div> */}
              <div className="menu d-none d-sm-block">
                <div className="container">
                  <div className="menu-inner">
                    <motion.ul
                      variants={parent}
                      initial="initial"
                      animate="animate"
                      exit="exit"
                    >
                      {menuLang.map(list => (
                        <div
                          onClick={e => {
                            resetOffsetY()
                            // closeMenu()
                          }}
                          key={list.id}
                        >
                          <List
                            language={language}
                            key={list.id}
                            setCursorHovered={setCursorHovered}
                            leftLineFlex={list.leftLineFlex}
                            rightLineFlex={list.rightLineFlex}
                            title={list.title}
                            thumbnailPosition={list.thumbnailPosition}
                            offset={list.offset}
                            src={list.src}
                            id={list.id}
                            x={x}
                            y={y}
                            updateShowNavbar={updateShowNavbar}
                            hideTransitionBanner={hideTransitionBanner}
                            resetOffset={resetOffset}
                            roomPageAnimationDelayFromNav={
                              roomPageAnimationDelayFromNav
                            }
                          />
                        </div>
                      ))}
                    </motion.ul>
                  </div>
                </div>
              </div>
              <div className="small-screen-menu">
                <div className="logo">
                  <div
                    className="d-block d-sm-none"
                    style={{
                      top: "-34vw",
                      position: "absolute",
                      left: "10.5%",
                    }}
                  >
                    <Logo scale="1.5" padTop="10%" color="white" />
                  </div>
                </div>
                <div
                  className="contact offset-1"
                  style={{ position: "relative", zIndex: 1 }}
                >
                  <Link to={`/${language}/contact`}>
                    <div onClick={() => resetOffsetY()}>
                      <Trans>CONTACT</Trans>
                    </div>
                  </Link>
                  <div className="vertical-line"></div>
                  <div className="languages">
                    <div onClick={() => languageChange("en")}>EN</div>
                    <div onClick={() => languageChange("fr")}>FR</div>
                    <div onClick={() => languageChange("ru")}>RU</div>
                  </div>
                </div>
                <div className="links offset-1">
                  <Link to={`/${language}`}>
                    <div onClick={() => resetOffsetY()}>
                      <Trans>Hotel</Trans>
                    </div>
                  </Link>
                  <Link to={`/${language}/rooms`}>
                    <div
                      onClick={() => {
                        roomPageAnimationDelayFromNav(false)
                        resetOffsetY()
                      }}
                    >
                      <Trans>Chambres</Trans>
                    </div>
                  </Link>
                  <Link to={`/${language}/spa`}>
                    <div onClick={() => resetOffsetY()}>
                      <Trans>Spa</Trans>
                    </div>
                  </Link>
                  <Link to={`/${language}/activities`}>
                    <div onClick={() => resetOffsetY()}>
                      <Trans>Activités</Trans>
                    </div>
                  </Link>
                </div>
              </div>
            </motion.div>
            <Panels hidePanels={hidePanels} />
          </>
        )}
      </AnimatePresence>
    </>
  )
}

const List = ({
  leftLineFlex,
  rightLineFlex,
  title,
  thumbnailPosition,
  offset,
  src,
  id,
  setCursorHovered,
  updateShowNavbar,
  hideTransitionBanner,
  roomPageAnimationDelayFromNav,
  language,

  // x,
  y,
}) => {
  const [hoverState, setHoverState] = useState(false)
  const list = useRef()
  const [state, setState] = useState({
    top: 0,
    left: 0,
  })

  let { x } = useMousePosition()
  useEffect(() => {
    setState({
      top: list.current.getBoundingClientRect().top,
      left: list.current.getBoundingClientRect().left,
    })
  }, [hoverState])
  return (
    <motion.li ref={list}>
      <Link to={`/${language}${id}`}>
        <div
          className="wrapper"
          onClick={() => {
            roomPageAnimationDelayFromNav(false)
            setTimeout(() => updateShowNavbar({ show: true, delay: 0 }), 2000)
          }}
          // onClick={() => hideTransitionBanner()}
        >
          <div className={`line left flex-${leftLineFlex}`}>
            <motion.div
              variants={lineGrow}
              className="mask"
              transition={{ ...transition, duration: 1 }}
            ></motion.div>
          </div>
          <motion.div
            onHoverStart={() => setHoverState(true)}
            onHoverEnd={() => setHoverState(false)}
            // onMouseEnter={() => setCursorHovered(true)}
            // onMouseLeave={() => setCursorHovered(false)}
            className="title"
          >
            <h2>
              <motion.div
                variants={titleSlideUp}
                transition={transition}
                className="text"
              >
                {title}
              </motion.div>
            </h2>
          </motion.div>
          <div className="thumbnail" style={{ left: thumbnailPosition }}>
            <Image src={src} />
            <motion.div
              variants={lineGrow}
              transition={{ ...transition, duration: 1 }}
              className="mask"
            ></motion.div>
          </div>
          <motion.div
            initial={{ opacity: 0 }}
            className="floating-image"
            animate={{
              opacity: hoverState ? 1 : 0,
              x: x + offset,
              y: y,
            }}
            transition={{
              ease: "linear",
            }}
          >
            <Image src={src} />
          </motion.div>
          <div className={`line right flex-${rightLineFlex}`}>
            <motion.div
              variants={lineGrow}
              className="mask right"
              transition={{ ...transition, duration: 1 }}
            ></motion.div>
          </div>
        </div>
      </Link>
    </motion.li>
  )
}

const Panels = ({ hidePanels }) => {
  const [panelComplete, setPanelComplete] = useState(false)

  let pageYOffset = hidePanels ? 0 : window.pageYOffset

  return (
    <>
      <motion.div
        initial={{ height: 0 }}
        animate={{
          //to work on iphone we need to make it bigger
          height: [0, window.innerHeight + 150, 0],
          top: [
            pageYOffset,
            pageYOffset,
            pageYOffset + window.innerHeight + 150,
          ],
        }}
        exit={{
          height: [0, window.innerHeight + 150, 0],
          top: [
            pageYOffset + window.innerHeight + 150,
            pageYOffset,
            pageYOffset - 150,
          ],
        }}
        transition={{ ...transition, duration: 1.98, times: [0, 0.5, 1] }}
        style={{ background: panelComplete ? "#b99494" : "#f1c9c8" }}
        className="left-panel-background"
      ></motion.div>
      <motion.div
        style={{
          background: panelComplete ? "#b99494" : "#f1c9c8",
        }}
        initial={{ height: 0 }}
        animate={{
          height: [0, window.innerHeight + 150, 0],
          bottom: [
            -pageYOffset - 150,
            -pageYOffset - 150,
            -pageYOffset + window.innerHeight + 150,
          ],
        }}
        exit={{
          height: [0, window.innerHeight + 150, 0],
          top: [
            pageYOffset,
            pageYOffset,
            pageYOffset + window.innerHeight + 150,
          ],
        }}
        transition={{ ...transition, duration: 1.98, times: [0, 0.5, 1] }}
        className="right-panel-background"
        onAnimationComplete={() => {
          setPanelComplete(!panelComplete)
        }}
      ></motion.div>
    </>
  )
}

const mapStateToProps = ({ language, scrolltriggerReady }) => {
  return { language }
}

const mapDispatchToProps = dispatch => {
  return {
    updateShowNavbar: navbarObj =>
      dispatch({
        type: `UPDATE_SHOW_NABAR`,
        payload: {
          show: navbarObj.show,
          delay: navbarObj.delay,
        },
      }),
    updateShowMenu: menuStatus =>
      dispatch({ type: `UPDATE_SHOW_MENU`, payload: menuStatus }),
    updateLanguage: langObj =>
      dispatch({ type: `UPDATE_LANGUAGE`, payload: langObj }),
    hideTransitionBanner: () => dispatch({ type: `IMG_LOADED` }),
    resetOffsetFunct: bool => dispatch({ type: "RESET_OFFSET", payload: bool }),
    roomPageAnimationDelayFromNav: bool =>
      dispatch({ type: "FIRED_FROM_NAVBAR", payload: bool }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu)
