import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { motion } from "framer-motion"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Navbar from "./share/Navbar"
import { I18nProvider } from "@lingui/react"
import Scrollbar from "smooth-scrollbar"
import axios from "axios"

//Components
import Menu from "./Menu"

//Styles
import "../styles/App.scss"

//redux
import { connect } from "react-redux"
// import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import { detect } from "@lingui/detect-locale"
import ReservationButton from "./reservation/ReservationButton"
import ReservationCenter from "./reservation/ReservationCenter"
import ReservationCenterMobile from "./reservation/ReservationCenterMobile"
import ReservationButtonMobile from "./reservation/ReservationButtonMobile"
import ReservationFinalBar from "./reservation/ReservationFinalBar"
import Logo from "./share/Logo"
import Cookies from "./cookies/Cookies"
import LogoAnimated from "./share/LogoAnimated"
import DownArrow from "./share/DownArrow"
import { console } from "globalthis/implementation"

const catalogs = {
  en: { messages: require("../../i18n/lingui/en/messages.json") },
  ru: { messages: require("../../i18n/lingui/ru/messages.json") },
  fr: { messages: require("../../i18n/lingui/fr/messages.json") },
}

const Layout = ({
  children,
  updateShowNavbar,
  showMenu,
  updateIsTouchScreen,
  language,
  updateInitialLanguage,
  updateWindowWidth,
  mainImageLoaded,
  updateLayoutRendered,
  scrolltriggerReady,
  resetOffset,
  firstTimeLayoutRendered,
  hideTransitionBanner,
  setScrollTriggerReady,
  updateScrollOffset,
  updateResetOffset,
  isTouchScreen,
  scrollOffset,
  roomListColor,
  isFetchingAvailabilities,
  updateRoomsAvailabilities,
  startingDate,
  endingDate,
  showReservationCenter,
  loadingBannerFromRoom,
  showArrowDown,
  isTouchDevice,
  setSessionId,
}) => {
  const appContainer = useRef(null)
  const [showNavbar, setShowNavbar] = useState(false)
  let scroller = useRef()
  const siteData = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [initialLanguageUpdated, setInitialLanguageUpdated] = useState(false)

  let loadingBanner = useRef(null)
  let aside = useRef(null)

  // State of our menu
  const [menuState, setMenuState] = useState(false)
  // State of to display our custom cursor
  const [cursorHovered, setCursorHovered] = useState(false)

  const [firstBannerSeen, setFirstBannerSeen] = useState(false)

  const [windowWidth, setWindowWidth] = useState()

  //generate uuid in order to record the reservation requests
  const [sessionUuid, setSessionUuid] = useState("")

  let allBody = useRef(null)

  let downArrow = useRef(null)

  let mainChildrenDiv = useRef(null)

  let cookies = useRef(null)

  let navbar = useRef(null)
  gsap.registerPlugin(ScrollTrigger)

  const [menuReady, setMenuReady] = useState(false)

  const [alreadyRendered, setAlreadyRendered] = useState(false)

  const uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (
      c
    ) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
  }

  const initScrollBar = yPosition => {
    const scrollerConst = scroller
    const isTouchDevice = "ontouchstart" in window
    if (!isTouchDevice && !Scrollbar.has(scrollerConst)) {
      const bodyScrollBar = Scrollbar.init(scrollerConst, {
        damping: isTouchDevice ? 1 : 0.07,
        delegateTo: document,
        alwaysShowTracks: false,
        continuousScrolling: true,
      })
      yPosition && bodyScrollBar.setPosition(0, 0)
      ScrollTrigger.scrollerProxy(scrollerConst, {
        scrollTop(value) {
          if (arguments.length) {
            bodyScrollBar.scrollTop = value
          }
          // bodyScrollBar.offset.y !== scrollOffset && updateScrollOffset(bodyScrollBar.offset.y)
          updateScrollOffset(bodyScrollBar.offset.y)
          return bodyScrollBar.scrollTop
        },
      })
      bodyScrollBar.addListener(ScrollTrigger.update)
      ScrollTrigger.defaults({ scroller: scrollerConst })
    }
    setScrollTriggerReady(true)
    setMenuReady(true)
  }

  useEffect(() => {
    //when switching pages reset scrollbar to y = 0
    if (resetOffset && !isTouchScreen) {
      const scrollbar = Scrollbar.get(scroller)
      scrollbar && scrollbar.setPosition(0, 0)
      initScrollBar(true)
      updateResetOffset(false)
    }
  }, [resetOffset === true])

  // useEffect(() => {
  //   !isCookiesVisible && gsap.to(cookies, {visibility: 'hidden', duration: 1})
  // }, [isCookiesVisible])

  //animate scroll down flicker so that it stop at the end of the page
  useEffect(() => {}, [scrollOffset])

  useEffect(() => {
    const roomsCategoryName = {
      "438969": "executive-room",
      "438970": "privilege-room",
      "438971": "garden-prestige-room",
      "438972": "confort-superior-room",
      "438973": "garden-comfort-room",
      "438974": "confort-room",
      "438975": "prestige-terrasse-room",
      "438976": "prestige-anis-room",
      "438977": "prestige-canard-room",
      "438978": "prestige-mauve-room",
    }
    const roomsCategories = [
      "438969",
      "438970",
      "438971",
      "438972",
      "438973",
      "438974",
      "438975",
      "438976",
      "438977",
      "438978",
    ]
    if (startingDate && endingDate) {
      // function urlToFetch(roomCat) {
      //   return new Promise((resolve, reject) => {
      //     let urlBody = `https://secure.reservit.com/api/rs/bestprice/2/429967?fromdate=${startingDate}&todate=${endingDate}&roomAge1=30,30&lang=FR&currency=EUR&roomCategories=${roomCat}&serviceIncluded=false`
      //     request.get(urlBody, function (error, response, body) {
      //       if (!error && response.statusCode == 200) {
      //         console.log(response, 'response')
      //       } else {
      //         reject(response);
      //       }
      //     });
      //   })
      // }
      isFetchingAvailabilities(true)
      function formatDate(date) {
        var d = new Date(date),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear()

        if (month.length < 2) month = "0" + month
        if (day.length < 2) day = "0" + day

        return [year, month, day].join("-")
      }
      let urlsToFetch = []
      let arrAvailabilities = []
      function createAxiosUrl(roomCat) {
        let urlBody = `https://secure.reservit.com/api/rs/bestprice/2/429967?fromdate=${formatDate(
          startingDate
        )}&todate=${formatDate(
          endingDate
        )}&roomAge1=30,30&lang=FR&currency=EUR&roomCategories=${roomCat}&serviceIncluded=false`
        urlsToFetch.push(axios.get(urlBody))
      }
      roomsCategories.map(roomCat => createAxiosUrl(roomCat))

      axios
        .all(urlsToFetch)
        .then(
          axios.spread((...responses) => {
            responses.map((response, index) => {
              let objAvailability = {}
              const queryString = response.config.url
              const urlParams = new URLSearchParams(queryString)
              const category = urlParams.get("roomCategories")
              const roomCategory = roomsCategoryName[category]
              objAvailability[roomCategory] =
                response.data.bestPrice_unFormatted
              arrAvailabilities.push(objAvailability)
            })
            isFetchingAvailabilities(false)
            updateRoomsAvailabilities(arrAvailabilities)
            axios
              .post(
                "https://reservations-requests.herokuapp.com/new_request",
                // axios.post('http://localhost:5000/new_request',
                {
                  hotelId: 2,
                  sessionId: sessionUuid,
                  fromMobile: isTouchDevice ? true : false,
                  startDate: formatDate(startingDate),
                  endDate: formatDate(endingDate),
                  offers: arrAvailabilities,
                }
              )
              .then(res => console.log(res))
            setSessionId(sessionUuid)
          })
        )
        .catch(errors => {
          // react on errors.
          console.error(errors, "errors reservit")
        })
    }
  }, [startingDate, endingDate])

  useEffect(() => {
    if (mainImageLoaded) {
      let tl = gsap.timeline()
      // gsap.to(loadingBanner, {yPercent: -100, duration: 1, delay: 3})
      initScrollBar()
      tl.to(loadingBanner, {
        yPercent: 100,
        duration: 1,
        delay: firstBannerSeen ? 1 : 5,
      }).set(loadingBanner, { yPercent: -100 })
    } else if (window.location.pathname != "/" && firstTimeLayoutRendered) {
      // gsap.set(loadingBanner, {yPercent: 0, duration: 2})
      gsap.to(loadingBanner, { yPercent: 0, duration: 2, delay: 0.3 })
    } else if (!mainImageLoaded) {
      if (!firstTimeLayoutRendered) {
        // gsap.set(loadingBanner, {yPercent: -100 , duration: 2})
        // gsap.to(loadingBanner, {yPercent: 0, duration: 2})
        gsap.to(loadingBanner, { yPercent: 0, duration: 2 })
      }
    }
  }, [mainImageLoaded])

  useEffect(() => {
    const asideContainer = aside
    gsap.to(asideContainer, {
      backgroundColor: roomListColor,
    })
  }, [roomListColor])

  useEffect(() => {
    if (scrolltriggerReady) {
      const mainChildrenDivConst = mainChildrenDiv
      const scrollTrig = ScrollTrigger.create({
        trigger: mainChildrenDiv,
        start: "150px",
        onEnter: () => {
          updateShowNavbar({ show: false, delay: 0 })
        },
        onLeaveBack: () => {
          updateShowNavbar({ show: true, delay: 0 })

          // showMenu?
          //   updateShowNavbar({show: true, delay: 2000})
          //   :
          //   updateShowNavbar({show: true, delay: 0})
        },
      })
    }
  }, [scrolltriggerReady])

  const updateInitialLanguageFunc = (initialLang, language) => {
    const url = window.location.href
    if (url.includes("/en")) {
      setInitialLanguageUpdated(true)
      language != "en" && updateInitialLanguage("en")
    } else if (url.includes("/ru")) {
      setInitialLanguageUpdated(true)
      language != "ru" && updateInitialLanguage("ru")
    } else if (url.includes("/fr")) {
      setInitialLanguageUpdated(true)
      language != "fr" && updateInitialLanguage("fr")
    } else if (initialLang.includes("fr")) {
      setInitialLanguageUpdated(true)
      language != "fr" && updateInitialLanguage("fr")
      // } else if (initialLang.includes('fr')) {
      //   setInitialLanguageUpdated(true)
      //   language != 'fr' && updateInitialLanguage('fr')
    } else if (initialLang.includes("ru")) {
      setInitialLanguageUpdated(true)
      language != "ru" && updateInitialLanguage("ru")
    } else {
      setInitialLanguageUpdated(true)
      language != "en" && updateInitialLanguage("en")
    }
  }

  useEffect(() => {
    if (isTouchScreen && Scrollbar.has(scroller)) {
      const scrollbar = Scrollbar.get(scroller)
      scrollbar.update({ damping: 0.2 })
    }
  }, [isTouchScreen])

  if (typeof window !== "undefined") {
    const result = detect(window.navigator.language)
    !initialLanguageUpdated && updateInitialLanguageFunc(result, language)
  }

  useEffect(() => {
    setTimeout(() => setFirstBannerSeen(true), 6000)
    updateLayoutRendered()
    if (window.location.pathname != "/") {
      hideTransitionBanner()
    }

    "ontouchstart" in window && updateIsTouchScreen()
    //updated width on resize

    setWindowWidth(window.innerWidth)
    updateWindowWidth(window.innerWidth)

    setSessionUuid(uuidv4())
  }, [])

  useEffect(() => {
    //   function handleResize() {
    //     updateWindowWidth(window.innerWidth)
    //     // window.location.reload();
    //     // console.log(showReservationCenter, 'reservaiton')
    //     showReservationCenter && window.location.reload();
    // }
    // window.addEventListener('resize', handleResize)
  }, [showReservationCenter])

  const scrollDown = () => {
    if (Scrollbar.get(scroller)) {
      Scrollbar.get(scroller).scrollTo(
        0,
        Scrollbar.get(scroller).offset.y + window.innerHeight,
        1200
      )
    }
  }

  const checkIfOnRoomPage = () => window.location.pathname.includes("-room")

  // Locking the body from scrolling when menu is opened
  useEffect(() => {
    menuState
      ? document.body.classList.add("body-lock")
      : document.body.classList.remove("body-lock")
  }, [menuState])

  //get x and y mouse coordinates
  // const { x, y } = useMousePosition()
  const x = 0
  const y = 0

  return (
    <I18nProvider language={language || "en"} catalogs={catalogs}>
      <div ref={el => (allBody = el)}>
        <aside ref={el => (aside = el)}></aside>
        <div ref={cookies}>
          <Cookies />
        </div>
        <div
          className="app"
          ref={el => (scroller = el)}
          id="scroller-app"
          style={
            !isTouchScreen
              ? { width: "100%", height: "100vh", overflow: "auto" }
              : {}
          }
        >
          <motion.div
            animate={{
              x: x - 16,
              y: y - 16,
              scale: cursorHovered ? 1.2 : 1,
              opacity: cursorHovered ? 0.8 : 0,
            }}
            transition={{
              ease: "linear",
              // duration: 0.2,
            }}
            className="cursor"
          ></motion.div>

          <div>
            <div
              className="mainChildren"
              style={{
                top: 0,
                position: "absolute",
                height: "30vh",
                width: "100vw",
              }}
              ref={el => (mainChildrenDiv = el)}
            ></div>
            <main>{children}</main>
          </div>
        </div>
        <div className="loading-banner" ref={el => (loadingBanner = el)}>
          {firstBannerSeen ? (
            <Logo color="black" margTop={"calc(50vh - 250px)"} animate={true} />
          ) : (
            <LogoAnimated />
          )}
        </div>
        {windowWidth > 576 ? (
          <>
            <ReservationButton />
            <ReservationCenter />
          </>
        ) : (
          <>
            <ReservationButtonMobile />
            <ReservationCenterMobile />
          </>
        )}
        <ReservationFinalBar />
        {/* <div
            style={{width: '100vw', height: '100vh'}}
          > */}
        <Menu
          setCursorHovered={setCursorHovered}
          menuState={menuState}
          setMenuState={setMenuState}
          x={x}
          y={y}
        />
        {/* </div> */}
        <div ref={el => (navbar = el)}>
          <Navbar
            showNavbar={showNavbar}
            setMenuState={setMenuState}
            menuState={menuState}
          />
        </div>
        <div ref={el => (downArrow = el)} onClick={() => scrollDown()}>
          {showArrowDown && <DownArrow color="#A88F5D" />}
        </div>
      </div>
    </I18nProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const mapStateToProps = ({
  showTransition,
  showMenu,
  language,
  languageCatalogs,
  mainImageLoaded,
  firstTimeLayoutRendered,
  scrolltriggerReady,
  scrollOffset,
  resetOffset,
  showReservationCenter,
  isTouchScreen,
  roomListColor,
  isVideoPlaying,
  isCookiesVisible,
  startingDate,
  endingDate,
  loadingBannerFromRoom,
  showArrowDown,
  isTouchDevice,
}) => {
  return {
    showTransition,
    showMenu,
    language,
    languageCatalogs,
    mainImageLoaded,
    firstTimeLayoutRendered,
    scrolltriggerReady,
    scrollOffset,
    resetOffset,
    showReservationCenter,
    isTouchScreen,
    roomListColor,
    isVideoPlaying,
    isCookiesVisible,
    startingDate,
    endingDate,
    loadingBannerFromRoom,
    showArrowDown,
    isTouchDevice,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateShowNavbar: navbarObj =>
      dispatch({
        type: `UPDATE_SHOW_NABAR`,
        payload: {
          show: navbarObj.show,
          delay: navbarObj.delay,
        },
      }),
    updateInitialLanguage: lang =>
      dispatch({ type: `UPDATE_INITIAL_LANGUAGE`, payload: lang }),
    updateIsTouchScreen: () =>
      dispatch({ type: `UPDATE_IS_TOUCHSCREEN`, payload: true }),
    updateWindowWidth: windowWidth =>
      dispatch({ type: `UPDATE_WINDOW_WIDTH`, payload: windowWidth }),
    updateLayoutRendered: () => dispatch({ type: `UPDATE_LAYOUT_RENDERED` }),
    hideTransitionBanner: () => dispatch({ type: `IMG_LOADED` }),
    setScrollTriggerReady: bool =>
      dispatch({ type: `SCROLLTRIGGER_READY`, payload: bool }),
    updateScrollOffset: value =>
      dispatch({ type: `UPDATE_OFFSET`, payload: value }),
    updateResetOffset: bool =>
      dispatch({ type: "RESET_OFFSET", payload: bool }),
    updateRoomsAvailabilities: arr =>
      dispatch({ type: `UPDATE_ROOMS_AVAILABILITIES`, payload: arr }),
    isFetchingAvailabilities: arr =>
      dispatch({ type: `UPDATE_FETCHING_AVAILABILITIES`, payload: arr }),
    setSessionId: id => dispatch({ type: `SET_SESSION_ID`, payload: id }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
