import { Trans } from "@lingui/macro"
import gsap from "gsap"
import React, { useEffect, useRef } from "react"
import { connect } from "react-redux"

export const ReservationButtonMobile = ({
  showReservationBtn,
  updateShowReservationCenter,
  showRoomBarReservation,
  isVideoPlaying,
}) => {
  let container = useRef(null)

  // useEffect(() => {
  //     console.log('showRoomBarReservation')
  //     showRoomBarReservation && gsap.set(container, {yPercent : 100})
  // }, [showRoomBarReservation])

  useEffect(() => {
    if (isVideoPlaying) {
      gsap.to(container, { yPercent: 100 })
    } else {
      gsap.to(container, { yPercent: 0 })
    }
  }, [isVideoPlaying])

  useEffect(() => {
    if (!showReservationBtn && !showRoomBarReservation) {
      gsap.to(container, { yPercent: 0, duration: 0.3 })
    } else if (showRoomBarReservation) {
      gsap.set(container, { yPercent: 0 })
    } else if (showReservationBtn && !showRoomBarReservation) {
      gsap.to(container, { yPercent: -100, delay: 1 })
    }
  }, [showReservationBtn, showRoomBarReservation])
  return (
    <div
      className="reservation-button-mobile"
      ref={el => (container = el)}
      onClick={() => updateShowReservationCenter()}
    >
      <div className="overflow-div">
        <div className="greyish-background"></div>
        <div className="text">
          <Trans>TARIFS / RÉSERVER</Trans>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({
  showReservationBtn,
  showRoomBarReservation,
  isVideoPlaying,
}) => {
  return { showReservationBtn, showRoomBarReservation, isVideoPlaying }
}

const mapDispatchToProps = dispatch => {
  return {
    updateShowReservationCenter: () =>
      dispatch({
        type: `SHOW_RESERVATION_CENTER`,
      }),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReservationButtonMobile)
