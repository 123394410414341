import gsap from "gsap"
import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import EndCalendar from "./EndCalendar"
import RoomsList from "./RoomsList"
import StartCalendar from "./StartCalendar"
import { Trans } from "@lingui/macro"
import { console, IDBCursor, window } from "globalthis/implementation"
import DownArrow from "../share/DownArrow"
import { array } from "prop-types"

const ReservationCenter = ({
  showReservationCenter,
  hideShowReservationCenter,
  startingDate,
  endingDate,
  roomsAvailabilities,
}) => {
  let container = useRef(null)
  let containerScroll = useRef(null)
  let containerSize = useRef(null)

  const [availsNumber, setAvailsNumber] = useState(0)

  useEffect(() => {
    // const containerWidth = container.getBoundingClientRect().width
    if (showReservationCenter) {
      let leftOffset

      if (window.innerWidth > 500 && window.innerWidth < 1500) {
        leftOffset = window.innerWidth * 0.7
      } else if (window.innerWidth > 1500) {
        leftOffset = 1050
      } else {
        leftOffset = 500
      }
      gsap.to(container, { x: leftOffset, delay: 0.5 })

      //allows scrolling inside reservation center
      const el = container

      ;["touchmove", "mousewheel", "wheel"].forEach(eventType => {
        el.addEventListener(eventType, e => e.stopPropagation())
      })
    } else {
      gsap.to(container, { x: 0, duration: 1 })
    }
  }, [showReservationCenter])

  useEffect(() => {
    console.log(containerSize.getBoundingClientRect().height, "container")
    console.log(roomsAvailabilities.filter(obj => (obj = {})).length, "length")
  }, [startingDate, endingDate])

  useEffect(() => {
    console.log("new roomsavail")
    let tempArr = []
    roomsAvailabilities.map(obj =>
      obj[Object.keys(obj)[0]] ? tempArr.push(obj) : null
    )
    setAvailsNumber(tempArr.length)
  }, [roomsAvailabilities])

  const isRoomPage = () => {
    const href = window.location.href
    const segments = new URL(href).pathname.split("/")
    const last = segments.pop() || segments.pop()

    if (last == "rooms") {
      return true
    } else {
      return false
    }
  }

  return (
    <div
      ref={el => (container = el)}
      // style={{direction:'ltr'}}
      className="reservation-center"
      style={{ overflow: "auto" }}
      // style={{direction: 'rtl', overflow: 'auto'}}
    >
      {/* <div
                ref={el => containerScroll = el}     
                style={{width: '500px', height: '100vh', position: 'absolute', top: 0, overflow: 'auto'}}
                >
            </div> */}
      <div
        style={{ direction: "ltr" }}
        ref={el => (containerSize = el)}
        style={{ position: "relative" }}
      >
        <div className="row">
          <div className="col-10 offset-1">
            <div className="close">
              <i
                className="fas fa-times"
                onClick={() => hideShowReservationCenter()}
              ></i>
            </div>
            <div className="calendars" style={{ display: "flex" }}>
              <StartCalendar />
              <EndCalendar />
            </div>
          </div>
        </div>
        {!startingDate && !endingDate && isRoomPage() ? (
          <div className="enter-dates">
            <Trans>
              Merci d'entrer des dates pour connaître nos disponibilités
            </Trans>
          </div>
        ) : (
          <RoomsList />
        )}
        {endingDate && startingDate && availsNumber > 0 ? (
          <DownArrow color="white" isReservationCenter={true} />
        ) : null}
      </div>
    </div>
  )
}

const mapStateToProps = ({
  showReservationCenter,
  startingDate,
  endingDate,
  roomsAvailabilities,
}) => {
  return {
    showReservationCenter,
    startingDate,
    endingDate,
    roomsAvailabilities,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    hideShowReservationCenter: () =>
      dispatch({
        type: `HIDE_RESERVATION_CENTER`,
      }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReservationCenter)

// https://secure.reservit.com/reservit/reserhotel.php?lang=FR&id=2&hotelid=429967&roomtcode=438971&catcode=1023437&fday=12&fmonth=02&fyear=2021&nbnights=1
