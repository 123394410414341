import React from "react"
import { connect } from "react-redux"

async function loadMessages(language) {
  return await import(`../../../i18n/lingui/${language}/messages.json`)
}

async function handleLanguageChange(language) {
  const newCatalog = await loadMessages(language)
  return { [language]: { message: newCatalog.default } }
}

function LanguageSelector({ language, updateLanguage, navColor }) {
  const handleChange = event => {
    event.preventDefault()
    let languageSelected = event.target.value
    handleLanguageChange(languageSelected).then(response => {
      updateLanguage({
        language: languageSelected,
        langCatalog: response,
      })
    })
  }

  return (
    <div className="select">
      <select
        onChange={e => handleChange(e)}
        value={language}
        style={{ color: navColor }}
      >
        <option value="en">EN</option>
        <option value="fr">FR</option>
        <option value="ru">RU</option>
      </select>
    </div>
  )
}

const mapStateToProps = ({ language, navColor }) => {
  return { language, navColor }
}

const mapDispatchToProps = dispatch => {
  return {
    updateLanguage: langObj =>
      dispatch({ type: `UPDATE_LANGUAGE`, payload: langObj }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector)
