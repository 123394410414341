import React, { useEffect, useRef, useState } from "react"
import gsap from "gsap"
import NavbarMenuItem from "./NavbarMenuItem"
import { connect } from "react-redux"
import LanguageSelector from "./LanguageSelector"
import { Trans } from "@lingui/macro"

const Navbar = ({
  setMenuState,
  menuState,
  showNavbar,
  showMenu,
  updateShowNavbar,
  navColor,
  mainImageLoaded,
  isVideoPlaying,
}) => {
  let navbarContainer = useRef(null)
  let background = useRef(null)
  let verticalLine = useRef(null)
  let hamburgerMenu = useRef(null)
  let hamburgerButton = useRef(null)
  let closeMenu = useRef(null)
  let menuButtons = useRef(null)
  let menuItems = useRef([])

  const addToMenuItems = el => {
    if (el && !menuItems.current.includes(el)) {
      menuItems.current.push(el)
    }
  }

  const [showFirstLine, setShowFirstLine] = useState(false)

  let timeline = gsap.timeline()

  //language line
  let languageLine = useRef(null)
  const showLanguageLineFunc = () => {
    timeline
      .to(languageLine, { visibility: "visible" })
      .to(languageLine, { xPercent: 0 }, "<")
  }
  const hideLanguageLineFunc = () => {
    timeline
      .to(languageLine, { xPercent: 100 })
      .to(languageLine, { visibility: "hidden" })
      .to(languageLine, { xPercent: -100 })
  }

  const showMenuItems = (menuItems, delay) => {
    gsap.to(menuItems, {
      autoAlpha: 1,
      stagger: 0.1,
      // duration: 1.5,
    })
  }
  const hideMenuItems = menuItems => {
    gsap.to(menuItems, {
      autoAlpha: 0,
      stagger: 0.1,
      // duration: 1.5
    })
  }

  useEffect(() => {
    const menuItemsConst = menuItems.current
    if (mainImageLoaded) {
      !window.location.pathname.includes("-room") &&
        setTimeout(() => showMenuItems(menuItemsConst), 1000)
    } else {
      hideMenuItems(menuItemsConst)
    }
  }, [mainImageLoaded])

  useEffect(() => {
    // let tl = gsap.timeline()
    const menuItemsConst = menuItems.current
    if (showNavbar.show) {
      let pathname = window.location.pathname
      //if url includes -room, do not show navbar

      const checkUrlRoom = path => path.includes("-room")
      if (!checkUrlRoom(pathname) && showMenu) {
        setTimeout(() => showMenuItems(menuItemsConst), showNavbar.delay)
      } else if (!checkUrlRoom(pathname) && !showMenu) {
        setTimeout(() => showMenuItems(menuItemsConst), showNavbar.delay)
      }
      // !checkUrlRoom(pathname) && setTimeout(() => showMenuItems(menuItemsConst), showNavbar.delay )
      // timeline
      // .to(background, {
      //     yPercent: 0,
      //     backgroundColor: 'white',
      // })
      // .to(navbarContainer, {color: 'black'}, '<')
      // .to(verticalLine, {backgroundColor: 'black'}, '<')
      // .to(navbarContainer, {css: {boxShadow: 'rgba(0, 0, 0, 0.75) 0px 0px 35px -25px'} })
    } else {
      hideMenuItems(menuItemsConst)
      // timeline
      //   .to(navbarContainer, {css: {boxShadow: ''} })
      //   .to(background, { yPercent: -100, backgroundColor: 'transparent'}, '<')
      //   .to(verticalLine, {backgroundColor: 'white'}, '<')
      //   .to(navbarContainer, {color: 'white'}, '<')
    }
  }, [showNavbar])

  useEffect(() => {
    if (isVideoPlaying) {
      gsap.to(navbarContainer, {
        autoAlpha: 0,
        duration: 1,
      })
    } else {
      gsap.to(navbarContainer, {
        autoAlpha: 1,
        duration: 1,
      })
    }
  }, [isVideoPlaying])

  //replace hamburger by close button
  useEffect(() => {
    const tl = gsap.timeline()
    const menuItemsConst = menuItems.current
    gsap.set(closeMenu, { xPercent: 400 })
    if (menuState) {
      tl
        // .to(menuButtons, 0, {visibility: 'hidden'})
        // .to(background, { yPercent: -100, backgroundColor: 'transparent'}, '<')
        // .to(navbarContainer, {css: {boxShadow: ''} },  '<')
        .to(hamburgerButton, { xPercent: -300 }, "<")
        .to(
          hamburgerMenu,
          { visibility: "hidden", backgroundColor: "transparent" },
          "<"
        )
        .to(hamburgerMenu, {
          visibility: "visible",
          border: "1px solid white",
          delay: 1.5,
        })
        .to(closeMenu, { xPercent: 0 })
      hideMenuItems(menuItemsConst)
    } else {
      tl.set(closeMenu, { xPercent: 400, visibility: "visible", delay: 1 })
        .to(hamburgerButton, { xPercent: 0 })
        .to(
          hamburgerMenu,
          {
            visibility: "hidden",
            backgroundColor: "transparent",
            border: "none",
          },
          "<"
        )
        // .to(background, { yPercent: 0, backgroundColor: showNavbar?'white':'transparent', delay: 1.5})
        .to(hamburgerMenu, { visibility: "visible" }, "<")
      // .to(menuButtons, {visibility: 'visible'}, '<')
      // .to(navbarContainer, {color: showNavbar?'black':'white'}, '<')
      // .to(verticalLine, {backgroundColor: showNavbar?'black':'white'}, '<')
      // .to(navbarContainer, {css: {boxShadow: showNavbar?'rgba(0, 0, 0, 0.75) 0px 0px 35px -25px':''} })
      // showMenuItems(menuItemsConst)
      // setTimeout(() =>showMenuItems, 2000)
      // showMenuItems(menuItemsConst)
    }
  }, [menuState])

  return (
    <div className="navbar-main" ref={el => (navbarContainer = el)}>
      <div className="bg-parent">
        <div className="links">
          <div className=" d-none d-sm-block">
            <div className="flex" ref={el => (menuButtons = el)}>
              <div
                className="link marginTop"
                style={{ marginLeft: "2vw" }}
                ref={el => addToMenuItems(el)}
              >
                <NavbarMenuItem
                  linkName="hotel"
                  name={<Trans>hotel</Trans>}
                  url=""
                  duration={3}
                />
              </div>
              <div
                className="link marginTop"
                style={{ marginLeft: "2vw" }}
                ref={el => addToMenuItems(el)}
              >
                <NavbarMenuItem
                  linkName="room"
                  name={<Trans>chambres</Trans>}
                  url="rooms"
                  duration={3}
                />
              </div>
              <div
                className="link marginTop"
                style={{ marginLeft: "2vw" }}
                ref={el => addToMenuItems(el)}
              >
                <NavbarMenuItem
                  linkName="spa"
                  name={<Trans>spa</Trans>}
                  url="spa"
                  duration={3}
                />
              </div>
              <div
                className="link marginTop"
                style={{ marginLeft: "2vw" }}
                ref={el => addToMenuItems(el)}
              >
                <NavbarMenuItem
                  linkName="contact"
                  name={<Trans>activitées</Trans>}
                  url="activities"
                  duration={3}
                />
              </div>
              <div
                className="link marginTop"
                style={{ marginLeft: "2vw" }}
                ref={el => addToMenuItems(el)}
              >
                <NavbarMenuItem
                  name={<Trans>contact</Trans>}
                  url="contact"
                  duration={3}
                />
              </div>
              <div
                className="link language"
                onMouseEnter={() => showLanguageLineFunc()}
                onMouseLeave={() => hideLanguageLineFunc()}
                ref={el => addToMenuItems(el)}
              >
                <div
                  className="vertical-line"
                  ref={el => (verticalLine = el)}
                  // style={{backgroundColor: 'white'}}
                  style={{ backgroundColor: navColor }}
                ></div>
                <div className="text-hover">
                  <LanguageSelector />
                  <div
                    className="red-line"
                    ref={el => (languageLine = el)}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="hamburger"
            onClick={() => setMenuState(!menuState)}
            ref={el => (hamburgerMenu = el)}
          >
            <i
              className="fas fa-bars"
              // style={{color: navColor}}
              ref={el => (hamburgerButton = el)}
            ></i>
            <i
              className="fas fa-times"
              onClick={() => updateShowNavbar({ show: true, delay: 2000 })}
              ref={el => (closeMenu = el)}
            ></i>
          </div>
        </div>
        <div className="bg" ref={el => (background = el)}></div>
      </div>
    </div>
  )
}

const mapStateToProps = ({
  showNavbar,
  showMenu,
  navColor,
  mainImageLoaded,
  isVideoPlaying,
}) => {
  return { showNavbar, showMenu, navColor, mainImageLoaded, isVideoPlaying }
}

const mapDispatchToProps = dispatch => {
  return {
    updateShowNavbar: navbarObj =>
      dispatch({
        type: `UPDATE_SHOW_NABAR`,
        payload: {
          show: navbarObj.show,
          delay: navbarObj.delay,
        },
      }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)
