/* eslint-disable */ module.exports = {
  languageData: {
    plurals: function (n, ord) {
      var s = String(n).split("."),
        v0 = !s[1],
        t0 = Number(s[0]) == n,
        n10 = t0 && s[0].slice(-1),
        n100 = t0 && s[0].slice(-2)
      if (ord)
        return n10 == 1 && n100 != 11
          ? "one"
          : n10 == 2 && n100 != 12
          ? "two"
          : n10 == 3 && n100 != 13
          ? "few"
          : "other"
      return n == 1 && v0 ? "one" : "other"
    },
  },
  messages: {
    CONTACT: "CONTACT",
    Hotel: "Hotel",
    Chambres: "Rooms",
    Spa: "Spa",
    Activitées: "Activities",
    "La Magie de la Vall\xE9e de la Loire": "The Magic of the Loire Valley",
    "Plus d'infos": "See more",
    "Amboise, ville royale aux mille merveilles":
      "Amboise, royal city of a thousand wonders",
    "AU CHARME RABELAISIEN est situ\xE9 au c\u0153ur de la r\xE9gion des Ch\xE2teaux de la Loire. Au d\xE9part de notre h\xF4tel, offrez-vous une plong\xE9e dans l\u2019histoire de France lors de votre escapade en bord de Loire. Les superbes paysages du bord de Loire et les b\xE2timents historiques":
      "Au Charme Rabelaisien is located in the heart of the Loire Valley Castles region. Departing from the hotel, take a dive into the history of France during your getaway along the Loire. The amazing landscapes on the banks of the Loire and the historic and",
    "et architecturaux charg\xE9s d\u2019histoire font du Val de Loire un territoire riche et incontournable, inscrit \xE0 la liste du Patrimoine Mondial de l\u2019UNESCO. La r\xE9gion s\u2019\xE9tend sur une aire de 800 km2, le long de la Loire sur 4 d\xE9partements.":
      "architectural buildings make the Loire Valley a rich and unmissable territory, registered on the UNESCO World Heritage list. The region extends over an area of 800km\xB2 along the Loire over 4 departments.",
    "Envoyez-nous un message": "Sens us a message",
    Envoyer: "Send",
    "Merci ! Nous vous contacterons au plus vite :)":
      "Merci ! Nous vous contacterons au plus vite :)",
    "Contactez-nous et nous nous ferons un plaisir de vous aider.":
      "Contactez-nous et nous nous ferons un plaisir de vous aider.",
    Addresse: "Address",
    "Contact info": "Contacts",
    "ENTREZ   EN   CONTACT   AVEC       NOUS":
      "WOULD   LOVE   TO     GET       IN        TOUCH",
    Cookies: "Cookies",
    "Au Charme Rabelaisien uses its own and third-party analytical cookies to analyze your preferences and improve your experience on the website If you want to learn more or opt out of all or some cookies,":
      "Au Charme Rabelaisien uses its own and third-party analytical cookies to analyze your preferences and improve your experience on the website If you want to learn more or opt out of all or some cookies,",
    "Learn more about their use.": "Learn more about their use.",
    Accept: "Accept",
    "Parking Priv\xE9 S\xE9curis\xE9": "Secure Private Parking",
    "L'h\xF4tel b\xE9n\xE9ficie d'un parking payant et s\xE9curis\xE9. Suivant disponibilit\xE9, sa reservation au pr\xE9alable est n\xE9cessaire.":
      "The hotel has a private and secured parking. Reservation upon your arrival is necessary.",
    "Prix: 12 euros par nuit": "Price: 12\u20AC per night",
    "Petit D\xE9jeuner Continental": "Continental Breakfast",
    "Un moment \xE0 savourer, un moment o\xF9 l'on prend son temps pour d\xE9guster notre buffet, un moment ou l'on s'attarde autour d'un caf\xE9. Il est temps pour vous de d\xE9buter votre journ\xE9e \xE0 Amboise de la plus belle mani\xE8re qu'il soit.":
      "A moment to savor, a moment when we take our time to taste our buffet, a moment when we linger around a coffee. It's time for you to start your day in Amboise in the best possible way.",
    Conciergerie: "Conciergerie",
    "Nous nous ferons un plaisir de mettre \xE0 profit nos connaissances pour rendre votre s\xE9jour le meilleur possible avec des services personnalis\xE9s (r\xE9servations de restaurants, taxis, ou visites culturelles et autres lieux embl\xE9matiques \xE0 d\xE9couvrir).":
      "We will be happy to use our knowledge to make your stay the best possible with personalized services (restaurant reservations, taxis, or cultural visits and other emblematic places to discover)",
    Welcome: "Welcome",
    "Nous aimons nos amis \xE0 quatre pattes, ils sont donc les bienvenus !":
      "We love our four-legged friends, so they are welcome!",
    "Sur demande, nous acceptons les chiens de moins de 10 kilos - 25 euros de suppl\xE9ment par nuit.":
      "On request, we accept dogs less than 8 kilos.",
    "Prix: 25 euros de suppl\xE9ment par nuit.":
      "Price: 25\u20AC supplement per night.",
    Ascenceur: "Elevator",
    "Un ascenceur vitr\xE9 est \xE0 votre disposition pour desservir le 1er et 2\xE8me \xE9tage dans la maison de ma\xEEtre.":
      "A glass elevator is at your disposal to serve the 1st and 2ndfloor in the mansion.",
    "NOS ACTIVIT\xC9ES": "Activities",
    "\xC0 partir de": "From",
    "Nos autres chambres disponibles": "Our other rooms available",
    "Nos autres chambres": "Nos autres chambres",
    GALLERIE: "Photogallery",
    "Un avant-go\xFBt de notre caract\xEAre": "A flavour of our character",
    "Suivez-Nous": "Follow us",
    "Join the adventure!": "Join the adventure!",
    "SUIVEZ-NOUS": "Follow us",
    "THE EXPERIENCE": "THE EXPERIENCE",
    Purely: "Purely",
    Original: "Original",
    "In Amboise historical town center, the hotel is an expression of the cultural generosity of the Loire Valley and its modern spirit.":
      "In the historic heart of the royal city of Amboise, the hotel is the expression of cultural generosity of the Loire River and its modern spirit",
    "Inscrivez-vous \xE0": "Subscribe to",
    "notre newsletter": "our newsletter",
    "Votre addresse email": "Your email",
    OK: "OK",
    "Congratulation! You are now registered !":
      "Congratulation! You are now registered !",
    "Salles de bains": "Modern",
    Modernes: "bathrooms",
    "Exclusives, spacieuses et incroyablement bien \xE9quip\xE9es (baignoires et douches \xE0 jets hydromassants s\xE9par\xE9es), elles sont aussi accueillantes et  agr\xE9ables que les grandes serviettes moelleuses que vous y trouverez.":
      "Exclusive, spacious and incredibly well-equipped bathrooms (baths and separate hydro-massage showers), they are as welcoming and pleasant as the large, fluffy towels you will find there",
    "VOIR LES CHAMBRES": "SEE ROOMS",
    Terrasses: "Private",
    Privées: "terraces",
    "D\u2019un petit d\xE9jeuner sur votre terrasse \xE0 un dernier verre au bar priv\xE9 qu\u2019offre votre chambre, tout est fait pour que vous vous sentiez comme chez vous.":
      "From breakfast on your terrace to a nightcap in the private bar in your room, everything is done to make you feel at home",
    Vues: "Immersive",
    Immersives: "Views",
    "Offrez-vous des vues pittoresques sur le Ch\xE2teau, notre jardin paysager avec sa piscine et ses toits Amboisiens":
      "Discover the most incredible views on the Castle of Amboise, the scenic garden with a swimming pool and the rooftops of Amboise",
    DÉCROCHER: "Allow your spirit to unwind",
    "avec Vue": "with a view",
    "C\xE9l\xE9brant l'esprit d'Amboise et de sa Loire sauvage, les 10 chambres de Au Charme Rabelaisien entourent les visiteurs de la beaut\xE9 captivante de la destination.":
      "Celebrating the spirit of Amboise and the beauty of the majestic Loire River, the 10 rooms of Au Charme Rabelaisien will envelop visitors in the captivating atmosphere",
    "Une invitation \xE0 profiter des vues les plus extraordinaires sur le ch\xE2teau, le jardin paysag\xE9 ou les superbes toits de cette ville historique.":
      "Imbued with reverie and history, near Leonard de Vinci\u2019s last home, Au Charme Rabelaisien is the window on nature and eternity of time.",
    DÉTENTE: "Relaxation",
    "L'ACR": "ACR",
    "Pour votre d\xE9tente et votre bien-\xEAtre, l\u2019h\xF4tel ACR s\u2019est dot\xE9 d\u2019un espace bien-\xEAtre avec jaccuzzi et sauna \xE0 acc\xE8s privatif.":
      "For your relaxation and well-being, the ACR hotel has a wellness area with jacuzzi and sauna with private access",
    Massages: "Massage",
    Jaccuzi: "Jacuzzi",
    Sauna: "Sauna",
    "Rayonnez et laissez vos tensions dispara\xEEtre entre les mains expertes de nos sp\xE9cialistes, au gr\xE9 de soins exceptionnels.":
      "Radiate and let your tensions disappear in the expert hands of our specialists, with exceptional care",
    "L\u2019immersion dans cette eau chaude \xE0 remous procure une sensation imm\xE9diate de d\xE9tente.":
      "Immersion in this hot whirlpool provides an immediate feeling of relaxation",
    "Le sauna offre une sensation de purification. Il stimule la circulation sanguine, offre une d\xE9tente physique et mentale compl\xE8te.":
      "The sauna offers a feeling of purification. It stimulates blood circulation, provides complete physical and mental relaxation",
    "EN SAVOIR PLUS": "Learn more",
    "FERMER VIDEO": "CLOSE VIDEO",
    "TARIFS / R\xC9SERVER": "BOOK / RATES",
    "PRIX:": "PRICE",
    RÉSERVER: "BOOK",
    Imprenables: "Fantastic",
    Luxueux: "Luxurious",
    Privé: "Personal",
    Confort: "Comfort",
    "Vues Imprenables": "Unobstructed views",
    "Douche \xE0 Jets et": "Jetted showers and",
    "Baignoires S\xE9par\xE9es": "separate tubs",
    "Exclusives, spacieuses et incroyablement bien \xE9quip\xE9es (baignoires et douches \xE0 jets hydromassants s\xE9par\xE9es), elles sont aussi accueillantes et agr\xE9ables que les grandes serviettes moelleuses que vous y trouverez.":
      "Exclusive, spacious and incredibly well-equipped bathrooms (baths and separate hydro-massage showers), they are as welcoming and pleasant as the large, fluffy towels you will find there",
    "Terrasses Privatives": "Private terraces",
    "Grand Confort": "Comfort",
    "Nos chambres disposent d\u2019un confort complet (Nespresso, produits de toilettes Fragonard, peignoirs, chaussons...), pour faire de votre s\xE9jour \xE0 Amboise, un moment r\xE9ussi, plac\xE9 sous le signe de la qui\xE9tude et du charme.":
      "Our rooms have complete comfort (Nespresso, Fragonard toiletries, bathrobes, slippers, etc.) to make your stay in Amboise a successful one under the sign of tranquility and charm",
    "NOS DIFF\xC9RENCES": "Our differences",
    "Nos Chambres": "Our rooms",
    "Chambres avec Vue Ch\xE2teau": "Rooms with views of castle",
    "Chambres avec Terrasse": "Rooms with terrace",
    "Chambres cot\xE9es Ville": "City side rooms",
    Découvrir: "Discover",
    "D\xE9couvrir la chambre": "Discover the",
    Chambre: " ",
    "Vue :": "View :",
    "Superficie :": "Area :",
    "Sdb :": "Bathroom :",
    "Occupation :": "Occupancy :",
    "Lits :": "Bed :",
    "Situation dans la :": "Location: ",
    "Climatisation r\xE9versible": "Reversible air conditioning",
    "WC s\xE9par\xE9 et ferm\xE9": "Closed toilets",
    CHAMBRES: "Rooms",
    LUXE: "LUXURY",
    et: "and",
    "SOUCIS DU D\xC9TAIL": "ATTENTION TO DETAIL",
    pour: "for",
    "UN S\xC9JOUR AUTHENTIC": "AN AUTHENTIC STAY",
    Contact: "Contacts",
    Address: "Address",
    hotel: "hotel",
    chambres: "rooms",
    spa: "spa",
    activitées: "activities",
    contact: "contact",
    "Besoin de plus d'informations?": "Need more information ?",
    "Contactez-nous": "Contact us",
    "Pour ceux qui souhaitent faire une pause relaxante pendant leur s\xE9jour, il y a un spa confortable \xE0 l\u2019int\xE9rieur de la structure. Id\xE9al pour se d\xE9tendre le corps et l\u2019esprit apr\xE8s une journ\xE9e de visites ou pour r\xE9cup\xE9rer de l\u2019\xE9nergie.":
      "For those who wish to take a relaxing break during their stay, the hotel has a comfortable spa. The ideal place for resting body and mind after a day of visiting or for recovering energy",
    "Intime et r\xE9serv\xE9": "Intimate and PRIVATE,",
    ", notre spa est con\xE7u pour accueillir un nombre limit\xE9 de deux clients qui pourront profiter pleinement des espaces":
      " the spa is designed to accommodate a limited number of two clients who can fully enjoy the spaces",
    Prix: "Price",
    "Privacy Policy": "Privacy Policy",
    "Terms of use": "Terms of use",
    "H\xF4tel Au Charme Rabelaisien provides its services under reserve that you to respect the present conditions of use, that can be changed at any time by H\xF4tel Au Charme Rabelaisien.":
      "H\xF4tel Au Charme Rabelaisien provides its services under reserve that you to respect the present conditions of use, that can be changed at any time by H\xF4tel Au Charme Rabelaisien.",
    "Intellectual property": "Intellectual property",
    "According to the laws poverning intellectual property, the reproduction or the use of elements found on this internet site, whether complete or partial, is strictly forbidden. Only are maintained the legal exceptions among which personal non-commercial use only (representation in the family circle, or private copy or the right to a short quotation.)":
      "According to the laws poverning intellectual property, the reproduction or the use of elements found on this internet site, whether complete or partial, is strictly forbidden. Only are maintained the legal exceptions among which personal non-commercial use only (representation in the family circle, or private copy or the right to a short quotation.)",
    "Thus, all the texts, photos, logos, brands and other elements reproduced on this site are protected by the right of intellectual property (Right author, right(straight) neighbour, right(law) of the marks(brands)).":
      "Thus, all the texts, photos, logos, brands and other elements reproduced on this site are protected by the right of intellectual property (Right author, right(straight) neighbour, right(law) of the marks(brands)).",
    "Any total or partial of this site by any means whatsoever, without the express permission of the operator of the website is prohibited and constitutes counterfeit sanctioned by articles L 335-2 and following of the Intellectual Property Code.":
      "Any total or partial of this site by any means whatsoever, without the express permission of the operator of the website is prohibited and constitutes counterfeit sanctioned by articles L 335-2 and following of the Intellectual Property Code.",
    "Same is true of any databases contained on the website which protected by the provisions of the Act of 11 July 1998 transposing into the Intellectual Property Code (IPC) of the European directive of 11 March 1996 on the Legal Protection of Databases":
      "Same is true of any databases contained on the website which protected by the provisions of the Act of 11 July 1998 transposing into the Intellectual Property Code (IPC) of the European directive of 11 March 1996 on the Legal Protection of Databases",
    "Personal datas": "Personal datas",
    "H\xF4tel Au Charme Rabelaisien complies fully with all applicable data protection and consumer legislation, and will treat all your personal information as confidential.We will not pass on your personal details to third parties. H\xF4tel Au Charme Rabelaisien makes a commitment to assure everyone who will make the demand a right of access, rectification and deletion of the datas concerning him/her.":
      "H\xF4tel Au Charme Rabelaisien complies fully with all applicable data protection and consumer legislation, and will treat all your personal information as confidential.We will not pass on your personal details to third parties. H\xF4tel Au Charme Rabelaisien makes a commitment to assure everyone who will make the demand a right of access, rectification and deletion of the datas concerning him/her.",
    Responsabilities: "Responsabilities",
    Links: "Links",
    "You are free to establish links towards this site, as long as they don\u2019t affect the interests and the image of its editor. You are allowed to establish links towards the pages of this site , only if they are in the followinf formats : html, php, xml.":
      "You are free to establish links towards this site, as long as they don\u2019t affect the interests and the image of its editor. You are allowed to establish links towards the pages of this site , only if they are in the followinf formats : html, php, xml.",
    "The practice of framing is strictly forbidden, as well as any other practice or technique which could confuse people.":
      "The practice of framing is strictly forbidden, as well as any other practice or technique which could confuse people.",
    "Deep links towards documents in the pdf, doc, or rtf formats are prohibited. In fact, those types of documents don\u2019t allow people to read the conditions of use.":
      "Deep links towards documents in the pdf, doc, or rtf formats are prohibited. In fact, those types of documents don\u2019t allow people to read the conditions of use.",
    "H\xF4tel Au Charme Rabelaisien is absolutely not responsible for the contents of Internet sites that can be reached through its website.":
      "H\xF4tel Au Charme Rabelaisien is absolutely not responsible for the contents of Internet sites that can be reached through its website.",
    "Enjoy your visit.": "Enjoy your visit.",
    "A cookie is a little file which a website sends to the browser and saves on the User\u2019s computer when visiting a website. Cookies are used in order to allow or enhance the running of the website, but also to gain information on the Site or on the User\u2019s web-browsing activities.":
      "A cookie is a little file which a website sends to the browser and saves on the User\u2019s computer when visiting a website. Cookies are used in order to allow or enhance the running of the website, but also to gain information on the Site or on the User\u2019s web-browsing activities.",
    "Technical cookies": "Technical cookies",
    "These cookies are essential to allow the User to browse on the Site and use its functionalities, such as the access to reserved areas of the Site (cookie of authentication). The strictly necessary cookies are used to record a univocal identifier in order to manage and identify the User with respect to other Users visiting the Site in the same moment, providing the User with a consistent and precise service (e.g. the language selection). These cookies are necessary to the correct working of the Site and to record the choices expressed by the User, to distribute requests on different servers, to record when the User gives his/her authorization or expresses the consent to specific options (e.g. newsletters), to allow the User to visualize contents and video through Adobe Flash Player.":
      "These cookies are essential to allow the User to browse on the Site and use its functionalities, such as the access to reserved areas of the Site (cookie of authentication). The strictly necessary cookies are used to record a univocal identifier in order to manage and identify the User with respect to other Users visiting the Site in the same moment, providing the User with a consistent and precise service (e.g. the language selection). These cookies are necessary to the correct working of the Site and to record the choices expressed by the User, to distribute requests on different servers, to record when the User gives his/her authorization or expresses the consent to specific options (e.g. newsletters), to allow the User to visualize contents and video through Adobe Flash Player.",
    "First Party Performance and Analytics Cookies":
      "First Party Performance and Analytics Cookies",
    "These cookies can be session cookies or persistent cookies and their use is limited to the performance and improvement of the Site. These cookies allow to recognize, measure and keep track of visitors to the Site, thus allowing the improvement of the Site, for example establishing whether the User is able to easily find the desired information, or by identifying which aspects of the Site are of major interest. These cookies are used by Au Charme Rabelaisien to process statistical and anonymous analysis on how Users navigate on the Site, the overall number of pages visited or the number of clicks made on a page while browsing. These first party cookies are considered by the privacy legislation as equal to technical cookies, therefore they do not require the consent of the User.":
      "These cookies can be session cookies or persistent cookies and their use is limited to the performance and improvement of the Site. These cookies allow to recognize, measure and keep track of visitors to the Site, thus allowing the improvement of the Site, for example establishing whether the User is able to easily find the desired information, or by identifying which aspects of the Site are of major interest. These cookies are used by Au Charme Rabelaisien to process statistical and anonymous analysis on how Users navigate on the Site, the overall number of pages visited or the number of clicks made on a page while browsing. These first party cookies are considered by the privacy legislation as equal to technical cookies, therefore they do not require the consent of the User.",
    "Third Party Analytics Cookies": "Third Party Analytics Cookies",
    "Au Charme Rabelaisien may also use analytics and performance cookies from third parties on the Site, including those of Google Analytics, as indicated below. These cookies collect information on the ways in which the User uses the Site, including, for example, the pages visited by the User. Furthermore, third-party analytics cookies can track the use that Users make of the Site and allow Au Charme Rabelaisien to make improvements to the Site by verifying, for example, whether the User is able to easily find the information sought or, again, identify the aspects of the Site that are most interesting. These cookies, with appropriate mechanisms to reduce the personal data collected (such as deletion of part of the User\u2019s IP address) are used by The St. Regis Venice to process analysis and statistics, exclusively in anonymous mode, on how Users browse the Site, the number of pages visited and clicks made on a page during navigation. Au Charme Rabelaisien treats the results of these analysis only if the third party uses cookies in connection with the browser installed on the computer or on other devices used by the User to browse the Site.":
      "Au Charme Rabelaisien may also use analytics and performance cookies from third parties on the Site, including those of Google Analytics, as indicated below. These cookies collect information on the ways in which the User uses the Site, including, for example, the pages visited by the User. Furthermore, third-party analytics cookies can track the use that Users make of the Site and allow Au Charme Rabelaisien to make improvements to the Site by verifying, for example, whether the User is able to easily find the information sought or, again, identify the aspects of the Site that are most interesting. These cookies, with appropriate mechanisms to reduce the personal data collected (such as deletion of part of the User\u2019s IP address) are used by The St. Regis Venice to process analysis and statistics, exclusively in anonymous mode, on how Users browse the Site, the number of pages visited and clicks made on a page during navigation. Au Charme Rabelaisien treats the results of these analysis only if the third party uses cookies in connection with the browser installed on the computer or on other devices used by the User to browse the Site.",
    "How to disable cookies on the browser":
      "How to disable cookies on the browser",
    "Apart from the choice to accept or refuse the cookies used by the Site for additional purposes by means of the mechanism illustrated in this Privacy Policy, in the future the User may disable from the browser the cookies installed by the Site also by means of the setting of the same browser. The majority of web browsers are set to accept cookies. However, if so wished, the User may set his/her browser to limit the number of cookies accepted or to block all cookies, by changing the browser\u2019s settings with the following procedures:":
      "Apart from the choice to accept or refuse the cookies used by the Site for additional purposes by means of the mechanism illustrated in this Privacy Policy, in the future the User may disable from the browser the cookies installed by the Site also by means of the setting of the same browser. The majority of web browsers are set to accept cookies. However, if so wished, the User may set his/her browser to limit the number of cookies accepted or to block all cookies, by changing the browser\u2019s settings with the following procedures:",
    ": Click \u201CTools\u201D in the top right corner and select \u201CInternet settings\u201D. In the pop-up select \u201CPrivacy\u201D: here the User may change the cookies settings.":
      ": Click \u201CTools\u201D in the top right corner and select \u201CInternet settings\u201D. In the pop-up select \u201CPrivacy\u201D: here the User may change the cookies settings.",
    ": Click the \u201Cwrench\u201D in the top right corner and select \u201CSettings\u201D. Then select \u201CShow advanced settings\u201D and change the \u201CPrivacy\u201D settings.":
      ": Click the \u201Cwrench\u201D in the top right corner and select \u201CSettings\u201D. Then select \u201CShow advanced settings\u201D and change the \u201CPrivacy\u201D settings.",
    ": In the top left menu select \u201CSettings\u201D. In the pop-up window select \u201CPrivacy\u201D: here the User may change the cookies settings.":
      ": In the top left menu select \u201CSettings\u201D. In the pop-up window select \u201CPrivacy\u201D: here the User may change the cookies settings.",
    ": In the top right menu select \u201CPreferences\u201D. Then select \u201CSecurity\u201D and change the cookies settings.":
      ": In the top right menu select \u201CPreferences\u201D. Then select \u201CSecurity\u201D and change the cookies settings.",
    "In order to carry out such operation in the mobile telephone, please consult the handbook of the device. Please be aware that by disabling all the cookies  on the Site, certain functionalities of the Site may not be available.":
      "In order to carry out such operation in the mobile telephone, please consult the handbook of the device. Please be aware that by disabling all the cookies  on the Site, certain functionalities of the Site may not be available.",
    "Further information on cookies": "Further information on cookies",
    "The website": "The website",
    "contains the instructions for the management of cookies of the most used browsers; alternatively, it is possible to consult the documentation related to the software used on the device.":
      "contains the instructions for the management of cookies of the most used browsers; alternatively, it is possible to consult the documentation related to the software used on the device.",
    "Amendments to the Privacy Policy": "Amendments to the Privacy Policy",
    "Au Charme Rabelaisien may amend, from time to time, this Privacy Policy, by publishing the amendments on the Site. We invite Users to check periodically such updates.":
      "Au Charme Rabelaisien may amend, from time to time, this Privacy Policy, by publishing the amendments on the Site. We invite Users to check periodically such updates.",
    "Chambre title room": "Room",
    Précédente: "Previous",
    "Chambre changer": "Room",
    Suivante: "Next",
    Indisponible: "Room Unavailable",
    "Il semblerait que nous soyons complet pour vos dates. Nous vous invitons \xE0 v\xE9rifier si tel est le cas en nous contactant.":
      "Seems like we are fully booked for these dates. Please feel free to contact us",
    "Nous recherchons les meilleurs tarifs guarantis":
      "We are looking for our best offers",
    "AUTRES DISPONIBILIT\xC9S?": "OTHERS AVAILABILITIES",
    Vue: "View",
    Superficie: "Area",
    "Climatisation r\u0117versible": "Air conditioning",
    Sdb: "Bathroom",
    Lit: "Bed",
    Occupation: "Occupancy",
    "Terrasse priv\xE9e": "Private terrace",
    "FERMER LA VIDEO": "END VIDEO",
    "Les chambres avec vue Ch\xE2teau :": "Rooms with a Castle View :",
    "Les chambres cot\xE9es ville :": "City side rooms :",
    "Les chambres avec une terrasse priv\xE9e :":
      "Rooms with a private terrace :",
    "V\xE9rifier la disponibilit\xE9": "Check availability",
    "Merci d'entrer des dates pour conna\xEEtre nos disponibilit\xE9s":
      "Please enter dates",
  },
}
