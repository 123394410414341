import React, { useRef, useEffect } from "react"
import gsap from "gsap"
import { connect } from "react-redux"
import { Link } from "gatsby"
import { Trans } from "@lingui/macro"

const CookiesBanner = ({
  hideCookies,
  isCookiesVisible,
  isVideoPlaying,
  language,
}) => {
  let hoverLine = useRef(null)
  let container = useRef(null)

  const showHover = () => gsap.to(hoverLine, { xPercent: 0 })
  const hideHover = () => gsap.to(hoverLine, { xPercent: -110 })

  useEffect(() => {
    if (isVideoPlaying) {
      gsap.to(container, { opacity: 0 })
    } else {
      gsap.to(container, { opacity: 1 })
    }
  }, [isVideoPlaying])

  useEffect(() => {
    if (isCookiesVisible) {
      gsap.to(container, { autoAlpha: 1, duration: 1 })
    } else {
      gsap.to(container, { autoAlpha: 0, duration: 1 })
    }
  }, [isCookiesVisible])

  useEffect(() => {
    gsap.set(hoverLine, { xPercent: -110 })
  }, [])
  return (
    <div
      className="cookies-banner"
      style={{ boxShadow: "0px 0px 35px -25px" }}
      ref={el => (container = el)}
    >
      <div className="title">
        <Trans>Cookies</Trans>
      </div>
      <div className="text">
        <Trans>
          Au Charme Rabelaisien uses its own and third-party analytical cookies
          to analyze your preferences and improve your experience on the website
          If you want to learn more or opt out of all or some cookies,
        </Trans>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          className="link"
          onMouseLeave={() => hideHover()}
          onMouseEnter={() => showHover()}
        >
          <Link to={`/${language}/legal`}>
            <Trans>Learn more about their use.</Trans>
          </Link>
          <div
            ref={el => (hoverLine = el)}
            style={{ width: "100%", height: 1, backgroundColor: "grey" }}
          ></div>
        </div>
        <div className="accept" onClick={() => hideCookies(false)}>
          <Trans>Accept</Trans>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ isCookiesVisible, isVideoPlaying, language }) => {
  return { isCookiesVisible, isVideoPlaying, language }
}

const mapDispatchToProps = dispatch => {
  return {
    hideCookies: bool => {
      dispatch({
        type: `UPDATE_COOKIES_BANNER`,
        payload: bool,
      })
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CookiesBanner)
