import React, { useState, useRef, useEffect } from "react"
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import fr from "date-fns/locale/fr"
import en from "date-fns/locale/fr"
import ru from "date-fns/locale/ru"
import { connect } from "react-redux"

const langObj = { en: en, fr: fr, ru: ru }

let calendarLangVoc = {
  fr: "DÉPART",
  en: "CHECK-OUT",
  ru: "отъезд",
}

const EndCalendarMobile = ({
  language,
  startingDate,
  endingDate,
  updateEndingDate,
}) => {
  let container = useRef(null)
  registerLocale(language, langObj[language])

  const updateEndDate = date => {
    updateEndingDate(date)
  }

  const startingDatePlusOne = date => {
    var day = 60 * 60 * 24 * 1000
    var endDate = new Date(date.getTime() + day)
    return endDate
  }

  useEffect(() => {
    let input = container.children[0].children[0].children[0]
    input.readOnly = true
  }, [])
  const tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)

  return (
    <div className="calendar-mobile" ref={el => (container = el)}>
      <DatePicker
        selected={endingDate}
        onSelect={date => updateEndDate(date)}
        locale={language != "ru" ? language : ru}
        withPortal
        placeholderText={calendarLangVoc[language]}
        minDate={startingDate ? startingDatePlusOne(startingDate) : tomorrow}
        dateFormat={language === "en" ? "MM/dd/yyyy" : "dd/MM/yyyy"}
      />
    </div>
  )
}

const mapStateToProps = ({ language, startingDate, endingDate }) => {
  return { language, startingDate, endingDate }
}

const mapDispatchToProps = dispatch => {
  return {
    updateEndingDate: date => {
      dispatch({
        type: `UPDATE_ENDING_DATE`,
        payload: date,
      })
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EndCalendarMobile)
