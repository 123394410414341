import React, { useEffect, useRef, useState } from "react"
import gsap from "gsap"
import { connect } from "react-redux"
import { Trans } from "@lingui/macro"

export const ReservationButton = ({
  showReservationBtn,
  updateShowReservationCenter,
  scrollOffset,
  isVideoPlaying,
}) => {
  let container = useRef(null)
  let maskDiv = useRef(null)

  const [hoverMask, setHoverMask] = useState(false)

  const [offsetY, setOffsetY] = useState(0)

  const handleMaskChange = () => {
    setHoverMask(!hoverMask)
  }

  useEffect(() => {
    setOffsetY(scrollOffset)
  }, [scrollOffset])

  useEffect(() => {
    if (hoverMask) {
      gsap.to(maskDiv, { yPercent: -100 })
    } else {
      gsap.to(maskDiv, { yPercent: 0 })
    }
  }, [hoverMask])

  useEffect(() => {
    if (isVideoPlaying) {
      gsap.to(container, { autoAlpha: 0, duration: 1 })
    } else {
      gsap.to(container, { autoAlpha: 1, duration: 1 })
    }
  }, [isVideoPlaying])

  useEffect(() => {
    if (!showReservationBtn) {
      gsap.to(container, { xPercent: -100, duration: 0.3 })
    } else {
      gsap.to(container, { xPercent: 0, delay: 1 })
    }
  }, [showReservationBtn])
  return (
    <div
      className="reservation-button"
      style={{ transform: offsetY && `translateY(${offsetY})` }}
      ref={el => (container = el)}
      onClick={() => updateShowReservationCenter()}
      onMouseEnter={() => handleMaskChange()}
      onMouseLeave={() => handleMaskChange()}
    >
      <div className="overflow-div">
        <div className="greyish-background" ref={el => (maskDiv = el)}></div>
        <div className="text">
          <Trans>TARIFS / RÉSERVER</Trans>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({
  showReservationBtn,
  scrollOffset,
  isVideoPlaying,
}) => {
  return { showReservationBtn, scrollOffset, isVideoPlaying }
}

const mapDispatchToProps = dispatch => {
  return {
    updateShowReservationCenter: () =>
      dispatch({
        type: `SHOW_RESERVATION_CENTER`,
      }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReservationButton)
