import React from "react"

const LogoAnimated = () => {
  return (
    <div className="loading-banner-svg">
      <svg
        className="logo-animation"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 2473.8 1304.3"
        style={{ enableBackground: "new 0 0 2473.8 1304.3" }}
        xmlSpace="preserve"
      >
        {/* <style type="text/css">
        .st0{fill:#000;}
        .st1{fill:transparent;stroke:#000;stroke-width:5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
    </style> */}
        <g className="bg">
          <path
            className="st0"
            d="M493,772.7v-1.2h80.5l190.6-581.8l216.5,581.8h65v1.2h-268v-1.2h106.9L805.6,559H644.5l-69.8,212.5h116v1.2H493
        z M644.9,557.8h160.3l-84.9-229.7L644.9,557.8z"
          />
          <path
            className="st0"
            d="M978.9,303c51.8-71,134.8-114.4,217.7-113.3c40.3,0,77.4,6.4,111.6,18.7c28.7,11.2,82.1,26.3,86.5-18.7h1.2
        v187.8h-1.2c0-41.5,1.6-66.6-20.3-102.9c-13.6-21.9-26.3-36.7-49.4-54.6c-23.5-17.9-86.5-29.1-128.4-29.1c-63,0-102.5,30.3-134,90.9
        s-47.1,116.4-47.1,207c0,180.6,54.6,293.9,181,293.9c41.9,0,104.9-10.8,128-29.1c23.9-18.3,35.9-33.9,49.4-56.2
        c21.9-36.7,20.7-71.8,20.7-101.3h1.2v187.8h-1.2c-4.8-6.8-9.2-11.6-12.8-15.2c-6.4-6.4-19.5-13.6-36.3-13.6c-14,0-21.9,4-37.5,10
        c-34.3,12.4-71.4,18.7-111.6,18.7c-70.2,0-137.6-28.3-189-77.4c-25.9-24.7-46.6-55.8-62.2-93.7c-15.6-37.9-23.1-79-23.1-124
        C922.3,416.6,944.2,350.8,978.9,303z"
          />
          <path
            className="st0"
            d="M1340.2,772.7v-1.2h76.6V202.1h-76.6v-1.2H1581c60.6,0,108.9,17.1,135.6,44.7c26.7,27.1,39.9,59.8,39.9,95.3
        c0,69-43.1,107.7-113.3,123.6c40.7,6.4,71.8,28.3,92.9,50.2c21.5,21.9,32.3,54.2,32.3,83.3v80.5c0,71.4,16,96.5,61,96.5
        c16.4,0,37.1-6,51-10.4l0.4,1.2c-26.7,8.8-54.2,13.2-83.3,13.2c-25.9,0-49.4-5.6-73.4-19.5c-23.5-14-38.7-42.3-38.7-80.5v-93.3
        c0-29.9-5.2-71.4-21.5-91.7c-15.9-20.3-42.7-29.5-75-29.5h-89.3v307.1h76.6v1.2H1340.2z M1499.7,463.3h102.1
        c28.7,0,48.2-11.6,58.2-32.3c10-21.1,13.6-44.3,13.6-82.9c0-36.3-7.2-69.4-21.9-98.5c-14.4-29.1-41.9-47.5-77-47.5h-75V463.3z"
          />
        </g>
        <g className="sub_one">
          <path
            className="st1"
            d="M493,772.7v-1.2h80.5l190.6-581.8l216.5,581.8h65v1.2h-268v-1.2h106.9L805.6,559H644.5l-69.8,212.5h116v1.2H493
        z M644.9,557.8h160.3l-84.9-229.7L644.9,557.8z"
          />
          <path
            className="st1"
            d="M978.9,303c51.8-71,134.8-114.4,217.7-113.3c40.3,0,77.4,6.4,111.6,18.7c28.7,11.2,82.1,26.3,86.5-18.7h1.2
        v187.8h-1.2c0-41.5,1.6-66.6-20.3-102.9c-13.6-21.9-26.3-36.7-49.4-54.6c-23.5-17.9-86.5-29.1-128.4-29.1c-63,0-102.5,30.3-134,90.9
        s-47.1,116.4-47.1,207c0,180.6,54.6,293.9,181,293.9c41.9,0,104.9-10.8,128-29.1c23.9-18.3,35.9-33.9,49.4-56.2
        c21.9-36.7,20.7-71.8,20.7-101.3h1.2v187.8h-1.2c-4.8-6.8-9.2-11.6-12.8-15.2c-6.4-6.4-19.5-13.6-36.3-13.6c-14,0-21.9,4-37.5,10
        c-34.3,12.4-71.4,18.7-111.6,18.7c-70.2,0-137.6-28.3-189-77.4c-25.9-24.7-46.6-55.8-62.2-93.7c-15.6-37.9-23.1-79-23.1-124
        C922.3,416.6,944.2,350.8,978.9,303z"
          />
          <path
            className="st1"
            d="M1340.2,772.7v-1.2h76.6V202.1h-76.6v-1.2H1581c60.6,0,108.9,17.1,135.6,44.7c26.7,27.1,39.9,59.8,39.9,95.3
        c0,69-43.1,107.7-113.3,123.6c40.7,6.4,71.8,28.3,92.9,50.2c21.5,21.9,32.3,54.2,32.3,83.3v80.5c0,71.4,16,96.5,61,96.5
        c16.4,0,37.1-6,51-10.4l0.4,1.2c-26.7,8.8-54.2,13.2-83.3,13.2c-25.9,0-49.4-5.6-73.4-19.5c-23.5-14-38.7-42.3-38.7-80.5v-93.3
        c0-29.9-5.2-71.4-21.5-91.7c-15.9-20.3-42.7-29.5-75-29.5h-89.3v307.1h76.6v1.2H1340.2z M1499.7,463.3h102.1
        c28.7,0,48.2-11.6,58.2-32.3c10-21.1,13.6-44.3,13.6-82.9c0-36.3-7.2-69.4-21.9-98.5c-14.4-29.1-41.9-47.5-77-47.5h-75V463.3z"
          />
        </g>
        <g className="sub_two">
          <path
            className="st1"
            d="M400,972.8c-2.1-1.5-10.7-2.7-25.6-3.4c9.2-63.3,13.8-105.1,13.8-125.4v-0.2c0-3.4-0.2-6.3-0.5-8.7
            c-0.7-7.7-3.5-11.6-8.5-11.6h-1c-9.3,0-24.2,14.9-44.7,44.7c-22.6,35.1-42,69.4-58.2,102.8c0,1.4-1.3,2.2-3.9,2.4
            c-22.7,0.8-34.1,1.8-34.1,3.2v0.9c0,5.2,5.1,7.7,15.2,7.7l16.1-1.3h0.2c-1.2,4.3-2.6,8.5-4.2,12.5c-16.3,43.8-24.4,72.3-24.4,85.7
            c0.2,3.7,0.6,5.7,1.1,5.8h1.3c1.1,0,1.9-0.2,2.4-0.5c1.5-1.1,2.3-2,2.3-3c0-1.8,2.5-11.7,7.6-29.6c7.1-23.8,15.4-47.7,24.8-71.4
            c0-0.6,3.1-1.4,9.2-2.4c30.6-3,56-4.6,76.2-4.6c-3.3,33.9-5,58-5,72.1v1.1c0,10.9,1.2,17.7,3.6,20.3c2.7,0,4.3-2.5,4.9-7.4
            c-0.2-1.1-0.5-1.7-1.1-1.7c-1.3-0.5-1.9-2.5-1.9-5.8c0-6.4,2.5-32.5,7.4-78.4c0-0.5,2.6-0.7,7.7-0.8c2.9,0,6,0.2,9.2,0.6l11.4,2.2
            h0.7c0.8,0,1.7-0.3,2.9-1c0-0.6-0.2-0.9-0.5-0.9C404.4,975.2,403,973.9,400,972.8z M364.9,968.5c-3.6,0.5-30.1,2-79.5,4.3h-0.2
            c0-0.3,0.2-0.8,0.5-1.5c6.6-16.8,18.8-40,36.5-69.5c28.3-46.2,47.3-69.3,57.1-69.3h0.2l0.2,1.7c0,6.6-1.1,21-3.3,43.2
            C371.5,924.7,367.7,955,364.9,968.5z"
          />
          <path
            className="st1"
            d="M510.6,948.8c-2.4,2.4-5,4.8-7.9,7.4c-11.1,9.3-20.1,14.1-26.9,14.6h-0.6c-1.7,0.2-3.4,0-5.1-0.6
            c-4.8-2-7.5-7.2-8.1-15.4c0-0.7-0.1-2.2-0.2-4.7c-0.5-0.9-1.3-1.3-2.3-1.3h-0.5c-0.8,0.1-1.6,0.5-2.3,1.1
            c-4.3,5.7-8.1,10.5-11.4,14.4c-6.1,6.2-10.1,9.4-12.2,9.6c-1,0.1-1.5-0.3-1.5-1.3l-0.1-0.9c-0.2-2.4,1.9-8.8,6.3-19
            c3.5-7.8,5.2-12.7,5.1-14.5l-0.1-0.1c0-0.9-1-1.4-2.9-1.4c-1.3,0.1-2.6,0.6-3.9,1.5c-1.4,1.7-2.7,3.8-3.9,6.3
            c-6.1,12.9-8.9,22.7-8.4,29.3v1c0.1,0.6,0.2,1.3,0.3,2.2c1.3,3.2,3.4,4.7,6.3,4.6c3.7-0.3,9.3-4.7,16.8-13.1
            c6.8-8.3,10.3-12.4,10.5-12.4c0.1,1.2,0.1,2,0.1,2.3c0.2,3,0.8,5.6,1.6,7.6c2.5,5.4,6.6,7.9,12.4,7.5l1.6-0.1
            c7.5-0.5,16.8-4.9,27.9-13.2c3.2-2.3,6.8-5.8,10.7-10.6c-0.2-0.7-0.5-1-0.9-1L510.6,948.8z"
          />
          <path
            className="st1"
            d="M769.7,974.6c-1.6,0.2-4.7,3.1-9.3,8.9c-14.5,15-29.7,28.6-45.5,40.9c-46.8,36-81.4,55.4-103.7,58.1
            c-4.2,0.5-8.5,0.2-12.9-0.8c-11.9-4-18.6-13.7-20.2-29.2l-0.3-3.3c-1.6-15.7,2-37.2,10.8-64.7c3.8-11.2,7.8-21.2,12-30.2
            c6.5-15.7,16.8-34.4,31.1-56.1c8.6-12.2,14.8-20.2,18.6-24.2c20.1-21.9,34.4-33.3,43.1-34.4l2.1-0.2h1.4c4.4,1.4,6.9,5,7.4,10.6
            l0.2,1.7c0.5,4.5-0.3,11.1-2.3,19.9c-3.8,15.3-5.4,25.3-4.9,29.8v0.6c0.8,4.2,2.6,6.1,5.4,5.7c3-0.3,6.3-4.4,9.9-12.4
            c5.7-16.9,7.8-32.4,6.4-46.3c-0.8-7.3-2.7-13.2-5.8-17.8c-4.9-5.6-10.2-8.1-15.7-7.4l-3.1,0.3c-11.2,1.4-25.9,11.7-44,31
            c-12.5,14.2-24.2,30-35.2,47.3c-9.7,16.1-17.2,30.6-22.6,43.5c-17.9,44.1-25.6,77.6-23.2,100.3l0.6,5c1.1,10.9,5,20.2,11.5,27.7
            c8.3,7.6,17.5,10.8,27.6,9.6l2.6-0.3c15.7-1.9,39.5-13.5,71.3-34.9c21.6-15.3,40.4-30.2,56.5-44.7c9.4-8.4,18.1-17.2,26-26.4
            c3.3-3.7,4.9-6,4.8-6.7l-0.1-0.5L769.7,974.6z"
          />
          <path
            className="st1"
            d="M837.5,989.9c-2-3.5-4.1-13.9-6.3-31.3c-1.7-17.5-4.5-26.2-8.2-26.3c-3.6-0.2-8.5,4.3-14.7,13.3
            c-7.1,11.8-11.5,18.9-13.1,21.3H795v-0.1c0.5-3.3,0.7-5.4,0.8-6.3c2.4-18.1,4.5-31.9,6.5-41.6c3.3-18.2,6.9-32.5,10.7-43.2
            c-0.3-2.5-1.4-3.8-3.2-3.8l-0.9-0.1c-1,0-2,0.6-3.2,1.7c-2.4,2.4-5.5,12.9-9.3,31.3c-5.9,30.6-9,52.8-9.3,66.7l-0.1,3.6
            c-0.1,4.3,0.3,7.4,1,9.4c0,0.4,0.9,0.6,2.6,0.7h0.2c0.5,0,1-0.2,1.6-0.6c1.7-2.1,3.5-4.9,5.5-8.5c4.6-10.2,8.2-17.7,10.8-22.6
            c5.9-10.2,10.2-15.2,12.9-15.2c0.5,0.2,1,0.5,1.4,1.1c0.7,3.2,2.3,12,4.9,26.5c4.2,20.6,8.7,30.9,13.3,31l0.7,0.1
            c0.8,0,1.7-0.4,2.5-1.1c0-0.2-0.8-0.6-2.4-1.4C840.5,994.7,839.1,993.1,837.5,989.9z"
          />
          <path
            className="st1"
            d="M970.5,945.3l2.6-0.1c9.4,0.5,15.3,0.7,17.5,0.5c0.8-0.1,1.9-0.3,3.3-0.6c6.4-2,9.5-3.4,9.5-4.2l-0.1-0.7
            c0-0.2-0.2-0.3-0.7-0.5c-3.5,1.8-6.5,2.9-8.9,3.1l-1.1,0.2l-0.2,0.1c-0.1-0.1-0.2-0.1-0.2-0.1l-0.8,0.1c-2,0.2-3.8,0.2-5.1-0.1
            c-8.9-1.2-14.6-1.7-17.1-1.4c-5.9,0.7-11.4,5.1-16.4,13.2v-1.3c1.1-9.7,1.6-15.3,1.5-16.9c-0.2-1.1-0.6-1.9-1.3-2.2l-0.7,0.1
            c-1.7,0.2-3.1,2.5-4.4,7c-0.6,2.7-1.1,5.3-1.6,7.7c-0.1,0-0.1-0.1-0.1-0.1h-0.3c-0.3,0-1,0.5-2.2,1.6c-2.4,2.2-4.6,4-6.5,5.5
            c-9.6,7.4-18.2,11.1-25.5,11.1h-0.2c-6.2,0-12-2.5-17.4-7.4c-0.8-0.6-2-1.9-3.9-3.8c-1.3-1.5-2.5-3-3.8-4.4
            c-0.8-1.3-1.6-1.9-2.3-1.9h-0.1c-0.5,0-1,0.2-1.6,0.6c-0.5,0.3-1.5,1.2-3.1,2.7c-2.7,2.7-4.9,5-6.7,7c-2.6,2.7-4.9,5-6.9,7
            c-2.1,1.9-3.7,3.1-4.7,3.6c0.2-0.5,0.3-0.9,0.3-1c0.8-1.8,1.9-3.8,3.2-5.9c1.2-2,2.7-4,4.6-6.2c0.8-1.1,2.2-2.5,4.1-4.2
            c3-2.4,5.8-4.1,8.5-5c0.9-0.3,2.1-0.6,3.6-0.8c0.8-0.2,1.5-0.5,2.2-0.8c1.7-0.8,2.6-1.7,2.6-2.7v-0.2c0-0.9-0.6-1.8-1.9-2.7
            c-1.7-1-3.6-1.5-5.7-1.5h-0.6c-2,0-4.2,0.5-6.6,1.6c-1.4,0.6-2.7,1.3-3.9,2c-1,0.6-2.4,1.7-4.2,3.3c-0.5,0.4-1.3,1.2-2.5,2.5
            c-1.7,1.7-3,3.3-4,4.7c-1.7,2.4-3,4.6-4.1,6.6c-2.4,4.9-3.6,9.2-3.6,12.9v0.6c0,0.8,0.1,1.5,0.3,2.1c0.5,1.4,1.5,2,2.9,2h0.3
            c0.5,0,1.1-0.1,1.8-0.3c1.1-0.4,1.9-0.8,2.4-1.1c0.6-0.3,1.6-1,3-2.2c0.8-0.7,2.9-2.6,6.2-5.7c4.9-5.2,8.2-8.6,9.9-10.4
            c2-2,3.4-3.2,4.1-3.8l1.4,1.6c0.8,1,2.1,2.3,3.9,3.9c7,6.5,14.3,9.8,22,9.8c6.8,0,13.9-2.5,21.5-7.4c2-1.4,4.7-3.4,8.1-6.2
            c1.4-1.2,3.2-2.8,5.2-4.8c-2.3,12-3.2,20.3-2.7,24.8c0.5,3,1.5,4.4,3,4.2h0.1c1.2-0.2,2.2-1.4,3.1-3.8c1.4-6.8,3.4-12.3,5.9-16.4
            C959.3,951,964.4,946,970.5,945.3z"
          />
          <path
            className="st1"
            d="M1144.8,955.1c-2.5,4.4-3.9,8.2-3.9,11.4v2.4l0.2,1.9c2.1,5.1,6.4,7.6,12.9,7.6h3.5c6.5,0,16.1-2.4,28.7-7.1
            c10.2-4,21-9.3,32.6-15.8c5.4-3.3,8.7-5.7,9.9-7.2v-0.7c0-0.2-0.3-0.5-0.8-0.7h-0.2c-1,0-4.5,2.1-10.6,6.3
            c-26.6,14-46.4,21-59.5,21c-1.1,0-2.2,0-3.2-0.1c-5.5-0.9-8.2-3.2-8.2-6.8c0-2,0.9-4.9,2.7-8.7c4.1-0.8,7.1-1.5,8.9-2.3
            c6.2-3.3,9.2-7.1,9.2-11.4c-0.4-4.1-1.9-6.2-4.6-6.2h-1.1c-2.4,0-5.2,1.5-8.3,4.6c-1.7,2-3.1,3.9-4.5,5.8
            c-17.2,12.7-30.5,19-40.1,19c-7.5,0-13.6-4.7-18.3-14c-3.6-7.8-6.9-11.7-10-11.7c-3.5,0-8.8,4.7-15.9,14c2-18.8,3.3-32.2,3.8-40
            c0-5.5-1-8.2-3-8.2h-0.8c-0.5,0-1.3,0.4-2.5,1.1c-4,4.1-8.1,9.5-12.3,16.3c-8.1,12.8-14.6,24.2-19.5,34.2h-0.2v-0.2
            c1.1-4.3,1.7-7.1,1.7-8.3v-0.9c0-1.6-1-2.6-3.1-3.1h-0.3c-1.6,0-3.3,1.5-5.1,4.3c-4,10-6,19.9-6,29.7v3.4c0,3.9,1.3,5.9,3.8,5.9
            h1.4c1.7,0,3.4-1.7,4.9-5.1c1.5-5.9,3.6-11.5,6.3-16.9c5-11,11.5-22.9,19.4-35.8c3.6-5.9,6.9-10.2,9.8-13.1
            c-2.4,19.3-3.5,31-3.5,35.2c0,2.1,0.2,4.1,0.6,5.9c0,2,1.1,3,3.4,3c1.1,0,1.9-0.6,2.4-1.7c8.4-9.9,13.4-15,15-15.3l0.7,0.9
            c8.6,15.6,17.5,23.4,26.8,23.4c8.7,0,19.4-4.4,32.4-13.1C1141.8,957.1,1143.3,956.1,1144.8,955.1z"
          />
          <path
            className="st1"
            d="M1368.3,880.9h-1.6c-2.8,0-5.4,1.8-7.9,5.5c-8.9,20.5-17.2,49.8-24.8,87.7c-0.5,3.8-1,7.7-1.6,11.5
            c-0.5,3.6-1,7.3-1.4,10.9c-0.4,3.5-0.9,6.9-1.3,10.4c-0.4,3.3-0.8,6.5-1.2,9.8c-0.4,3.1-0.7,6.1-1.1,9.2c-0.3,2.9-0.6,5.8-0.9,8.7
            c-0.3,2.7-0.6,5.4-0.8,8.1c-0.2,2.5-0.5,5-0.7,7.5c-0.2,2.3-0.4,4.6-0.6,6.9c-0.3,3.2-0.5,6.4-0.7,9.6c-0.1,1,0,2-0.1,3
            c0,1-0.2,2-0.2,2.9c-0.1,1.8-0.1,3.6-0.2,5.4c0,0.7,0,1.4,0,2.1v3.2c0,2.5,0.2,4.2,0.6,5.1c3.9-0.6,5.8-2.2,5.8-4.8v-1.3
            c0-8,2.1-23.4,6.3-46.3c5.9-27.2,12.9-61,21-101.5c8.1-30,12.1-47.6,12.1-52.9v-0.3C1369,881.2,1368.7,880.9,1368.3,880.9zM1451,1025.1c-46.7-14.7-75.5-25.2-86.5-31.7v-1.6c2.7-2.7,10.6-8.3,23.5-16.8c17.1-9.9,34.8-21.9,53.2-36.2
            c37.6-30.2,56.4-55.3,56.4-75.1c0-5.5-1.4-10.8-4.1-15.8c-10-15.7-25.7-23.5-47-23.5h-4.7c-13.9,0-30.3,3-49.2,8.9
            c-16.8,5.9-30,12-39.6,18.1c-11.6,5.8-26.1,16-43.4,30.7c-16.3,15.2-24.4,26.2-24.4,33v2.8c0,1.1,0.4,2.4,1.3,4.1
            c1.6,0,3.8-3,6.7-8.9c6.8-9.5,16.2-19,28.1-28.5c45.2-32.1,85-48.2,119.5-48.2c6.3,0,13.9,1.1,22.9,3.2
            c13.9,4.1,20.8,10.8,20.8,19.9v1.6c0,16.9-17.1,40-51.4,69.4c-16.1,12.8-34.1,25.8-53.9,38.7c-17.5,10.9-26.3,19.1-26.3,24.4
            c0,5.2,7.7,10.6,23.2,16.2c85.4,27.4,133.8,41.1,145.2,41.1c0.4,0,1.2-0.2,2.5-0.6c-0.2-2.6-0.6-4.2-1.3-4.8
            C1503.9,1040.9,1480,1034,1451,1025.1z"
          />
          <path
            className="st1"
            d="M1919.4,946.9c-0.3,0.3-0.5,1.4-0.8,3.2c-0.3,0.2-0.6,0.5-0.9,0.8c-2.4,2.2-4.6,4-6.5,5.5
            c-9.6,7.4-18.2,11.1-25.5,11.1h-0.2c-6.2,0-12-2.5-17.4-7.4c-0.8-0.6-2-1.9-3.9-3.8c-1.3-1.5-2.6-3-3.8-4.4
            c-0.8-1.3-1.6-1.9-2.3-1.9h-0.1c-0.5,0-1,0.2-1.6,0.6c-0.5,0.3-1.5,1.2-3.1,2.7c-2.7,2.7-4.9,5-6.7,7c-2.6,2.7-4.9,5-7,7
            c-2.1,1.9-3.7,3.1-4.7,3.6c0.2-0.5,0.3-0.9,0.3-1c0.8-1.8,1.9-3.8,3.2-5.9c1.2-2,2.7-4,4.6-6.2c0.8-1.1,2.2-2.5,4.1-4.2
            c3-2.4,5.8-4.1,8.5-5c0.9-0.3,2.1-0.6,3.6-0.8c0.8-0.2,1.5-0.5,2.2-0.8c1.7-0.8,2.6-1.7,2.6-2.7v-0.2c0-0.9-0.6-1.8-1.9-2.7
            c-1.7-1-3.6-1.5-5.7-1.5h-0.6c-2,0-4.2,0.5-6.6,1.6c-1.4,0.6-2.7,1.3-3.9,2c-1,0.6-2.4,1.7-4.2,3.3c-0.5,0.4-1.3,1.2-2.5,2.5
            c-1.7,1.7-3,3.3-4,4.7c-1.7,2.4-3,4.6-4.1,6.6c-0.1,0.3-0.2,0.5-0.3,0.8c-26.2,34.1-46.4,51.3-60.2,51.3c-1.2,0-2.9-0.3-5.1-0.9
            c-8.4-4.3-12.5-16.3-12.5-36.1c0-25,6.5-55.1,19.5-90.2c5.2-9.9,7.7-16.1,7.7-18.8V866c0-1.4-0.7-2-2.1-2h-0.9
            c-3.1,0-7.3,6.4-12.5,19.1c-8.8,25.2-14.4,46.6-17,64.3c-1.7,0.8-4.8,2.7-9.5,5.9c-26.6,14-46.4,21-59.5,21c-1.1,0-2.2,0-3.2-0.1
            c-5.5-0.9-8.2-3.2-8.2-6.8c0-2,0.9-4.9,2.7-8.7c4.1-0.8,7.1-1.5,8.9-2.3c6.2-3.3,9.2-7.1,9.2-11.4c-0.4-4.1-1.9-6.2-4.6-6.2h-1.1
            c-2.4,0-5.2,1.5-8.3,4.6c-1.2,1.5-2.2,2.8-3.2,4.2c-8.2,1.1-20.5,4.6-37.3,10.7c-2.1-14-8.5-21-19.1-21c-11.5,0-24.9,6.5-40,19.5
            c0-3.3,2.7-13.9,8-31.8c7.4-22,12.6-32.9,15.4-32.9l2.2,0.3c0.5,0,0.7-0.2,0.7-0.7v-2.4c0-2-1.3-3.2-3.8-3.8h-0.9
            c-5.8,0-12.1,8.9-19,26.8c-4.7,16.1-8.1,28.7-10.2,38c-0.2,0.2-0.4,0.3-0.6,0.5c-2.4,2.2-4.6,4-6.5,5.5
            c-9.6,7.4-18.2,11.1-25.5,11.1h-0.2c-6.2,0-12-2.5-17.4-7.4c-0.8-0.6-2-1.9-3.9-3.8c-1.3-1.5-2.6-3-3.8-4.4
            c-0.8-1.3-1.6-1.9-2.3-1.9h-0.1c-0.5,0-1,0.2-1.6,0.6c-0.5,0.3-1.5,1.2-3.1,2.7c-2.7,2.7-4.9,5-6.7,7c-2.6,2.7-4.9,5-7,7
            c-2.1,1.9-3.7,3.1-4.7,3.6c0.2-0.5,0.3-0.9,0.3-1c0.8-1.8,1.9-3.8,3.2-5.9c1.2-2,2.7-4,4.6-6.2c0.8-1.1,2.2-2.5,4.1-4.2
            c3-2.4,5.8-4.1,8.5-5c0.9-0.3,2.1-0.6,3.6-0.8c0.8-0.2,1.5-0.5,2.2-0.8c1.7-0.8,2.6-1.7,2.6-2.7v-0.2c0-0.9-0.6-1.8-1.9-2.7
            c-1.7-1-3.6-1.5-5.7-1.5h-0.6c-2,0-4.2,0.5-6.6,1.6c-1.4,0.6-2.7,1.3-3.9,2c-1,0.6-2.4,1.7-4.2,3.3c-0.5,0.4-1.3,1.2-2.5,2.5
            c-1.7,1.7-3,3.3-4,4.7c-1.7,2.4-3,4.6-4.1,6.6c-2.4,4.9-3.7,9.2-3.7,12.9v0.6c0,0.8,0.1,1.5,0.3,2.1c0.5,1.4,1.5,2,2.9,2h0.3
            c0.5,0,1.1-0.1,1.8-0.3c1.1-0.4,1.9-0.8,2.4-1.1c0.6-0.3,1.6-1,3-2.2c0.8-0.7,2.9-2.6,6.1-5.7c4.9-5.2,8.2-8.6,9.9-10.4
            c2.1-2,3.4-3.2,4.1-3.8l1.4,1.6c0.8,1,2.1,2.3,3.9,3.9c7,6.5,14.3,9.8,22,9.8c6.8,0,13.9-2.5,21.5-7.4c2.1-1.4,4.8-3.4,8.1-6.2
            c0.8-0.7,1.8-1.6,2.8-2.5c-1.3,6.2-2.1,10.7-2.1,13.4v1.8c0.5,0.9,1.3,1.5,2.2,1.8c0.8,0,1.8-0.4,3.1-1.3
            c16.7-17.9,30.5-26.8,41.3-26.8c1.8,0,3.6,0.2,5.2,0.7c8.7,3.2,13,8.2,13,15v2.2l-7.7,3.1c-13.7,6.4-25.7,14.1-36,23.1
            c-9.9,10-14.8,18.4-14.8,25.2c0,0.8,0.2,2.2,0.6,4.1c2.2,4.5,5.9,6.7,11.2,6.7h1.5c2.6,0,5.5-0.4,8.7-1.3
            c13.4-4.9,23.3-13.8,29.5-26.4c3-3.8,6.5-13.1,10.5-27.8c0.6-2.1,1.3-4.2,1.9-6.3c17.3-6.6,29.1-10.4,35.6-11.5
            c-5,6.9-7.5,12.6-7.5,17.1v2.4l0.2,1.9c2.1,5.1,6.4,7.6,12.9,7.6h3.5c6.5,0,16.1-2.4,28.7-7.1c10.2-4,21-9.3,32.6-15.8
            c3.2-2,5.5-3.6,7.3-4.9c-0.7,5.9-1.1,11.4-1.1,16.4v6.5c0,6.1,0.9,13.4,2.7,21.9c4.2,15.1,10.6,22.7,19,22.7h3.3
            c7.9,0,19-6.4,33.1-19.1c9.1-9.4,16.8-17.9,23.1-25.7v0.5c0,0.8,0.1,1.5,0.3,2.1c0.5,1.4,1.5,2,2.9,2h0.3c0.5,0,1.1-0.1,1.8-0.3
            c1.1-0.4,1.9-0.8,2.4-1.1c0.6-0.3,1.6-1,3-2.2c0.8-0.7,2.9-2.6,6.1-5.7c4.9-5.2,8.2-8.6,9.9-10.4c2.1-2,3.4-3.2,4.1-3.8l1.4,1.6
            c0.8,1,2.1,2.3,3.9,3.9c7,6.5,14.3,9.8,22,9.8c6.8,0,13.9-2.5,21.5-7.4c2.1-1.4,4.8-3.4,8.1-6.2c1-0.9,2.3-2,3.6-3.2
            c-0.3,3.8-0.6,9.3-0.9,16.7c0,0.6,0,0.9,0.1,1c0,4.6,0.7,7.2,2,7.9h0.7c1,0,2-1.3,3.1-4c1.8-8.5,2.7-14.8,2.7-19V954
            c0-4.2-0.3-8.1-0.8-11.5C1922.5,942.5,1920.5,944,1919.4,946.9z M1614.7,998.3c-10.3,13.6-20.6,20.4-31.1,20.4h-0.7
            c-5.5,0-8.5-2.5-9.2-7.4v-1.3c0-5.8,4.7-13.1,14.2-21.9c13.4-10.7,26.8-19.1,40.3-25.2h0.2C1625.8,977.7,1621.2,989.4,1614.7,998.3
            zM1920.7,927.3c0,0.8,0.2,1.6,0.5,2.6c0,0.5,0.6,0.8,1.8,0.8h0.1c2.4,0,3.8-1.8,4.3-5.5c-2-1-3.1-1.5-3.4-1.5
            C1921.8,925,1920.7,926.1,1920.7,927.3z"
          />
          <path
            className="st1"
            d="M2032.9,927.3c0,0.8,0.2,1.6,0.5,2.6c0,0.5,0.6,0.8,1.8,0.8h0.1c2.4,0,3.8-1.8,4.3-5.5c-2-1-3.1-1.5-3.4-1.5
            C2034,925,2032.9,926.1,2032.9,927.3zM2031.6,946.9c-0.1,0.1-0.2,0.5-0.4,0.9c-7.2,3.5-18.4,9.6-33.5,18.6c-3.8,2.7-5.8,4-5.9,4.1
            c-1.7-3.2-3.2-5.7-4.7-7.5c-1.8-2.4-3.7-3.8-5.7-4.4c-4.9-1.2-9.2-2.8-12.9-4.8c0.2-1.5,2.3-4.6,6.3-9.1c3-3.2,6.3-6.4,10-9.6
            c3.7-2.9,5.4-4.9,5.4-6.2c-0.4-1.7-1.6-2.5-3.8-2.4l-1.4,0.1c-2.9,0.2-8.1,4-15.6,11.6c-6.6,7-9.8,12.6-9.6,16.9
            c0.1,1,0.5,2.1,1.1,3.4c1.1,1.8,4.2,3.4,9.3,4.8c7.1,3,11.2,5.4,12.5,7.3c1.3,1.9,1.6,3.6,1.1,5c-3.9,3.9-9.3,8.4-16.1,13.4
            c-15.1,11.5-22.9,19.6-23.4,24.3l0.1,1c1,2.2,2.6,3.3,4.9,3.2l0.6-0.1c3.6-0.2,8.1-2.4,13.7-6.7c3.2-2,7.6-6.2,13.3-12.4
            c6.5-8.2,10.8-14.5,12.7-18.8c1.5-3.7,2.4-5.7,2.7-5.9c7.7-6.1,19.9-13.5,36.3-22.3c0.7-0.3,1.3-0.5,1.9-0.8
            c-0.5,3.6-0.8,10-1.1,19.1c0,0.6,0,0.9,0.1,1c0,4.6,0.7,7.2,2,7.9h0.7c1,0,2-1.3,3.1-4c1.8-8.5,2.7-14.8,2.7-19V954
            c0-4.2-0.3-8.1-0.8-11.5C2034.7,942.5,2032.8,944,2031.6,946.9z M1970.6,995.6c-8.4,9.3-15.7,14.9-21.7,16.6c0-0.2,0.1-0.6,0.2-1
            c1.9-3.7,5.9-8.1,11.9-13.4c9.9-8.4,15.9-13.1,18-14.4h0.1C1978.1,986,1975.3,990,1970.6,995.6z"
          />
          <path
            className="st1"
            d="M2236.2,947.7l-2,0.6l-6.4,4.8c-16.3,10.2-28.4,15.5-36.3,16h-1.1c-1.3,0-2.4-0.3-3.2-0.9
            c-2.4-1.2-3.5-6-3.5-14.5c0-6.1-1.1-9.1-3.2-9.1h-1.1c-4.6,0.6-11.6,7.6-21,21c0-4.1,2.1-14.6,6.3-31.6c0-1.6-1.1-2.6-3.3-3h-0.3
            c-0.4,0-0.9,0.1-1.7,0.4c-1.2,0-2.7,3.6-4.6,10.9c-0.4,1.8-0.7,3.4-1.1,5c-1.5,0.6-4.7,2.5-9.8,6c-26.6,14-46.4,21-59.5,21
            c-1.1,0-2.2,0-3.2-0.1c-5.5-0.9-8.2-3.2-8.2-6.8c0-2,0.9-4.9,2.7-8.7c4.1-0.8,7.1-1.5,8.9-2.3c6.2-3.3,9.2-7.1,9.2-11.4
            c-0.4-4.1-1.9-6.2-4.6-6.2h-1.1c-2.4,0-5.2,1.5-8.3,4.6c-8.1,9.7-12.2,17.4-12.2,23.1v2.4l0.2,1.9c2.1,5.1,6.4,7.6,12.9,7.6h3.5
            c6.5,0,16.1-2.4,28.7-7.1c10.2-4,21-9.3,32.6-15.8c3.2-2,5.6-3.6,7.4-4.9c-2.2,11.1-3.4,19.8-3.5,26.1c0.6,3.5,2.1,5.2,4.3,5.2
            c1.7,0,2.8-0.8,3.5-2.5c2.4-5.5,5.9-11.5,10.5-18.1c3.3-4.9,6.9-8.6,10.7-11.3c0,0.8,0,2.3-0.1,4.7c0,0.6,0,2,0.1,4.3
            c1.1,8.9,4.4,13.3,10.1,13.3h1.7c3.9,0,9.6-1.6,17.1-4.7c7.3-3.5,13.8-7.3,19.6-11.3c6.9-4.4,10.4-6.9,10.4-7.5
            `     C2237.3,948.2,2236.9,947.7,2236.2,947.7z"
          />
        </g>
        <g className="sub_three">
          <g>
            <path
              className="st0"
              d="M980,1054.1c-2.3,6-4.5,11.8-6.8,17.6c-2.2,5.7-4.4,11.5-6.6,17.1c-2.2,5.7-4.4,11.4-6.6,17.1
                    c-2.2,5.7-4.5,11.6-6.8,17.6h5.4c1.3-3.5,2.7-7.3,4.1-11.2c1.4-3.9,2.8-7.6,4-11.1h34.2l8.5,22.3h6.1l-28.3-69.5H980z
                    M968.1,1097.3l15.3-38.6l16.1,38.6H968.1z"
            />
          </g>
          <g>
            <polygon
              className="st0"
              points="1058.2,1117.2 1032.9,1054.2 1025.6,1054.2 1025.6,1123.6 1030.7,1123.6 1030.7,1062.5 1055.6,1123.6 
                    1060.9,1123.6 1085.9,1062.5 1085.9,1123.6 1091,1123.6 1091,1054.2 1083.7,1054.2 	"
            />
          </g>
          <g>
            <path
              className="st0"
              d="M1156.2,1093.2c-1.1-1.6-2.6-3-4.6-4.1c-2-1.1-4.6-1.8-7.8-2.1c2.3-0.5,4.2-1.3,5.7-2.5c1.5-1.2,2.6-2.5,3.5-4
                    s1.4-3.1,1.7-4.8c0.3-1.7,0.5-3.4,0.5-5c0-5.8-1.8-10-5.5-12.7c-3.7-2.6-9-4-16-4h-25.1v69.4h25.2c8.2,0,14.5-1.7,18.7-5
                    c4.2-3.3,6.4-8.2,6.4-14.5c0-1.8-0.2-3.7-0.5-5.6C1158,1096.6,1157.3,1094.8,1156.2,1093.2z M1113.6,1059.2l19.6-0.1
                    c6,0,10.3,1,13,3.1c2.7,2,4.1,5.2,4.1,9.4c0,4.7-1.5,8-4.4,10c-2.9,2-7.5,3-13.6,3h-18.6V1059.2z M1133.7,1118.5h-20.1v-28.8h18.7
                    c4.1,0,7.4,0.3,10.1,0.9c2.7,0.6,4.8,1.4,6.5,2.6c1.6,1.2,2.8,2.7,3.5,4.5c0.7,1.8,1,4,1,6.5
                    C1153.4,1113.7,1146.8,1118.5,1133.7,1118.5z"
            />
          </g>
          <g>
            <path
              className="st0"
              d="M1227.1,1062.7c-2.9-3.1-6.4-5.5-10.4-7.2c-4-1.7-8.4-2.5-13.1-2.5c-4.8,0-9.2,0.8-13.2,2.5
                    c-4,1.7-7.5,4.1-10.4,7.2c-2.9,3.1-5.2,6.9-6.8,11.2c-1.6,4.4-2.4,9.3-2.4,14.8c0,5.4,0.8,10.3,2.4,14.8c1.6,4.4,3.9,8.2,6.7,11.4
                    c2.9,3.2,6.4,5.6,10.4,7.4c4,1.8,8.4,2.6,13.2,2.6c4.8,0,9.2-0.9,13.2-2.6c4-1.8,7.4-4.2,10.3-7.4c2.9-3.2,5.1-7,6.8-11.4
                    c1.6-4.4,2.4-9.3,2.4-14.7c0-5.4-0.8-10.3-2.4-14.7C1232.2,1069.5,1230,1065.8,1227.1,1062.7z M1229,1101.9
                    c-1.4,3.9-3.3,7.1-5.7,9.8c-2.5,2.7-5.4,4.7-8.7,6.1c-3.4,1.4-7,2.1-11,2.1c-4,0-7.6-0.7-11-2.1c-3.4-1.4-6.3-3.4-8.7-6.1
                    c-2.4-2.7-4.3-5.9-5.7-9.8s-2-8.3-2-13.2c0-4.9,0.7-9.3,2.1-13.1c1.4-3.8,3.3-7,5.7-9.6c2.4-2.6,5.4-4.6,8.7-5.9
                    c3.4-1.3,7-2,11.1-2c3.9,0,7.6,0.7,10.9,2c3.3,1.3,6.2,3.3,8.6,5.9c2.4,2.6,4.3,5.8,5.7,9.6c1.4,3.8,2.1,8.2,2.1,13.2
                    C1231,1093.6,1230.3,1098,1229,1101.9z"
            />
          </g>
          <g>
            <rect
              x="1253.1"
              y="1054.2"
              className="st0"
              width="5.1"
              height="69.4"
            />
          </g>
          <g>
            <path
              className="st0"
              d="M1318.8,1057.7c-2.2-1.6-4.8-2.8-7.8-3.6c-3-0.8-6.2-1.2-9.8-1.2c-3.6,0-6.8,0.4-9.7,1.3
                    c-2.9,0.9-5.5,2.1-7.6,3.7c-2.1,1.6-3.7,3.5-4.8,5.8s-1.7,4.7-1.7,7.4c0,2.5,0.4,4.7,1.3,6.6c0.8,1.9,2.2,3.6,3.9,5
                    c1.8,1.5,4,2.7,6.7,3.8c2.7,1.1,6,2.1,9.8,2.9c4.6,1,8.5,1.9,11.6,2.9c3.1,0.9,5.6,2,7.5,3.2c1.9,1.2,3.3,2.6,4.1,4.2
                    c0.8,1.6,1.2,3.5,1.2,5.9c0,2.2-0.5,4.2-1.4,5.9c-0.9,1.8-2.3,3.3-4,4.5c-1.8,1.2-3.9,2.2-6.3,2.8c-2.5,0.7-5.3,1-8.3,1
                    c-3,0-5.7-0.3-8.4-0.8c-2.6-0.5-5-1.4-7-2.6c-2-1.2-3.8-2.9-5.2-5c-1.4-2.1-2.3-4.7-2.8-7.8h-5c0.2,3.3,0.9,6.2,2.2,8.8
                    c1.3,2.6,3.1,4.8,5.5,6.7c2.4,1.8,5.3,3.3,8.8,4.2c3.5,1,7.6,1.5,12.3,1.5c3.6,0,6.9-0.5,10-1.5c3.1-1,5.7-2.4,7.9-4.2
                    c2.2-1.8,3.9-3.9,5.2-6.4c1.2-2.5,1.9-5.2,1.9-8.1c0-2.6-0.4-4.8-1.2-6.7c-0.8-1.9-1.9-3.5-3.3-4.9c-1.4-1.3-3-2.5-4.8-3.4
                    c-1.9-0.9-3.8-1.7-5.9-2.3c-2-0.6-4.1-1.2-6.3-1.7c-2.2-0.5-4.2-0.9-6.3-1.3c-3.5-0.7-6.4-1.5-8.7-2.3c-2.3-0.8-4.2-1.8-5.6-2.9
                    c-1.4-1.1-2.4-2.3-3-3.6c-0.6-1.4-0.9-2.9-0.9-4.8c0-4,1.6-7.1,4.8-9.4c3.2-2.3,7.9-3.4,14.1-3.4c3,0,5.6,0.3,7.9,1
                    c2.3,0.7,4.3,1.6,6,2.7c1.7,1.2,3,2.5,4.1,4.1c1.1,1.6,1.8,3.3,2.3,5.2h0.7c1.7-3.1,1.4-2.5,2.5-4.8
                    C1324.5,1063.1,1320.2,1058.7,1318.8,1057.7z"
            />
          </g>
          <g>
            <polygon
              className="st0"
              points="1349.2,1090 1389.8,1090 1389.8,1084.9 1349.2,1084.9 1349.2,1059.2 1392.6,1059.2 1392.6,1054.2 
                    1344.1,1054.2 1344.1,1123.6 1394.3,1123.6 1394.3,1118.5 1349.2,1118.5 	"
            />
          </g>
          <g>
            <polygon
              className="st0"
              points="1443.7,1052.7 1452.7,1080.3 1481.7,1080.3 1458.2,1097.4 1467.2,1125.1 1443.7,1108 1420.2,1125.1 
                    1429.1,1097.4 1405.6,1080.3 1434.7,1080.3 			"
            />
          </g>
          <g>
            <polygon
              className="st0"
              points="1524.4,1051.9 1533.4,1079.6 1562.5,1079.6 1538.9,1096.7 1547.9,1124.3 1524.4,1107.2 
                    1500.9,1124.3 1509.9,1096.7 1486.3,1079.6 1515.4,1079.6 			"
            />
          </g>
          <g>
            <polygon
              className="st0"
              points="1605.1,1051.2 1614.1,1078.8 1643.2,1078.8 1619.6,1095.9 1628.6,1123.6 1605.1,1106.5 
                    1581.6,1123.6 1590.6,1095.9 1567,1078.8 1596.1,1078.8 			"
            />
          </g>
          <g>
            <polygon
              className="st0"
              points="1685.8,1050.4 1694.8,1078.1 1723.9,1078.1 1700.3,1095.2 1709.3,1122.8 1685.8,1105.7 
                    1662.3,1122.8 1671.3,1095.2 1647.7,1078.1 1676.8,1078.1 			"
            />
          </g>
        </g>
      </svg>
    </div>
  )
}

export default LogoAnimated
