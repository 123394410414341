import {
  createStore as reduxCreateStore,
  compose,
  combineReducers,
  applyMiddleware,
} from "redux"
import thunk from "redux-thunk"
import reducer from "./reducers"

// const reducer = combineReducers({reducers})

const initialState = {
  count: 0,
  showTransition: false,
  instaContainerHeight: 0,
  activityTitle: "",
  activityBackgroundColor: "#F4EBE3",
  showNavbar: { show: true, delay: 0 },
  showMenu: false,
  navColor: "white",
  showTransition: false,
  newCatalogs: {},
  language: "en",
  isTouchScreen: false,
  showGalleryCursors: false,
}

let composeEnhancer = () => {}

if (typeof window !== `undefined`) {
  composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
}
const createStore = () =>
  reduxCreateStore(
    reducer,
    // initialState,
    composeEnhancer(applyMiddleware(thunk))
  )
export default createStore
