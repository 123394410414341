import React, { useEffect, useState } from "react"
import RoomsListData from "./roomslist.json"
import { connect } from "react-redux"
import { Image } from "../gatsby-images/image"
import TransitionLink from "gatsby-plugin-transition-link"
import { Trans } from "@lingui/macro"
import { console } from "globalthis/implementation"
import axios from "axios"

const RoomsListMobile = ({
  hideShowReservationCenter,
  language,
  startingDate,
  endingDate,
  showTransitionBanner,
  hideTransitionBanner,
  resetOffset,
  fetchingAvailabilities,
  roomsAvailabilities,
  isFromRoom,
  showReservationCenter,
  sessionId,
}) => {
  const langObj = RoomsListData.filter(obj => obj[language])[0][language]
  const [roomsListLanguage, setRoomsListLanguage] = useState(langObj)
  const [filteredList, setFilteredList] = useState([])

  useEffect(() => {
    setFilteredList(roomsListLanguage)
  }, [])

  useEffect(() => {
    const roomsList = RoomsListData.filter(obj => obj[language])[0][language]
    let arr = []
    if (roomsAvailabilities) {
      roomsAvailabilities.map(roomAvailObj => {
        const key = Object.keys(roomAvailObj)[0]
        const value = Object.values(roomAvailObj)[0]
        roomsList.map(roomObj => {
          if (roomObj.url == key) {
            roomObj["price"] = value
            arr.push(roomObj)
          }
        })
      })
      let availableRooms = []
      arr.map(el => el.price && availableRooms.push(el))
      setFilteredList(availableRooms)
      // console.log(availableRooms, 'availableRooms')
    }
  }, [roomsAvailabilities])

  useEffect(() => {
    const newLanguage = RoomsListData.filter(obj => obj[language])[0][language]
    setFilteredList(newLanguage)
  }, [language])

  return (
    <div className="rooms-list-mobile-container">
      {
        // roomsListLanguage.map(room => {
        //     return (
        //         <List
        //             hideShowReservationCenter={hideShowReservationCenter}
        //             title={room["title"]}
        //             subtitle={room["subtitle"]}
        //             description={room["description"]}
        //             btn={room["discover-btn"]}
        //             src={room["src"]}
        //             url={room["url"]}
        //             startingDate={startingDate}
        //             endingDate={endingDate}
        //             key={`${room["url"]}-mobile`}
        //             showTransitionBanner={showTransitionBanner}
        //             hideTransitionBanner={hideTransitionBanner}
        //             resetOffset={resetOffset}
        //         />
        //     )
        // })
        fetchingAvailabilities ? (
          <p style={{ textAlign: "center", color: "#f2f2f2", marginTop: 50 }}>
            <Trans>Nous recherchons les meilleurs tarifs guarantis</Trans>
          </p>
        ) : filteredList.length ? (
          filteredList.map(room => {
            return (
              <List
                hideShowReservationCenter={hideShowReservationCenter}
                title={room["title"]}
                subtitle={room["subtitle"]}
                description={room["description"]}
                btn={room["discover-btn"]}
                src={room["src"]}
                url={room["url"]}
                startingDate={startingDate}
                endingDate={endingDate}
                key={room["url"]}
                hideTransitionBanner={hideTransitionBanner}
                showTransitionBanner={showTransitionBanner}
                resetOffset={resetOffset}
                price={room["price"]}
                language={language}
                view={room["view"]}
                superficie={room["superficie"]}
                occupation={room["occupation"]}
                terrasse={room["terrasse"]}
                sdb={room["sdb"]}
                lit={room["lit"]}
                isFromRoom={isFromRoom}
                showReservationCenter={showReservationCenter}
                sessionId={sessionId}
              />
            )
          })
        ) : (
          <div
            style={{
              color: "#f2f2f2",
              margin: "auto",
              width: "80%",
              marginTop: 50,
            }}
          >
            <p>
              <Trans>
                Il semblerait que nous soyons complet pour vos dates. Nous vous
                invitons à vérifier si tel est le cas en nous contactant.
              </Trans>
            </p>
            <div
              className="contact-us"
              onClick={() => checkIfValidClick() && showTransitionBanner()}
            >
              <TransitionLink
                to={`/${language}/contact`}
                exit={{
                  delay: 2,
                }}
                entry={{
                  trigger: ({ node, e, exit, entry }) => {
                    hideTransitionBanner()
                    resetOffset(true)
                  },
                }}
              >
                <div
                  className="button"
                  onClick={() => hideShowReservationCenter()}
                >
                  <Trans>Contactez-nous</Trans>
                </div>
              </TransitionLink>
            </div>
          </div>
        )
      }
    </div>
  )
}

const checkIfValidClick = name => {
  if (typeof window !== `undefined`) {
    if (!window.location.pathname.includes(name)) {
      return true
    } else {
      return false
    }
  }
}

const List = ({
  hideShowReservationCenter,
  title,
  subtitle,
  showTransitionBanner,
  hideTransitionBanner,
  url,
  resetOffset,
  price,
  description,
  language,
  src,
  endingDate,
  startingDate,
  view,
  superficie,
  occupation,
  sdb,
  lit,
  terrasse,
  isFromRoom,
  showReservationCenter,
  sessionId,
}) => {
  const [nDays, setNDays] = useState("")
  const [roomCode, setRoomCode] = useState({ roomType: "", roomCat: "" })
  const [dateObj, setDateObj] = useState({})

  useEffect(() => {
    const roomUrl = url
    const roomsCodes = {
      "executive-room": { roomCat: "438969", roomType: "1023434" },
      "privilege-room": { roomCat: "438970", roomType: "1023436" },
      "garden-prestige-room": { roomCat: "438971", roomType: "1023437" },
      "confort-superior-room": { roomCat: "438972", roomType: "1023438" },
      "garden-comfort-room": { roomCat: "438973", roomType: "1023439" },
      "confort-room": { roomCat: "438974", roomType: "1023440" },
      "prestige-terrasse-room": { roomCat: "438975", roomType: "1023441" },
      "prestige-anis-room": { roomCat: "438976", roomType: "1023442" },
      "prestige-canard-room": { roomCat: "438977", roomType: "1023443" },
      "prestige-mauve-room": { roomCat: "438978", roomType: "1023435" },
    }

    setRoomCode(roomsCodes[roomUrl])

    if (startingDate && endingDate) {
      const diff = (e, t) => Math.floor((e.getTime() - t.getTime()) / 864e5)
      const days = diff(endingDate, startingDate)
      setNDays(days)
      const newDate = {}
      newDate["day"] = startingDate.getDate()
      newDate["month"] = startingDate.getMonth() + 1
      newDate["year"] = startingDate.getFullYear()
      newDate["nDays"] = diff(endingDate, startingDate)
      setDateObj(newDate)
    }
  }, [startingDate, endingDate])

  const pluralNight = () => {
    if (language == "fr" && nDays > 1) {
      return "nuits"
    } else if (language == "fr") {
      return "nuit"
    } else if (language == "en" && nDays > 1) {
      return "nights"
    } else if (language == "en") {
      return "night"
    } else if (language == "ru") {
      return "ночи"
    }
  }
  return (
    <div
      className="list-container"
      onClick={() => {
        isFromRoom(true)
        checkIfValidClick(url) &&
          showTransitionBanner() &&
          hideShowReservationCenter()
      }}
    >
      {startingDate && endingDate ? (
        <a
          onClick={() => {
            axios.post(
              "https://reservations-requests.herokuapp.com/complete_request",
              {
                sessionId: sessionId,
                roomCat: url,
              }
            )
          }}
          style={{ width: "100%", height: "100%" }}
          href={`https://secure.reservit.com/reservit/reserhotel.php?lang=${language.toUpperCase()}&id=2&hotelid=429967&roomtcode=${
            roomCode["roomCat"]
          }&catcode=${roomCode["roomType"]}&fday=${dateObj["day"]}&fmonth=${
            dateObj["month"]
          }&fyear=${dateObj["year"]}&nbnights=${dateObj["nDays"]}`}
        >
          <div className="row">
            <div
              className="image col-10 offset-1"
              style={{ borderTop: "1px solid white" }}
            >
              <div className="image">
                {showReservationCenter && <Image src={src} />}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="text col-10 offset-1">
              <div className="title">{title}</div>
              <div className="subtitle">{subtitle}</div>
              <div className="description">{description}</div>
              <div className="">
                <div
                  className="room-infos-separation"
                  style={{ marginTop: 15 }}
                ></div>
                <div style={{ display: "flex" }}>
                  <ul
                    className="rooms-infos-reservation"
                    style={{ width: "100%" }}
                  >
                    <li className="li">
                      <span>
                        <Trans>Vue</Trans>
                      </span>{" "}
                      : {view}
                    </li>
                    <li className="div">
                      <span>
                        <Trans>Superficie</Trans>
                      </span>{" "}
                      : {superficie}
                    </li>
                    <li className="div">
                      <span>
                        <Trans>Occupation</Trans>
                      </span>{" "}
                      : {occupation}
                    </li>
                    <li className="div">
                      <span>
                        <Trans>Climatisation rėversible</Trans>
                      </span>
                    </li>
                    {terrasse && (
                      <li
                        className="div"
                        style={{ textDecoration: "underline" }}
                      >
                        <Trans>Terrasse privée</Trans>
                      </li>
                    )}
                    <li className="li">
                      <span>
                        <Trans>Sdb</Trans>
                      </span>{" "}
                      : {sdb}
                    </li>
                    <li className="div">
                      <span>
                        <Trans>Lit </Trans>
                      </span>{" "}
                      : {lit}
                    </li>
                  </ul>
                  {/* <ul className="rooms-infos-reservation" style={{width: '50%'}}>

                                    </ul> */}
                </div>
                <div className="room-infos-separation"></div>
              </div>
              {price && (
                <div className="price">
                  {price}€{" "}
                  <span>
                    <Trans>pour</Trans> {nDays} {pluralNight()}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="book-btn">
            <Trans>RÉSERVER</Trans>
          </div>
        </a>
      ) : (
        <TransitionLink
          to={`/${language}/rooms/${url}`}
          exit={{
            delay: checkIfValidClick(url) ? 2 : 0,
          }}
          entry={{
            trigger: ({ node, e, exit, entry }) => {
              hideTransitionBanner()
              resetOffset(true)
            },
          }}
        >
          <div className="row">
            <div
              className="image col-10 offset-1"
              style={{ borderTop: "1px solid white" }}
            >
              <div className="image">
                {showReservationCenter && <Image src={src} />}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="text col-10 offset-1">
              <div className="title">{title}</div>
              <div className="subtitle">{subtitle}</div>
              <div className="description">{description}</div>
              <div className="">
                <div
                  className="room-infos-separation"
                  style={{ marginTop: 15 }}
                ></div>
                <div style={{ display: "flex" }}>
                  <ul
                    className="rooms-infos-reservation"
                    style={{ width: "100%" }}
                  >
                    <li className="li">
                      <span>
                        <Trans>Vue</Trans>
                      </span>{" "}
                      : {view}
                    </li>
                    <li className="div">
                      <span>
                        <Trans>Superficie</Trans>
                      </span>{" "}
                      : {superficie}
                    </li>
                    <li className="div">
                      <span>
                        <Trans>Occupation</Trans>
                      </span>{" "}
                      : {occupation}
                    </li>
                    <li className="div">
                      <span>
                        <Trans>Climatisation rėversible</Trans>
                      </span>
                    </li>
                    {terrasse && (
                      <li
                        className="div"
                        style={{ textDecoration: "underline" }}
                      >
                        <Trans>Terrasse privée</Trans>
                      </li>
                    )}
                    <li className="li">
                      <span>
                        <Trans>Sdb</Trans>
                      </span>{" "}
                      : {sdb}
                    </li>
                    <li className="div">
                      <span>
                        <Trans>Lit </Trans>
                      </span>{" "}
                      : {lit}
                    </li>
                  </ul>
                  {/* <ul className="rooms-infos-reservation" style={{width: '50%'}}>

                                    </ul> */}
                </div>
                <div className="room-infos-separation"></div>
              </div>
              {price && (
                <div className="price">
                  {price}€{" "}
                  <span>
                    <Trans>pour</Trans> {nDays} {pluralNight()}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="book-btn">
            <Trans>Découvrir</Trans>
          </div>
        </TransitionLink>
      )}
    </div>
  )
}

const mapStateToProps = ({
  language,
  startingDate,
  endingDate,
  fetchingAvailabilities,
  showReservationCenter,
  roomsAvailabilities,
  sessionId,
}) => {
  return {
    language,
    startingDate,
    endingDate,
    fetchingAvailabilities,
    roomsAvailabilities,
    showReservationCenter,
    sessionId,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    hideShowReservationCenter: () =>
      dispatch({
        type: `HIDE_RESERVATION_CENTER`,
      }),
    showTransitionBanner: () => dispatch({ type: `IMG_NOT_LOADED` }),
    hideTransitionBanner: () => dispatch({ type: `IMG_LOADED` }),
    resetOffset: bool => dispatch({ type: "RESET_OFFSET", payload: bool }),
    isFromRoom: bool => dispatch({ type: "IS_FROM_ROOM", payload: bool }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomsListMobile)
