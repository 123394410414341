import React, { useEffect, useRef, useState } from "react"
import gsap from "gsap"
import { connect } from "react-redux"
import StartCalendar from "./StartCalendar"
import EndCalendar from "./EndCalendar"
import StartCalendarMobile from "./StartCalendarMobile"
import EndCalendarMobile from "./EndCalendarMobile"
import { Trans } from "@lingui/macro"
import axios from "axios"

const ReservationFinalBar = ({
  startingDate,
  endingDate,
  roomUrl,
  updateShowReservationCenter,
  roomReservationInfos,
  showRoomBarReservation,
  roomsAvailabilities,
  language,
  sessionId,
}) => {
  let container = useRef(null)
  let reserverText = useRef(null)

  const [insertDate, setInsertDate] = useState(false)
  // let hoverDiv = useRef(null)
  // const [hoveredButton, setHoveredButton] = useState(false)

  // const showHover = () => {
  //     setHoveredButton(!hoveredButton)
  // }
  // useEffect(() => {
  //     hoveredButton?
  //         gsap.to(hoverDiv, {xPercent: -100})
  //         :
  //         gsap.to(hoverDiv, {xPercent: 0})
  // }, [hoveredButton])
  let windowWidth

  const [roomCode, setRoomCode] = useState({ roomType: "", roomCat: "" })
  const [dateObj, setDateObj] = useState({})
  const [price, setPrice] = useState(null)

  if (typeof window !== `undefined`) {
    windowWidth = window.innerWidth
  }

  useEffect(() => {
    const roomsCodes = {
      "executive-room": { roomCat: "438969", roomType: "1023434" },
      "privilege-room": { roomCat: "438970", roomType: "1023436" },
      "garden-prestige-room": { roomCat: "438971", roomType: "1023437" },
      "confort-superior-room": { roomCat: "438972", roomType: "1023438" },
      "garden-comfort-room": { roomCat: "438973", roomType: "1023439" },
      "confort-room": { roomCat: "438974", roomType: "1023440" },
      "prestige-terrasse-room": { roomCat: "438975", roomType: "1023441" },
      "prestige-anis-room": { roomCat: "438976", roomType: "1023442" },
      "prestige-canard-room": { roomCat: "438977", roomType: "1023443" },
      "prestige-mauve-room": { roomCat: "438978", roomType: "1023435" },
    }

    setRoomCode(roomsCodes[roomUrl])
  }, [roomUrl])

  useEffect(() => {
    if (startingDate && endingDate) {
      const newDate = {}
      newDate["day"] = startingDate.getDate()
      newDate["month"] = startingDate.getMonth() + 1
      newDate["year"] = startingDate.getFullYear()
      const diff = (e, t) => Math.floor((e.getTime() - t.getTime()) / 864e5)
      newDate["nDays"] = diff(endingDate, startingDate)

      setDateObj(newDate)
    }
  }, [startingDate, endingDate])

  useEffect(() => {
    if (roomsAvailabilities.length) {
      roomsAvailabilities.filter(room => {
        const key = Object.keys(room)[0]
        key == roomUrl && setPrice(room[key])
      })
    }
  }, [roomUrl, roomsAvailabilities, startingDate])

  useEffect(() => {
    if (showRoomBarReservation) {
      gsap.set(container, { bottom: 30, yPercent: 0 })
    } else {
      gsap.set(container, { yPercent: 400 })
    }
  }, [showRoomBarReservation])

  useEffect(() => {
    startingDate && endingDate && roomReservationInfos.availability
      ? gsap.fromTo(
          reserverText,
          { opacity: 0.5 },
          { opacity: 1, repeat: -1, duration: 1 }
        )
      : gsap.set(reserverText, { opacity: 1 })
  }, [startingDate, endingDate])
  return (
    <div
      className="final-bar-reservation offset-md-2 col-md-8 offset-sm-1 col-sm-10"
      ref={el => (container = el)}
    >
      {/* <div
                style={{width: '100%', height: '100%', position: 'relative', display: 'flex', alignItems: 'center'}}
            >
                <div 
                    className="hover-div"
                    ref={el => hoverDiv = el}
                >
                    
                </div> */}
      <div className="calendar-div">
        {windowWidth > 576 ? (
          <StartCalendar showCalendar={insertDate} />
        ) : (
          <StartCalendarMobile showCalendar={insertDate} />
        )}
      </div>
      <div className="vertical-line"></div>
      <div className="calendar-div">
        {windowWidth > 576 ? <EndCalendar /> : <EndCalendarMobile />}
      </div>
      <div className="vertical-line"></div>
      <div className="price">
        {roomsAvailabilities.length ? (
          price ? null : (
            <p
              className="unavailable"
              style={{
                marginTop: "unset",
                marginBottom: "0",
              }}
            >
              <Trans>Indisponible</Trans>
            </p>
          )
        ) : (
          <div
            className="price-title"
            style={{ width: "100%", height: "100%" }}
            onClick={() => setInsertDate(true)}
          >
            {insertDate ? (
              <Trans>INSERT A DATE FOR PRICE</Trans>
            ) : (
              <Trans>PRIX:</Trans>
            )}
          </div>
        )}
        {startingDate && endingDate && price && <div>{price}€</div>}
      </div>
      <div className="vertical-line"></div>
      <div
        // onMouseEnter={() => showHover()}
        // onMouseLeave={() => showHover()}
        style={{ height: "100%" }}
        className="reserve"
        ref={el => (reserverText = el)}
      >
        {roomsAvailabilities.length ? (
          price ? (
            //https://secure.reservit.com/reservit/reserhotel.php?lang=FR&id=2&hotelid=429967&roomtcode=438971&catcode=1023437&fday=12&fmonth=02&fyear=2021&nbnights=1
            <a
              style={{ width: "100%", height: "100%" }}
              href={`https://secure.reservit.com/reservit/reserhotel.php?lang=${language.toUpperCase()}&id=2&hotelid=429967&roomtcode=${
                roomCode["roomCat"]
              }&catcode=${roomCode["roomType"]}&fday=${dateObj["day"]}&fmonth=${
                dateObj["month"]
              }&fyear=${dateObj["year"]}&nbnights=${dateObj["nDays"]}`}
              onClick={() => {
                axios.post(
                  "https://reservations-requests.herokuapp.com/complete_request",
                  {
                    sessionId: sessionId,
                    roomCat: roomUrl,
                  }
                )
              }}
            >
              <Trans>RÉSERVER</Trans>
            </a>
          ) : (
            <div onClick={() => updateShowReservationCenter()}>
              <Trans>AUTRES DISPONIBILITÉS?</Trans>
            </div>
          )
        ) : (
          <div>
            {/*https://secure.reservit.com/reservit/reserhotel.php?lang=FR&id=2&hotelid=429967&roomtcode=438969*/}
            <Trans>RÉSERVER</Trans>
          </div>
        )}
      </div>
    </div>
    // </div>
  )
}

const mapStateToProps = ({
  roomReservationInfos,
  startingDate,
  endingDate,
  roomUrl,
  showRoomBarReservation,
  roomsAvailabilities,
  language,
  sessionId,
}) => {
  return {
    roomReservationInfos,
    startingDate,
    endingDate,
    showRoomBarReservation,
    roomUrl,
    roomsAvailabilities,
    language,
    sessionId,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    hideShowReservationCenter: () =>
      dispatch({
        type: `HIDE_RESERVATION_CENTER`,
      }),
    updateShowReservationCenter: () =>
      dispatch({
        type: `SHOW_RESERVATION_CENTER`,
      }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReservationFinalBar)
