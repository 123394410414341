import gsap from "gsap/gsap-core"
import React, { useEffect, useRef, useState } from "react"
import TransitionLink from "gatsby-plugin-transition-link"
import { connect } from "react-redux"

function NavbarMenuItem({
  name,
  navColor,
  showTransition,
  showTransitionFunction,
  showTransitionBanner,
  hideTransitionBanner,
  url,
  resetOffset,
  isFromRoom,
  roomPageAnimationDelay,
  language,
  linkName,
}) {
  let redLine = useRef(null)

  const [lineName, setLineName] = useState("")
  //function to avoid adding delay if click is not valid because on the same page
  const checkIfValidClick = () => {
    if (typeof window !== `undefined`) {
      if (
        !window.location.pathname.includes(linkName) &&
        !(
          window.location.pathname === "/" ||
          window.location.pathname === "/en/" ||
          window.location.pathname === "/fr/" ||
          (window.location.pathname === "/ru/" && linkName === "hotel")
        )
      ) {
        return true
      } else {
        return false
      }
    }
  }
  const handleTransitionBanner = () => {
    isFromRoom(false)
    roomPageAnimationDelay(true)
    checkIfValidClick() && showTransitionBanner()
  }

  const showLineFunc = () => {
    !showTransition && setLineName(name)
    //added this showtransition funtion because without it
    // first element's red line of navbar was always flashing on page transition
    showTransitionFunction(false)
  }
  const hideLineFunc = () => {
    setLineName("")
  }

  useEffect(() => {
    const redLineConst = redLine
    gsap.set(redLineConst, { visibility: "hidden", xPercent: -100 })
    // setTimeout(() => showTransitionFunction(false), 1000)
  }, [])

  useEffect(() => {
    const redLineConst = redLine
    // bug with first menu item being called even if state false
    lineName === name
      ? gsap.to(redLineConst, { visibility: "visible", xPercent: 0 })
      : gsap.to(redLineConst, { xPercent: -110 })
  }, [lineName])

  return (
    <div
      className="menu-item"
      onMouseEnter={() => showLineFunc()}
      onMouseLeave={() => hideLineFunc()}
      onClick={() => handleTransitionBanner()}
    >
      <TransitionLink
        to={`/${language}/${url}`}
        exit={{
          delay: checkIfValidClick() ? 2 : 0,
        }}
        entry={{
          trigger: ({ node, e, exit, entry }) => {
            hideTransitionBanner()
            resetOffset(true)
          },
        }}
      >
        {/* <Link to={name !== 'hotel'? `/${name}`:'/'} > */}
        <div
          className="name"
          style={{ color: navColor }}
          onClick={() => showTransitionFunction(true)}
        >
          {name}
        </div>
        <div className="red-line" ref={el => (redLine = el)}></div>
      </TransitionLink>
    </div>
  )
}

const mapStateToProps = ({
  showNavbar,
  navColor,
  showTransition,
  language,
}) => {
  return { showNavbar, navColor, showTransition, language }
}

const mapDispatchToProps = dispatch => {
  return {
    showTransitionFunction: bool =>
      dispatch({ type: `SHOW_TRANSITION`, payload: bool }),
    showTransitionBanner: () => dispatch({ type: `IMG_NOT_LOADED` }),
    hideTransitionBanner: () => dispatch({ type: `IMG_LOADED` }),
    resetOffset: bool => dispatch({ type: "RESET_OFFSET", payload: bool }),
    isFromRoom: bool => dispatch({ type: "IS_FROM_ROOM", payload: bool }),
    roomPageAnimationDelay: bool =>
      dispatch({ type: "FIRED_FROM_NAVBAR", payload: bool }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavbarMenuItem)
